.top-menu-utility {
    .menu-utility {
        .mini-cart-total {
            .mini-cart-link {
                span {
                    &.cart-bag {
                        background: url("../../../images/bag-icon.svg")
                            no-repeat;
                    }
                    &.cart-bag-qty {
                        background: url("../../../images/bag-icon-white.svg")
                            no-repeat;
                    }
                }
                .minicart-quantity {
                    background-color: $geraldine;
                    right: 91px;
                    top: 4px;
                }
                &:hover,
                &.minicart_open {
                    span {
                        &.cart-bag,
                        &.cart-bag-qty {
                            background: url("../../../images/bag-icon-black.svg")
                                no-repeat;
                        }
                    }
                }
                &.mini-cart-empty {
                    &:hover {
                        span {
                            &.cart-bag,
                            &.cart-bag-qty {
                                background: url("../../../images/bag-icon.svg")
                                    no-repeat;
                            }
                        }
                    }
                }
            }
            &:hover {
                .mini-cart-link {
                    &:not(.mini-cart-empty) {
                        span {
                            &.cart-bag,
                            &.cart-bag-qty {
                                background: url(../../../images/bag-icon-black.svg)
                                    no-repeat;
                                background-size: 100%;
                            }
                        }
                    }
                }
            }
            &.DelayHover {
                .mini-cart-link {
                    &:not(.mini-cart-empty) {
                        span {
                            &.cart-bag,
                            &.cart-bag-qty {
                                background: url(../../../images/bag-icon-black.svg)
                                    no-repeat;
                                background-size: 100%;
                            }
                        }
                    }
                }
            }
        }
        .mini-cart-content {
            .mini-cart-products {
                .mini-cart-product {
                    &.product-brylane-mini-cart {
                        .left-content {
                            .mini-cart-image {
                                img {
                                    object-fit: cover;
                                    min-height: 117px;
                                }
                            }
                        }
                    }
                    span {
                        @include WorkSansRegular($WorkSans);
                        font-size: $base-font;
                        letter-spacing: 0;
                        text-transform: capitalize;
                    }
                    span,
                    a {
                        &.label {
                            @include WorkSansRegular($WorkSans);
                            font-size: $base-font;
                            letter-spacing: 0.5px;
                            text-transform: capitalize;
                        }
                        &.value,
                        &.is-in-stock,
                        &.notavailable {
                            @include WorkSansBold($WorkSans);
                            font-size: $base-font;
                            letter-spacing: 0.5px;
                            text-transform: capitalize;
                        }
                        &.price-standard {
                            span {
                                @include WorkSansSemiBold($WorkSans);
                                font-size: $base-font;
                                letter-spacing: 0;
                            }
                        }
                        &.on-order,
                        &.price-standard-exist {
                            color: $medium-carmine;
                        }
                    }
                }
            }
            .attribute {
                padding: 0 0 10px 0;
                &[data-attribute="braCupSize"] {
                    span.value {
                        text-transform: uppercase;
                    }
                }
                span {
                    &.label {
                        padding: 0;
                    }
                    &.value {
                        padding: 0;
                        display: inline;
                        text-transform: lowercase;
                    }
                }
            }
            .product-availability-list {
                padding: 0 0 10px 0;
                span {
                    @include WorkSansSemiBold($WorkSans);
                    font-size: $base-font;
                    letter-spacing: 0;
                    text-transform: capitalize;
                    &.label {
                        padding: 0;
                    }
                    &.on-order {
                        text-transform: none;
                    }
                }
            }
        }
        .mini-cart-name {
            padding: 0 0 10px 0;
            a {
                font-family: $HurmeSemiBold;
                font-size: $base-font;
                text-transform: uppercase;
                letter-spacing: 0.5px;
            }
        }
        .mini-cart-brand {
            padding-bottom: 10px;
            color: $brand-name-color;
            &.jessicalondon {
                color: $brand-name-color;
            }
        }
        .mini-cart-totals {
            button,
            .button {
                padding: 15px 20px;
                background: $black;
                color: $white;
                width: 100%;
                border: none;
                font-family: $HurmeBlack;
                letter-spacing: 3.5px;
                font-size: $base-font + 2px;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
            }
            .mini-cart-subtotals {
                span {
                    @include WorkSansBold($WorkSans);
                    font-size: $base-font + 4px;
                    color: $black;
                    letter-spacing: 0.6px;
                }
            }
        }
        .mini-cart-slot {
            .minicartslot-content {
                .minicartslot {
                    .discount-sec {
                        font-size: $base-font + 28px;
                        @include WorkSansExtraLight($WorkSans);
                        .discount-percent {
                            @include WorkSansBold($WorkSans);
                            font-size: $base-font + 28px;
                        }
                    }
                    .product-sec {
                        @include LoraItalic($Lora);
                    }
                }
            }
        }
        .mini-cart-product {
            &:last-of-type {
                border-bottom: 2px solid $silver;
            }
        }
    }
}
@media screen and (min-width: 1024px) {
    .top-menu-utility {
        .menu-utility {
            .mini-cart-total {
                vertical-align: top;
            }
        }
    }
}
