footer {
    background-color: $white;
}
.footer-container {
    border-top: 1px solid $SW-navy-blue;
    &.success-message {
        .footer-email-signup-message {
            display: none;
        }
        .footer-email-signup {
            width: auto;
        }
        .email-signup {
            &.make-label-absolute {
                margin: 40px 0;
            }
        }
        .email-signup-footer-success {
            text-align: center;
            .email-signup-footer-success-heading {
                font-size: $base-font + 12px;
                @include WorkSansMedium($WorkSans);
                margin: 0 0 15px 0;
                width: 100%;
                float: left;
            }
            .promo-code {
                font-size: $base-font + 2px;
                @include WorkSansMedium($WorkSans);
                margin: 0 0 13px 0;
                width: 100%;
                float: left;
                color: $dim-gray;
            }
            p {
                font-size: $base-font + 2px;
                @include WorkSansMedium($WorkSans);
                color: $dim-gray;
                width: 100%;
                float: left;
            }
        }
    }
    ul {
        margin: 0;
        padding: 0;
        li {
            list-style: outside none none;
            margin: 0;
            padding: 0;
            line-height: normal;
            a {
                margin: 0;
                padding: 0;
            }
        }
    }
    p {
        margin: 0;
        padding: 0;
        line-height: normal;
    }
    .footer-email-signup-message {
        float: left;
        width: 100%;
        .footer-email-heading {
            text-align: center;
            @include GothamMedium($Gotham);
            color: $SW-navy-blue;
            font-size: $base-font + 8px;
            letter-spacing: 1px !important;
            margin: 33px 0 8px 0;
            text-transform: uppercase;
        }
        p {
            text-align: center;
            @include GothamBook($GothamBook);
            color: $SW-gray;
            font-size: $base-font + 2px;
            letter-spacing: 0.5px;
        }
    }
    .footer-email-signup {
        display: table;
        margin: auto;
        .email-signup {
            margin: 20px 0;
            &.make-label-absolute {
                .form-row {
                    input[id*="emailsignup_email"] {
                        padding: 15px 0 16px 15px;
                        font-size: $base-font + 1px;
                        line-height: $base-font + 2px;
                        height: 46px;
                        border-bottom: 0;
                        @include GothamBook($GothamBook);
                        letter-spacing: 0;
                        color: $SW-gray;
                    }
                    label {
                        span {
                            font-size: $base-font + 1px;
                            @include GothamBook($GothamBook);
                            letter-spacing: 0;
                            color: $SW-gray;
                            &.error {
                                color: $guardsman-Red;
                            }
                        }
                        &.input-focus {
                            span {
                                font-size: $base-font + 1px;
                                @include GothamBook($GothamBook);
                                letter-spacing: 0;
                                color: $SW-gray;
                                &.error {
                                    color: $guardsman-Red;
                                }
                            }
                        }
                        &.input-focus {
                            font-size: $base-font + 1px;
                            @include GothamBook($GothamBook);
                            letter-spacing: 0;
                            color: $SW-gray;
                        }
                    }
                }
            }
            .form-row {
                float: left;
                .field-wrapper {
                    height: 50px;
                    border-bottom: 4px solid $SW-navy-blue;
                }
                input[type="text"] {
                    width: 310px;
                    height: 50px;
                    padding: 15px;
                    border: 1px solid $FB-lightest-gray;
                    border-right: none;
                    border-radius: 0;
                    font-size: $base-font + 1px;
                    @include GothamBook($GothamBook);
                    letter-spacing: 0;
                    color: $SW-gray;
                }
                .error {
                    clear: both;
                    float: left;
                    padding-top: 5px;
                }
            }
            .form-row-button {
                clear: none;
                button {
                    background-color: $SW-navy-blue;
                    text-transform: uppercase;
                    font-size: $base-font + 2px;
                    @include GothamBold($Gotham);
                    letter-spacing: 2px;
                }
            }
            &.make-label-absolute {
                margin: 20px auto 33px;
                .form-row {
                    label {
                        span {
                            @include GothamBook($GothamBook);
                            font-size: $base-font;
                            letter-spacing: 0.5px;
                            color: $SW-gray;
                        }
                    }
                    &.form-row-button {
                        button {
                            padding-top: 15px;
                            padding-bottom: 15px;
                            height: 50px;
                            font-size: $base-font + 2px;
                            @include GothamBold($Gotham);
                            letter-spacing: 2px;
                        }
                    }
                }
            }
        }
    }
    .footer-social-icon {
        position: relative;
        margin-bottom: 28px;
        .fo-border {
            border-bottom: 1px solid $SW-navy-blue;
        }
        .fo-social {
            ul {
                li {
                    &.instagram {
                        a {
                            background-position: -17px -16px;
                        }
                    }
                    &.pintrest {
                        a {
                            background-position: -53px -15px;
                        }
                    }
                    &.facebook {
                        a {
                            background-position: -83px -16px;
                        }
                    }
                    &.twitter {
                        a {
                            background-position: -114px -16px;
                        }
                    }
                    &.youtube {
                        a {
                            background-position: -157px -14px;
                        }
                    }
                    a {
                        .icon {
                            font-size: 30px;
                            @media screen and (max-width: 767px) {
                                font-size: 17px;
                            }
                        }
                    }
                }
            }
        }
    }
    .footer-links {
        float: left;
        width: 100%;
        border-bottom: 1px solid $black;
        .footer_list_wrapper {
            margin: auto;
            width: 100%;
            .footer_item {
                float: left;
                width: 25%;
                ul {
                    margin: 0 0 32px;
                    padding: 0;
                }
                .footer-heading {
                    text-transform: uppercase;
                    margin: 0;
                    @include GothamBold($Gotham);
                    font-size: $base-font + 2px;
                    letter-spacing: 0.5px;
                    color: $SW-navy-blue;
                    margin-bottom: 0;
                    a {
                        text-transform: uppercase;
                        @include GothamBold($Gotham);
                        font-size: $base-font + 2px;
                        letter-spacing: 0.5px;
                        color: $SW-navy-blue;
                        display: inline-block;
                        &:hover {
                            color: $SW-navy-blue;
                        }
                    }
                    @media screen and (max-width: 1023px) {
                        margin-bottom: 0;
                        text-transform: uppercase;
                    }
                }
                a {
                    text-transform: capitalize;
                    font-size: $base-font + 2px;
                    @include GothamBook($GothamBook);
                    letter-spacing: 0.5px;
                    display: inline-block;
                    color: $SW-navy-blue;
                    &:hover {
                        color: $SW-navy-blue;
                    }
                }
            }
        }
    }
    .footer-item {
        box-sizing: border-box;
        display: block;
        padding: 0.5em 1.5em;
        width: 100%;
        @media screen and (min-width: 768px) {
            display: table-cell;
            padding: 1.5em;
            width: 25%;
        }
    }
    h3 {
        text-transform: uppercase;
    }
    .menu-footer {
        float: none;
        margin: 0.5em;
        padding: 0;
        @media screen and (min-width: 768px) {
            margin: 1em 0;
        }
        li {
            float: none;
            line-height: 2.5em;
            list-style: none !important;
        }
    }
    a {
        &:hover {
            color: $very-light-gray;
            text-decoration: none;
        }
    }
    .footer-brand-selector {
        float: left;
        width: 100%;
        .footer-brand-heading {
            @include GothamMedium($Gotham);
            font-size: $base-font + 8px;
            letter-spacing: 3px;
            margin: 0;
            padding-bottom: 23px;
            padding-top: 27px;
            text-align: center;
            width: 100%;
            color: $SW-navy-blue;
            text-transform: uppercase;
            @media screen and (max-width: 767px) {
                font-size: $base-font + 8px !important;
            }
        }
        .brands-list {
            ul {
                li {
                    img {
                        max-width: 100%;
                    }
                }
            }
        }
    }
    .footer-legal-copyrights {
        float: left;
        width: 100%;
        .static-links {
            display: table;
            margin: auto auto 15px;
            text-align: center;
            ul {
                li {
                    display: inline-block;
                    margin-right: 10px;
                    a {
                        @include GothamBook($GothamBook);
                        font-size: $base-font + 1px;
                        letter-spacing: 0.5px;
                        color: $SW-gray;
                        text-decoration: none;
                        @media screen and (max-width: 767px) {
                            text-decoration: underline;
                        }
                    }
                    img {
                        display: none;
                    }
                }
            }
        }
        .copyrights-wrapper {
            float: left;
            width: 100%;
            margin-bottom: 25px;
            p {
                float: left;
                text-align: center;
                width: 100%;
                @include GothamBook($GothamBook);
                font-size: $base-font + 1px;
                letter-spacing: 0.5px;
                color: $SW-gray;
                line-height: 20px;
            }
            .copy-arr {
                margin-bottom: 1px;
            }
        }
    }
}
.footer-container {
    &.footer-down {
        border-top: none;
        background-color: $white;
        float: left;
        width: 100%;
        margin-top: 40px;
        .footer-down-inner {
            width: 1260px;
            margin: 0 auto !important;
            @media screen and (max-width: 767px) {
                margin: 0 auto !important;
            }
        }
    }
}
@media screen and (max-width: 767px) {
    footer {
        .footer-container {
            .footer-email-signup {
                width: 96%;
            }
            .footer-links {
                .footer_list_wrapper {
                    .footer_item {
                        a {
                            text-transform: capitalize;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
    footer {
        .footer-container {
            .footer-email-signup {
                width: 52%;
                .email-signup {
                    margin: 14px 0;
                }
            }
            .footer-links {
                .footer_list_wrapper {
                    .footer_item {
                        ul {
                            margin: 0 0 22px;
                        }
                        a {
                            text-transform: capitalize;
                        }
                    }
                }
            }
            .footer-brand-selector {
                margin-bottom: 18px;
                .footer-brand-heading {
                    padding-bottom: 37px;
                    padding-top: 34px;
                }
            }
        }
    }
}

#wrapper.pt_checkout_globale.pt_checkout .intl-footer .footer-container.footer-down .footer-down-inner {
    margin: 27px auto !important;
}