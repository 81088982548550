.category-search {
    .simplesearch {
        background-color: $wild-sand;
        border-bottom: none;
        padding: 13px 11px 5px 23px;
        font-size: $base-font + 1px;
        text-transform: lowercase;
        @include WorkSansMedium($WorkSans);
        font-weight: normal;
        margin: 0 0 38px 0;
        border-radius: 0;
        .result-count {
            span {
                color: $dim-gray;
                &:first-letter {
                    text-transform: uppercase;
                }
            }
            .searchresult-hide-mobile {
                display: block;
                padding: 0 0 0 3px;
            }
            .searchresult-hide-desktop {
                display: none;
            }
            @media screen and (max-width: 767px) {
                .searchresult-hide-desktop {
                    display: block;
                }
                .searchresult-hide-mobile {
                    display: none;
                }
            }
        }
        input[type="text"] {
            border: none;
            background: transparent;
            text-transform: capitalize;
            width: 90%;
            padding: 0;
            font-size: $base-font + 34px;
            height: auto;
            box-sizing: border-box;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            @include WorkSansMedium($WorkSans);
            outline: none;
            color: $black;
            box-shadow: none;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
        }
        button {
            float: right;
            border: none;
            text-indent: -9999999px;
            padding: 0;
            margin: 5px 20px 0 0;
            background: url("../../../../images/big-search-icon.png") no-repeat;
            width: 34px;
            height: 36px;
            background-size: 100%;
        }
    }
}
//refinements
.product-list-page {
    .refinements {
        &.banner-slot {
            margin: 0;
            border-radius: 3px;
        }
    }
    .ref_fixed {
        .refinements {
            width: 100%;
            display: inline-block;
            position: fixed;
            max-width: 1260px;
            left: 50%;
            transform: translateX(-50%);
            background: $white;
            z-index: 9;
            box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.2);
            margin: 0;
            @media screen and (min-width: 768px) and (max-width: 1023px) {
                width: calc(100% - 20px);
                width: -o-calc(100% - 20px);
                padding: 0;
                margin: 0 !important;
            }
        }
    }
}
.ref_fixed {
    .refinements {
        width: 100%;
        display: inline-block;
        position: fixed;
        max-width: 1260px;
        left: 50%;
        transform: translateX(-50%);
        background: $white;
        z-index: 9;
        box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.2);
        @media screen and (min-width: 768px) and (max-width: 1023px) {
            width: calc(100% - 20px);
            width: -o-calc(100% - 20px);
            padding: 0;
            margin: 0 !important;
            top: 63px !important;
        }
        @media screen and (width: 1024px) {
            width: calc(100% - 20px) !important;
            width: -o-calc(100% - 20px) !important;
        }
    }
}
.refinements {
    width: 100%;
    float: left;
    border: 1px solid $black;
    @media screen and (min-width: 1024px) {
        width: calc(100% - 2px);
        width: -o-calc(100% - 2px);
        z-index: 3;
    }
    ul {
        list-style: none outside none;
        margin: 0;
        padding: 0;
    }
    .refinement-header {
        display: none;
    }
    .filters {
        .filter {
            display: none;
        }
    }
    .result-count {
        display: none;
    }
    .refinement {
        padding: 0;
        position: relative;
        &:last-child {
            .refinement-heading-category {
                border-right: none;
            }
        }
        @media screen and (min-width: 1024px) {
            /* one item */
            &:first-child:nth-last-child(1) {
                width: 100%;
            }
            /* two items */
            &:first-child:nth-last-child(2),
            &:first-child:nth-last-child(2) ~ .refinement {
                width: 50%;
            }
            /* three items */
            &:first-child:nth-last-child(3),
            &:first-child:nth-last-child(3) ~ .refinement {
                width: 33.3333%;
            }
            /* four items */
            &:first-child:nth-last-child(4),
            &:first-child:nth-last-child(4) ~ .refinement {
                width: 25%;
            }
            /* five items */
            &:first-child:nth-last-child(5),
            &:first-child:nth-last-child(5) ~ .refinement {
                width: 20%;
            }
            /* six items */
            &:first-child:nth-last-child(6),
            &:first-child:nth-last-child(6) ~ .refinement {
                width: 16.66%;
            }
            &:last-child {
                float: right;
            }
            .expended.right {
                left: -1px;
                right: auto;
            }
            .expended.left {
                left: auto;
                right: 0;
            }
        }
        .refinement-heading-category {
            background: transparent;
            padding: 14px 20px 10px;
            margin: 0;
            border: none;
            border-right: 1px solid $FB-dark-gray;
            text-transform: uppercase;
            letter-spacing: 1px;
            cursor: pointer;
            display: block;
            height: 100%;
            box-sizing: border-box;
            text-align: left;
            width: 100%;
            outline: 0;
            box-shadow: none;
            @include GothamMedium($Gotham);
            font-size: $base-font - 1;
            font-weight: 500;
            color: $s4a-navy-blue;
            &.expanded {
                background: $s4a-navy-blue;
                color: $white;
                border: none;
                i {
                    background: url(../../../images/carat-up-gray.svg) no-repeat;
                    &.fa-angle-up {
                        display: block;
                        color: $white;
                    }
                    &.fa-angle-down {
                        display: none;
                    }
                }
            }
            i {
                float: right;
                margin-right: -7px;
                background: url(../../../images/carat-down.svg) no-repeat;
                height: 21px;
                width: 21px;
                font-size: 0;
                &.fa-angle-up {
                    display: none;
                }
            }
        }
        .clear-refinement {
            display: none;
        }
        .expended {
            display: none;
            position: absolute;
            background: white;
            width: auto;
            box-sizing: border-box;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            border: 1px solid $dim-gray;
            z-index: 3;
            max-height: 510px;
            overflow: hidden;
            max-width: 720px;
            @media screen and (min-width: 1024px) and (max-width: 1260px) {
                max-width: 590px;
                .expended-main {
                    .scroll-bar-width {
                        ul {
                            width: 176px;
                        }
                    }
                }
            }
            .scroll-bar-height {
                margin: 20px 20px 10px 30px;
                box-sizing: border-box;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                overflow-x: auto !important;
                padding: 1px;
            }
            @media screen and (min-width: 1024px) {
                &.refinementcount-lessthanten {
                    width: 100%;
                    width: calc(100% + 1px);
                    .scroll-bar-height {
                        margin: 20px 10px 10px 32px;
                        ul {
                            width: 100%;
                        }
                    }
                }
            }
            .expended-main {
                position: relative;
                white-space: nowrap;
                ul {
                    padding: 0;
                    width: 164px;
                    display: inline-block;
                    vertical-align: top;
                    @media screen and (max-width: 1023px) {
                        width: 198px;
                    }
                    &.refinementColor li a {
                        height: 12px;
                        padding: 0;
                        text-indent: 50px;
                        width: 30px;
                        overflow: visible;
                        border-radius: initial;
                        border: 1px solid transparent;
                        margin: 5px;
                        position: relative;
                        text-transform: uppercase;
                        line-height: 14px;
                        float: left;
                        white-space: inherit;
                    }
                    &.silhoutteheadermenu {
                        width: 520px;
                        @media screen and (max-width: 1023px) {
                            width: 100%;
                        }
                        .silhouetteHeader {
                            width: 100%;
                            text-transform: uppercase;
                            float: left;
                            color: $SW-navy-blue;
                            @include GothamBold($Gotham);
                            font-size: $base-font - 1px !important;
                            padding: 0 0 10px 0;
                            letter-spacing: 1px;
                        }
                        li {
                            width: 33.33%;
                            float: left;
                            padding: 0 0 20px 0;
                            text-transform: uppercase;
                            @media screen and (max-width: 480px) {
                                width: 50%;
                            }
                        }
                    }
                    &.swatches {
                        li {
                            float: left;
                            padding: 0 0 15px 0;
                            width: 100%;
                            &.color-refinement-count {
                                padding: 0;
                            }
                            .available {
                                line-height: normal;
                            }
                            .notAvailable {
                                opacity: 0.2;
                                a {
                                    cursor: not-allowed;
                                    pointer-events: none;
                                }
                            }
                            &.selected {
                                a {
                                    border-color: transparent;
                                    font-weight: normal;
                                    &::after {
                                        content: "";
                                        position: absolute;
                                        height: 20px;
                                        width: 40px;
                                        left: -6px;
                                        top: -5px;
                                        border: 1px solid $black;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            li {
                a {
                    font-size: $base-font;
                    @include GothamBook($GothamBook);
                    line-height: 20px;
                    white-space: normal;
                    letter-spacing: 0;
                    color: $s4a-grey;
                    .count {
                        color: $silver;
                    }
                }
                .notAvailable {
                    opacity: 0.2;
                    a {
                        cursor: not-allowed;
                        pointer-events: none;
                    }
                }
                span {
                    &.check-square {
                        width: 17px;
                        height: 17px;
                        margin-right: 10px;
                        float: left;
                        border: 2px solid $white;
                        vertical-align: middle;
                        outline: 1px solid $s4a-navy-blue;
                        margin-top: 2px;
                        box-sizing: border-box;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                    }
                }
                &.selected {
                    span {
                        &.check-square-bold {
                            width: 17px;
                            height: 17px;
                            margin-right: 10px;
                            float: left;
                            border: 2px solid $white;
                            background: $black;
                            outline: 1px solid $black;
                            margin-top: 2px;
                            box-sizing: border-box;
                            -webkit-box-sizing: border-box;
                            -moz-box-sizing: border-box;
                        }
                    }
                }
            }
            &.refinementsCombinations-main {
                width: auto;
                left: initial;
                .expended-main {
                    max-height: 450px;
                    overflow: hidden;
                }
                ul {
                    width: 220px;
                    display: inline-block;
                    padding: 0;
                    vertical-align: top;
                    .refinementsCombinations-header {
                        font-size: $base-font + 2px;
                        font-family: $HurmeLight;
                        padding: 0 0 15px 0;
                        text-transform: uppercase;
                    }
                    .available,
                    .notAvailable {
                        width: auto;
                        float: left;
                        margin: 0 10px 10px 0;
                        &.selected {
                            a {
                                background: $black;
                                color: $white;
                                span {
                                    &.count {
                                        color: $white;
                                    }
                                }
                            }
                        }
                        a {
                            width: 60px;
                            height: 30px;
                            border: 1px solid $black;
                            display: inline-block;
                            text-align: center;
                            line-height: 30px;
                        }
                    }
                    .notAvailable {
                        opacity: 0.2;
                        a {
                            cursor: not-allowed;
                            pointer-events: none;
                        }
                    }
                }
            }
        }
        li {
            padding: 0 0 25px 0;
        }
    }
}
//search result count
.search-result-options {
    width: 100%;
    float: left;
    padding: 0;
    background-color: $white;
    border-bottom: 1px solid $SW-navy-blue;
    margin: 0 0 30px 0;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    overflow: visible;
    position: relative;
    &.paging {
        background: transparent;
        margin: 0;
        padding: 0;
    }
    .result-count {
        @include GothamBook($GothamBook);
        font-size: $base-font;
        letter-spacing: 1px;
        color: $SW-gray;
        width: auto;
        float: left;
        vertical-align: middle;
        line-height: 40px;
        padding: 0 21px;
        margin-top: 0 !important;
        span {
            color: $black;
        }
    }
}
.sort-by {
    width: auto;
    float: right;
    margin: 0;
    font-size: $base-font - 1px;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 0 15px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    background: transparent;
    &.custom-select {
        .selected-option {
            @include GothamBook($GothamBook);
            font-size: $base-font;
            color: $SW-gray;
            letter-spacing: 1px;
            padding-right: 25px;
        }
    }
    &.current_item {
        background-color: $SW-navy-blue;
        color: $white;
        .selected-option {
            &.selected {
                color: $white;
            }
            &:before {
                background: url("../../../images/carat-up-gray.svg") no-repeat;
                width: 21px;
                height: 21px;
                background-color: transparent;
            }
        }
        .selection-list {
            border: solid 2px $black;
            z-index: 1;
            padding: 0;
            background: $light-skygrey;
            li {
                box-sizing: border-box;
                padding: 15px 0 10px 50px;
                border: none;
                @include GothamBook($GothamBook);
                color: $SW-gray !important;
                background-color: $white !important;
                text-transform: capitalize;
                font-size: $base-font;
                &.selected {
                    background: $alabaster;
                    color: $black;
                    display: block;
                    &:before {
                        content: "";
                        background: url("../../../images/circle-selected.png")
                            no-repeat;
                        width: 17px;
                        height: 17px;
                        margin: 0 0 0 20px;
                        padding: 0;
                        left: 0;
                        background-size: 100%;
                        position: absolute;
                    }
                }
                &:before {
                    content: "";
                    background: url("../../../images/circle.png") no-repeat;
                    width: 17px;
                    height: 17px;
                    margin: 0 0 0 20px;
                    padding: 0;
                    left: 0;
                    background-size: 100%;
                    position: absolute;
                }
                &:hover {
                    background: $alabaster;
                    color: $black;
                }
            }
        }
    }
    form {
        width: auto;
        float: left;
        padding-right: 10px;
        font-family: $HelveticaBold;
        line-height: 40px;
        label {
            @include GothamBold($Gotham);
            font-size: $base-font;
            color: $SW-navy-blue;
            letter-spacing: 1px;
            display: block;
            margin-bottom: 0;
            line-height: 40px;
            &:after {
                content: ":";
            }
        }
    }
    .selected-option {
        padding: 0;
        border: none;
        background: transparent;
        line-height: 40px;
        float: left;
        width: auto;
        @include WorkSansRegular($WorkSans);
        letter-spacing: 0.5px;
        font-size: $base-font + 1px;
        min-height: auto;
        z-index: 0;
        text-transform: capitalize;
        &:before {
            background: url(../../../images/carat-down.svg) no-repeat;
            width: 21px;
            height: 21px;
            padding: 0;
            margin: 0;
            right: -2px;
            top: 30%;
            background-color: transparent;
        }
    }
}
@media screen and (min-width: 1024px) {
    .pt_product-search-result {
        #main {
            padding: 0 20px;
            box-sizing: content-box;
        }
    }
}
@media screen and (max-width: 1024px) {
    .sort-by {
        form {
            select {
                display: none;
            }
        }
        &.custom-select {
            .selected-option {
                display: block;
            }
            &:after {
                background: none;
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .pt_product-search-result {
        .category-search {
            .simplesearch {
                padding: 17px 11px 17px 25px;
                font-size: $base-font + 1px;
                input[type="text"] {
                    width: 85%;
                    font-size: $base-font + 12px;
                }
                button {
                    width: 20px;
                    height: 25px;
                    margin-top: 0;
                }
            }
        }
    }
}
@media screen and (max-width: 480px) {
    .pt_product-search-result {
        .category-search {
            .simplesearch {
                input[type="text"] {
                    width: 80%;
                }
            }
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .pt_product-search-result {
        &.product-list-page {
            .result-count-mobile {
                height: 40px;
                padding: 13px 20px;
            }
        }
        .promo-banner {
            margin: 14px auto 11px;
        }
        .refinements {
            .filters {
                padding: 10px 26px 10.5px 40px;
                height: 40px;
            }
            .sort-by {
                padding: 9.5px 26px 3.5px 40px;
                height: 40px;
                &.custom-select {
                    .selected-option {
                        width: calc(100% - 97px);
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1023px) {
    .refinements {
        width: calc(100% - 20px);
        width: -moz-calc(100% - 20px);
        width: -webkit-calc(100% - 20px);
        width: -o-calc(100% - 20px);
        margin: 0 10px !important;
    }
    .sort-by {
        form {
            label {
                margin-bottom: 0.3rem;
                line-height: 20px;
            }
        }
    }
    .pt_product-search-result {
        .category-search {
            .simplesearch {
                margin: 0 0 20px 0;
            }
        }
    }
    .refinements {
        .sort-by {
            &.custom-select {
                .selected-option {
                    width: 80%;
                    min-height: 100%;
                }
            }
            .selected-option {
                &:before {
                    margin-top: -5px;
                }
            }
        }
        .mobile-refinement {
            width: 100%;
            display: block;
        }
    }
    .pt_product-search-result {
        &.product-list-page {
            .result-count-mobile {
                width: calc(100% - 20px);
                width: -moz-calc(100% - 20px);
                width: -webkit-calc(100% - 20px);
                width: -o-calc(100% - 20px);
                margin-left: 10px;
                margin-right: 10px;
            }
            .category-search {
                display: none;
            }
            .refinements {
                margin-bottom: 0 !important;
            }
        }
        &.product-search-page {
            .result-count-mobile {
                display: none !important;
            }
            .primary-content {
                width: 100%;
                float: left;
                margin-top: 10px;
            }
        }
    }
    .result-count-mobile {
        display: block;
        width: 100%;
        float: left;
        text-align: center;
        padding: 15px 20px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        text-transform: uppercase;
        margin-bottom: 10px;
        background: rgba($FB-lighter-gray, 0.5);
        @include GothamBook($GothamBook);
        font-size: $base-font;
        letter-spacing: 1px;
        color: $SW-gray;
    }
    .sort-by {
        width: 50%;
        float: left;
        padding: 6px 0 7px 20px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        border: 1px solid $dark-gray;
        border-left: none;
        font-size: $base-font - 1px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-family: $HelveticaBold;
        select {
            display: none;
        }
        &.custom-select {
            &::after {
                background: none;
            }
        }
        &.current_item {
            border-top: none;
            padding: 14px 0 9px 20px;
            .selection-list {
                border-top: none;
                top: 37px !important;
                display: block;
            }
        }
        form,
        .selected-option {
            line-height: 20px;
        }
        .selected-option {
            width: 280px;
            display: block !important;
        }
    }
    .refinements {
        border: none;
        position: relative;
        .filters {
            width: 50%;
            float: left;
            padding: 16px 40px;
            box-sizing: border-box;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            border: 1px solid $dark-gray;
            position: relative;
            &.refinement-mobile {
                background: $SW-navy-blue;
                border: none;
                i {
                    display: none;
                    background: url(../../../images/carat-up-gray.svg) no-repeat;
                    &.fa-angle-up {
                        display: block;
                    }
                }
                .filter {
                    color: $white;
                }
                .refinements-count {
                    display: block !important;
                }
            }
            &.active-css {
                position: static;
                .refine-by-clearall {
                    display: none;
                }
            }
            i {
                float: right;
                background: url(../../../images/carat-down.svg) no-repeat;
                height: 21px;
                width: 21px;
                font-size: 0;
                margin-top: 3px;
                &.fa-angle-up {
                    display: none;
                }
            }
            .filter {
                display: block;
                @include GothamBold($Gotham);
                font-size: $base-font;
                color: $SW-navy-blue;
                letter-spacing: 2px;
                float: left;
                width: 100%;
            }
        }
        .refinements-count {
            position: absolute;
            background: white;
            width: 100%;
            left: 0;
            box-sizing: border-box;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            border: 2px solid $black;
            padding: 19px 20px 10px;
            z-index: 4;
            top: 37px;
            display: none;
            .refine-by-clearall {
                padding: 15px 20px 5px;
                width: 100%;
                float: left;
                box-sizing: border-box;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                text-transform: uppercase;
                letter-spacing: 1px;
                font-family: $HelveticaBold;
                a {
                    font-size: $base-font - 1px;
                    color: $dim-gray;
                }
            }
            .refinement {
                width: 100%;
                border: none;
                padding: 0 20px;
                box-sizing: border-box;
                &.active {
                    .expended {
                        display: block !important;
                        max-width: 100%;
                        @media screen and (min-width: 768px) and (max-width: 1023px) {
                            overflow-y: scroll;
                        }
                    }
                    .toggle-heading {
                        @media screen and (min-width: 768px) and (max-width: 1023px) {
                            width: auto;
                            display: inline-block;
                        }
                    }
                }
                .clear-refinement {
                    display: none;
                }
                .refinementsCombinations-main {
                    .expended-main {
                        position: static;
                        white-space: normal;
                        overflow-y: scroll;
                        ul {
                            width: 100%;
                            padding: 0 !important;
                        }
                    }
                }
                .toggle-heading {
                    @media screen and (min-width: 768px) and (max-width: 1023px) {
                        width: 100%;
                        display: block;
                    }
                }
                .refinement-heading-category {
                    border: none;
                    @media screen and (min-width: 768px) and (max-width: 1023px) {
                        border-bottom: 1px solid $very-light-gray;
                        padding: 14px 0 13px;
                    }
                    .count {
                        color: $dim-gray;
                    }
                    .fa-angle-up {
                        @media screen and (max-width: 1023px) {
                            background: url(../../../images/icon_rightarrow.svg)
                                no-repeat;
                            margin: -3px;
                        }
                    }
                }
                &:last-child {
                    @media screen and (min-width: 768px) and (max-width: 1023px) {
                        .refinement-heading-category {
                            border-bottom: 0;
                        }
                    }
                }
                .expended {
                    border: none;
                    width: 100%;
                    position: relative;
                }
                &.active {
                    padding: 0;
                    .refinement-heading-category {
                        border-bottom: 1px solid $very-light-gray;
                        background: transparent;
                        color: $black;
                        margin: 0 40px;
                        padding: 15px 5px;
                        border-right: none;
                        @media screen and (min-width: 768px) and (max-width: 1023px) {
                            width: calc(100% - 80px);
                        }
                        .fa-angle-down {
                            display: block;
                            color: $dim-gray;
                            float: left;
                            left: 0;
                            position: absolute;
                            margin-top: -3px;
                            transform: rotate(270deg);
                        }
                        i {
                            display: none;
                        }
                    }
                    .expended {
                        .scroll-bar-height {
                            margin: 20px 50px 10px;
                            overflow-x: auto !important;
                            box-sizing: border-box;
                            -webkit-box-sizing: border-box;
                            -moz-box-sizing: border-box;
                        }
                    }
                    .clear-refinement {
                        display: block;
                        float: right;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .pt_product-search-result {
        .refinements {
            &.refinement_fixed {
                position: fixed;
                z-index: 2;
                background: $white;
            }
        }
        &.product-list-page {
            .primary-content {
                margin-top: 20px;
                float: left;
            }
        }
    }
    .sort-by {
        padding: 9px 15px;
        position: static;
        border-radius: 0;
        form {
            width: 100%;
            label {
                margin-bottom: 0;
                &:after {
                    content: "";
                }
            }
        }
        &.current_item {
            padding-top: 10px;
            .selected-option {
                &.selected {
                    background: transparent;
                }
                &:before {
                    margin-top: -2px;
                }
            }
            .selection-list {
                left: 0;
                top: 39px !important;
                border-top: 2px solid black;
                background: $white;
                li.selected {
                    background: $white;
                }
            }
        }
    }
    .refinements {
        .sort-by {
            &.custom-select {
                .selected-option {
                    position: absolute;
                    width: 40%;
                    right: 20px;
                    text-indent: -9999px;
                    &:before {
                        margin-top: -9px;
                    }
                }
            }
        }
        .refinements-count {
            padding: 19px 7px 10px;
            top: 38px;
            .refinement {
                border-bottom: 1px solid $very-light-gray;
                &:nth-last-child(1) {
                    border-bottom: none;
                }
            }
            .refine-by-clearall {
                position: absolute;
                width: 95%;
                left: 0;
                bottom: 0;
                margin: 0 10px;
                box-sizing: border-box;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                padding: 20px 0;
                border-top: 2px solid $very-light-gray;
                z-index: 4;
                background: $white;
                a {
                    padding: 0 20px;
                    &.view-results {
                        float: right;
                        color: $black;
                    }
                    &.backbutton {
                        display: none;
                    }
                }
            }
        }
        .filters {
            position: static;
            padding: 9.5px 15px;
            border-radius: 0;
            .mobile-refinement {
                width: 100%;
            }
            &.refinement-mobile {
                .refinements-count {
                    height: 520px;
                }
            }
            .refinement {
                padding: 18px 0;
                .refinement-heading-category {
                    padding-top: 0;
                    padding-bottom: 0;
                    .count {
                        width: 100%;
                        padding: 7px 0 0 0;
                        font-family: $HelveticaMedium;
                        font-weight: normal;
                        color: $dark-gray;
                        font-size: $base-font - 1px;
                        display: none;
                        &.selected {
                            display: block;
                        }
                    }
                }
                &.active {
                    padding: 0;
                }
            }
            &.active-css {
                .refinements-count {
                    .refinement {
                        border-bottom: none;
                        .refinement-heading-category {
                            margin: 0;
                            padding: 15px;
                            i {
                                display: none;
                            }
                            .count {
                                display: none;
                            }
                        }
                        &.refinementColor {
                            .expended {
                                padding: 20px 0;
                                .expended-main {
                                    position: relative;
                                    white-space: nowrap;
                                    ul {
                                        width: 50%;
                                    }
                                }
                            }
                        }
                        .expended {
                            overflow: hidden;
                            max-height: 100%;
                            .scroll-bar-height {
                                margin: 20px 15px;
                                box-sizing: border-box;
                                -webkit-box-sizing: border-box;
                                -moz-box-sizing: border-box;
                            }
                            .expended-main {
                                position: relative;
                                white-space: normal;
                                ul {
                                    width: 100%;
                                }
                            }
                            &.refinementsCombinations-main {
                                ul {
                                    .available,
                                    .notAvailable {
                                        a {
                                            width: 50px;
                                            display: block;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .refine-by-clearall {
                    display: block;
                    a {
                        &.backbutton {
                            display: block;
                            color: $black;
                            width: auto;
                            float: left;
                        }
                        &.device-clear-all {
                            display: none;
                        }
                    }
                }
            }
            .filter-count {
                font-family: $WorkSans;
                font-size: $base-font;
                color: $dark-gray;
            }
        }
    }
    .view-more {
        &.hide-desktop {
            margin: 0 0 45px;
        }
        .viewmore-grid {
            display: inline-block;
            width: 238px;
            border-radius: 3px;
            padding: 15px 0;
            float: none;
            background: $black;
            color: $white;
        }
    }
}
//Filters
.refinement-breadcrumb,
.sort-breadcrumb {
    padding: 14px 14px 1px;
    width: 100%;
    float: left;
    text-transform: uppercase;
    font-size: $base-font - 1px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    @media screen and (max-width: 1023px) {
        padding: 3px 14px 1px;
    }
    .breadcrumb-refined-by {
        width: auto;
        float: left;
        padding-right: 10px;
        @include WorkSansBold($WorkSans);
        letter-spacing: 1px;
        line-height: 23px;
        @media screen and (min-width: 768px) and (max-width: 1023px) {
            line-height: 28px;
        }
    }
    .breadcrumb-refinement {
        font-weight: 400;
        &:after {
            content: "";
        }
    }
    .refine-by-clearall {
        float: right;
        .breadcrumb-refinement-value {
            margin-right: 0;
            background: transparent;
            margin-bottom: 0;
        }
    }
    .breadcrumb-refinement-value {
        @include WorkSansMedium($WorkSans);
        padding: 5px 10px;
        background: $wild-sand;
        margin: 0;
        border-radius: 0;
        font-size: $base-font - 1px;
        letter-spacing: 1px;
        margin-right: 10px;
        margin-bottom: 10px;
        display: inline-block;
        @media screen and (min-width: 768px) and (max-width: 1023px) {
            margin-bottom: 10px;
            display: inline-block;
        }
    }
    .breadcrumb-relax {
        background: none;
        color: $black;
        font-style: normal;
        padding: 0 0 0 0.4em;
    }
}
.sort-breadcrumb {
    .breadcrumb-refinement {
        @include WorkSansMedium($WorkSans);
        background: $wild-sand;
        padding: 5px 10px;
        font-size: $base-font - 1px;
        letter-spacing: 1px;
        margin-right: 10px;
        line-height: 20px;
    }
}
.breadcrumb-refinement-value {
    .refinement-color {
        display: inline-block;
        width: 30px;
        height: 12px;
        vertical-align: middle;
        line-height: normal;
    }
}
@media screen and (max-width: 767px) {
    .refinement-breadcrumb {
        display: none;
    }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .refinements {
        .refinement {
            .expended {
                .expended-main {
                    ul {
                        &.refinementColor {
                            width: 342px;
                            li {
                                &.color-refinement-count {
                                    width: 50%;
                                    padding: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .view-more {
        .viewmore-grid {
            background: $black;
            color: $white;
        }
    }
}
@media screen and (max-width: 767px) {
    body.single-column-refinement
        #main
        .refinements
        .filters
        .refinements-count
        .refinement {
        .refinement-heading-category i.fa-angle-up {
            background: url(../../../images/carat-down.svg) no-repeat;
        }
        &.active {
            .refinement-heading-category i.fa-angle-up {
                position: absolute;
                right: 0px;
                top: 12px;
                display: block;
            }
        }
    }
}
#primary .search-result-options {
    border-bottom: none;
}
