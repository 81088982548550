.pt_account {
    .reset-message-notify {
        @include GothamMedium($Gotham);
    }
}
.email-trouble-message {
    @include GothamMedium($Gotham);
    font-size: $base-font + 1px;
    color: $white;
    background: $dim-gray;
}
.account-overview-heading {
    font-size: $base-font + 10px;
    letter-spacing: 2px;
    @include GothamBold($GothamBold);
    color: $SW-navy-blue;
    text-transform: uppercase;
}
//My Account Overview
#secondary {
    .secondary-navigation {
        max-width: 1024px;
        .main {
            margin: 12px 0;
            font-size: $base-font + 14px;
            @include GothamBold($GothamBold);
            letter-spacing: 2px;
            color: $SW-navy-blue;
        }
        .navigation-links-row {
            li {
                margin: 20px 39px 0;
                &.navigation-links-row-inner {
                    li {
                        margin: 30px 30px 0;
                        word-spacing: 2px;
                    }
                }
                a {
                    font-size: $base-font + 2px;
                    @include GothamMedium($Gotham);
                    letter-spacing: 1px;
                    color: $SW-gray;
                    &:after {
                        content: "";
                        display: block;
                        transform: scaleX(0.1);
                        height: 3px;
                        background: transparent;
                        transition: transform 0.3s;
                        position: relative;
                        top: 2px;
                    }
                    &:hover {
                        &:after {
                            transform: scaleX(1);
                            background: black;
                        }
                    }
                }
            }
        }
    }
}
#wrapper {
    @each $pagename in $pagenames {
        &.#{nth($pagename, 1)} {
            #secondary {
                .secondary-navigation {
                    .secondary-navigation-links {
                        li {
                            &.#{nth($pagename, 2)} {
                                a {
                                    @include GothamBold($GothamBold);
                                    color: $SW-navy-blue;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 767px) {
    #secondary {
        .secondary-navigation {
            .secondary-navigation-links {
                padding: 0 20px 20px 20px;
                background: $light-skygrey;
                &.active {
                    display: block;
                }
            }
            .heading {
                background: $light-skygrey;
                font-size: $base-font + 2px;
                @include GothamBold($Gotham);
                letter-spacing: 1px;
                color: $SW-navy-blue;
            }
            .navigation-links-row {
                li {
                    &.navigation-links-row-inner {
                        li {
                            padding: 0;
                            word-spacing: normal;
                        }
                    }
                }
            }
        }
    }
}
