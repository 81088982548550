@media screen and (max-width: 767px) {
    .size-guide-content {
        &.active-menu {
            .size-guide-navigation__dropdown_selected {
                .selected-category {
                    color: $black;
                }
            }
        }
    }
}
.size-guide-container {
    .title {
        @include WorkSansMedium($WorkSans);
        font-size: $base-font + 12px;
        @media screen and (max-width: 767px) {
            font-size: $base-font + 12px;
        }
    }
    .size-guide-navigation__dropdown_selected {
        @include WorkSansMedium($WorkSans);
        color: $black;
    }
    .size-guide-navigation {
        a {
            @include WorkSansMedium($WorkSans);
            font-size: $base-font;
            &:hover,
            &.selected {
                color: var(--color-primary);
                border-color: var(--color-primary);
                @media screen and (min-width: 768px) {
                    @include WorkSansMedium($WorkSans);
                }
            }
        }
    }
}
