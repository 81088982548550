$white-smoke: #F4F4F4;
$white-smokedip: #f5f5f5;
$shady-lady: #979797;
$manatee: #8d89a5;
$blue-haze: #b3b0c4;
$midnight-blue: #172082;
$la-palma: #36a836;
$medium-carmine: #af3434;
$fire-engine-red: #ba1616;
$geraldine: #e57777;
$FB-light-gray: #c6c4d2;
$FB-lighter-gray: #d8d7df;
$FB-lightest-gray: #ebebef;
$FB-dark-gray: #4b4b4b;
$FB-mercury-light: #E8E8E8;
$FB-Pale-Slate: #D8D7D8;
$FB-Alabaster: #F9F9F9;
$FB-light-mercury-gray: #e6e6e6;
$FB-dark-grey: #d7d7d7;
$FB-light-black: #1a1a1a;
$SW-navy-blue: #002554;
$SW-gray: #7D7373;
$SW-light-gray:#f2f2f2; 
$SW-dark-gray:  #585351;
$SW-green: #008000;
$SW-light-blue: #1C78BA;
$SW-gainsboro: #e3e3e3;
$mortar-grey: #525252;
$mangotango: #c55300;