/* Body */
body {
    @include WorkSansMedium($WorkSans);
    font-size: $base-font;
}
/* Text */
h1 {
    font-family: $HurmeBlack;
    font-size: $base-font + 52px;
    font-weight: 600;
    letter-spacing: -2px;
}
h2:not(.recommendation-heading):not(.dont-use-legacy-sitegen) { 
    font-family: $HurmeBlack;
    font-size: $base-font + 32px;
    letter-spacing: -1.6px;
}
h3 {
    font-family: $HurmeBlack;
    font-size: $base-font + 20px;
}
h5 {
    @include WorkSansMedium($WorkSans);
    font-size: $base-font + 12px;
}
p:not(.dont-use-legacy-sitegen) {
    @include WorkSansMedium($WorkSans);
    font-size: $base-font + 1px;
}
/* Navigation */
nav a {
    font-family: $HurmeBlack;
    font-size: $base-font;
    color: white;
}
/* Form */
form label {
    font-family: $WorkSans;
    font-size: $base-font + 2px;
    color: $dim-gray;
}
input[type="checkbox"] {
    &:checked {
        background: url("../../../images/check.svg") no-repeat center;
    }
}
input[type="text"],
.input-textarea {
    border-radius: 0;
}
textarea {
    resize: none;
}
/* Table */
table {
    width: 100%;
}
th {
    text-align: left;
}
td {
    padding: 1em;
    vertical-align: top;
}
/* CTA */
button:not(.btn-ds):not([class*="tt-o-button"]),
.button,
.buttonstyle,
input[type="button"] {
    background: $sw-brand-blue;
    border-color: $sw-brand-blue;
    border-style: solid;
    border-width: 2px;
    border-radius: 0;
    color: $white;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    padding: 13px 30px;
    text-align: center;
    transition: all 0.3s ease;
    text-transform: uppercase;
    outline: 0;
    letter-spacing: 1.5px;
    font-family: $HurmeBlack;
    font-weight: 900;
    &[disabled],
    &.disabled,
    &.atb-disabled {
        color: $white;
    }
    &.solid-wine-berry {
        background: $black;
        border-color: $black;
        &:hover {
            @extend .solid-wine-berry;
        }
    }
    &.secondary {
        background: $white;
        border: solid 2px $sw-brand-blue;
        color: $sw-brand-blue;
    }
    &.simple {
        background-color: $manatee;
        color: $white;
    }
    a {
        color: $white;
    }
    &.transparent {
        background: transparent;
        border-color: $white;
        color: $white;
    }
    &.white {
        border-color: $white;
    }
    &.transperent-site-specific {
        background: transparent;
        border-color: $sw-brand-blue;
        color: $sw-brand-blue;
        &:hover {
            @extend .transperent-site-specific;
        }
    }
}
.buttonOulined {
    background: $white;
    border-color: $black;
    color: $black;
}
.button-text {
    color: white;
}
/* Back To Top */
.pt_product-search-result {
    a {
        &.back-to-top {
            width: 50px;
            height: 48px;
            right: 15px;
            bottom: 25%;
            background: url("../../../images/fb/back-to-top.svg") no-repeat
                center 43% transparent;
            background-size: 100%;
        }
    }
}
@media screen and (max-width: 1024px) {
    button:not(.btn-ds):not([class*="tt-o-button"]),
    .button,
    .buttonstyle,
    input[type="button"] {
        font-size: $base-font + 2px;
    }
}
@media only screen and (max-width: 767px) {
    button:not(.btn-ds):not([class*="tt-o-button"]),
    .button,
    .buttonstyle,
    input[type="button"] {
        &.solid-wine-berry,
        &.transperent-site-specific {
            padding-left: 0;
            padding-right: 0;
            width: 100%;
        }
    }
}
