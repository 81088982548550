$sans-serif: "Inter Tight", "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$serif: TimesNewRoman,"Times New Roman",Times,Baskerville,Georgia,serif;

$Helvetica : $sans-serif;
$HelveticaBold: $sans-serif;
$HelveticaMedium: $sans-serif;
$Lora: $serif;
$WorkSans: $sans-serif;
$sans-serif-alt: $sans-serif;
$BauerBodoniRegular: $sans-serif;
$ImperialRegular: $serif;
$ImperialMedium: $serif;
$HurmeBlack: $sans-serif;
$HurmeLight: $sans-serif;
$Hurme: $sans-serif;
$HurmeSemiBold: $sans-serif;
$HurmeBold: $sans-serif;
$Gotham: "Gotham A", "Gotham B", $sans-serif;
$GothamThin: "Gotham Cond A", "Gotham Cond B", $sans-serif;
$GothamLight: "Gotham Cond A", "Gotham Cond B", $sans-serif;
$GothamBold: "Gotham A", "Gotham B", $sans-serif;
$GothamBook: "Gotham A", "Gotham B", $sans-serif;
@mixin GothamThin($GothamThin) {
    font-family: $GothamThin;
    font-weight: 500;
}
@mixin GothamLight($GothamLight) {
    font-family: $GothamLight;
    font-weight: 500;
}
@mixin GothamMedium($Gotham) {
    font-family: $Gotham;
    font-weight: 500;
}
@mixin GothamBold($Gotham) {
    font-family: $GothamBold;
    font-weight: 700;
}
@mixin GothamBook($GothamBook) {
    font-family: $GothamBook;
    font-weight: 500;
}
@mixin Gotham($Gotham) {
    font-family: $Gotham;
    font-weight: 400;
}