.legacy-sitegen .pt_wish-list {
    .wishlist-main {
        h1 {
            text-transform: uppercase;
            @include GothamBold($Gotham);
            font-size: $base-font + 10px;
            letter-spacing: 2px;
            color: $SW-navy-blue;
        }
        h2 {
            font-size: $base-font + 1px;
            @include GothamMedium($Gotham);
            letter-spacing: 0.5px;
            color: $SW-gray;
        }
    }
    .form-row {
        &.birthMonth {
            .custom-select {
                select,
                .selected-option {
                    border-color: $black;
                }
            }
        }
    }
    .create-login {
        label {
            &.input-focus {
                transform: translate(7px, 3px);
            }
        }
        .col-1 {
            .login-box-content {
                .login-rememberme {
                    label {
                        top: 0;
                        position: absolute;
                        line-height: normal;
                    }
                }
            }
        }
        .inner-block {
            .login-box {
                background-color: $SW-light-gray;
            }
        }
        .col-2 {
            .login-create {
                height: auto;
            }
        }
    }
    .primary-content {
        .search-wishlist {
            h1 {
                font-size: $base-font + 12px;
                @include WorkSansMedium($WorkSans);
                letter-spacing: normal;
            }
            h2 {
                font-size: $base-font + 2px;
                @include WorkSansMedium($WorkSans);
                letter-spacing: normal;
            }
            .or-link {
                font-size: $base-font + 2px;
                @include WorkSansMedium($WorkSans);
            }
            .error-message {
                color: $medium-carmine;
                font-size: $base-font;
                @include WorkSansMedium($WorkSans);
            }
            .form-row {
                &.form-row-button {
                    button {
                        letter-spacing: 3px;
                        font-size: $base-font + 4px;
                        padding: {
                            top: 14.5px;
                            bottom: 14.5px;
                        }
                    }
                }
            }
            label {
                font-size: $base-font + 4px;
                letter-spacing: 0.5px;
                top: -6px;
                span {
                    color: $dim-gray;
                    &.error {
                        color: $guardsman-Red;
                    }
                }
                &.input-focus {
                    transform: translate(5px, 8px);
                    span {
                        font-size: $base-font - 1px;
                        letter-spacing: 0.4px;
                        &.error {
                            color: $guardsman-Red;
                        }
                    }
                }
            }
            input[type="text"],
            input[type="number"],
            input[type="password"],
            input[type="date"] textarea {
                padding: 18px 0 16px 15px;
            }
        }
        .search-wishlist-bottom {
            .search-wishilist-box {
                background-color: $SW-light-gray;
                border: none;
                h1 {
                    @include GothamMedium($Gotham);
                    font-size: $base-font + 6px;
                    letter-spacing: 1px;
                    color: $SW-navy-blue;
                }
                .form-row {
                    @media screen and (min-width: 1024px) {
                        width: 21%;
                        margin-right: 10px;
                        &.email_wishlist {
                            width: 31%;
                        }
                    }
                    &.form-row-button {
                        button {
                            @include GothamBold($GothamBold);
                            letter-spacing: 2px;
                            font-size: $base-font + 2px;
                            background: $SW-navy-blue;
                            padding: {
                                top: 16.5px;
                                bottom: 16.5px;
                            }
                        }
                    }
                    label {
                        @include GothamMedium($Gotham);
                        font-size: $base-font;
                        letter-spacing: 1px;
                        color: $SW-navy-blue;
                        span {
                            &:not(.error) {
                                color: $SW-navy-blue;
                            }
                        }
                        &.input-focus {
                            transform: translate(5px, 8px);
                            span {
                                font-size: $base-font - 1px;
                                letter-spacing: 0.4px;
                            }
                        }
                    }
                    input[type="text"],
                    input[type="number"],
                    input[type="password"],
                    input[type="date"] textarea {
                        padding: 18px 0 16px 15px;
                        border: 1px solid $SW-navy-blue;
                    }
                }
            }
        }
        .results-found {
            .heading {
                font-family: $HurmeBlack;
                letter-spacing: 3px;
                font-size: $base-font + 12px;
            }
            .section-header-list {
                .section-header {
                    font-size: $base-font;
                    @include WorkSansBold($WorkSans);
                }
            }
            .item-list {
                .section-item-list {
                    .col-1,
                    .col-2,
                    .col-3,
                    .col-4 {
                        font-size: $base-font;
                        @include WorkSansMedium($WorkSans);
                    }
                    .col-4 {
                        font-size: $base-font - 1px;
                        @include WorkSansBold($WorkSans);
                    }
                }
            }
        }
    }
}
//Wishlist login page
.create-login {
    .password-status {
        span {
            @include GothamBold($GothamBold);
            letter-spacing: 0.5px;
            margin-left: 0;
            font-size: $base-font + 1px;
            color: $SW-navy-blue;
            line-height: 18px;
        }
    }
    .make-label-absolute {
        .form-row {
            &.addtoemaillist {
                label {
                    font-size: $base-font + 1px;
                    @include GothamBook($GothamBook);
                    letter-spacing: 0;
                    color: $SW-gray;
                    &.input-focus {
                        padding: 0 20px;
                    }
                }
            }
            label {
                span {
                    font-size: $base-font + 1px;
                    @include GothamBook($GothamBook);
                    letter-spacing: 0;
                    color: $SW-gray;
                    &.error {
                        color: $medium-carmine;
                    }
                }
            }
        }
    }
    .login-oauth {
        button {
            font-size: $base-font + 2px;
            @include WorkSansSemiBold($WorkSans);
            letter-spacing: 1px;
        }
    }
    .custom-select {
        .selected-option {
            font-size: $base-font;
        }
    }
    button {
        font-size: $base-font + 4px;
        @include GothamBold($GothamBold);
        letter-spacing: 2px;
        background-color: $SW-navy-blue;
    }
    h1 {
        font-size: $base-font + 8px;
        @include GothamMedium($Gotham);
        letter-spacing: 0.5px;
        color: $SW-navy-blue;
        padding: 0 0 15px 0;
        border: {
            width: 2px;
            color: $SW-navy-blue;
        }
    }
    .create-inner-block {
        .login-create {
            box-shadow: none;
            border: 3px solid $SW-navy-blue;
            padding: 32px 64px;
            h1 {
                @include GothamBold($GothamBold);
                font-size: $base-font + 4px;
            }
            @media screen and (max-width: 1023px) {
                padding: 20px;
                margin: 0 10px 0 8px;
            }
            .para {
                font-size: $base-font + 1px;
                @include WorkSansRegular($WorkSans);
            }
            .step {
                h2 {
                    @include WorkSansBold($WorkSans);
                    font-size: $base-font + 2px;
                    letter-spacing: 0.5px;
                    text-transform: capitalize;
                }
                p {
                    @include WorkSansRegular($WorkSans);
                    font-size: $base-font + 1px;
                    letter-spacing: 0.5px;
                    line-height: 21px;
                }
            }
            .create-account {
                padding: 14.5px 45px;
            }
        }
        .login-create-account {
            height: 1006px;
        }
    }
    .login-create-account {
        h2 {
            font-size: $base-font + 1px;
            font-family: $Helvetica;
            letter-spacing: normal;
        }
        .para {
            font-size: $base-font + 1px;
            @include WorkSansMedium($WorkSans);
        }
        .or-section {
            .or-link {
                font-size: $base-font - 1px;
                @include GothamMedium($Gotham);
                color: $SW-navy-blue;
                letter-spacing: 0.5px;
            }
        }
        .legal {
            font-size: $base-font;
            font-family: $HelveticaMedium;
        }
    }
    .col-1 {
        @media screen and (min-width: 768px) and (max-width: 1023px) {
            width: 50%;
        }
        .form-row {
            &.login-rememberme {
                margin: 18px 0 0 0;
                @media screen and (max-width: 767px) {
                    margin: 0 0 10px 0;
                }
                span {
                    @media screen and (max-width: 767px) {
                        color: $black;
                    }
                }
                label {
                    top: -4px;
                    position: absolute;
                    span {
                        font-size: $base-font;
                        @include GothamBook($GothamBook);
                        letter-spacing: 0.5px;
                        color: $SW-gray !important;
                    }
                }
            }
        }
        .form-row-button {
            button {
                @media screen and (max-width: 767px) {
                    margin: 10px 0 0 0;
                }
            }
        }
    }
    .form-caption {
        font-size: $base-font;
        font-family: $Helvetica;
    }
    .inner-block {
        .login-box {
            h2,
            .description {
                font-size: $base-font + 1px;
                @include GothamBook($GothamBook);
                letter-spacing: 0;
                color: $SW-gray;
                line-height: 21px;
            }
            .filled-wineberry {
                padding: 14.5px 46.5px;
            }
            #password-reset {
                font-size: $base-font + 1px;
                @include GothamBook($GothamBook);
                letter-spacing: 0.5px;
                color: $SW-gray;
                text-decoration: underline;
                @media screen and (max-width: 767px) {
                    width: 60%;
                    float: right;
                    white-space: normal;
                    text-align: left;
                    margin: 10px 0 15px 2px;
                }
            }
            .login-data {
                span {
                    @include GothamBold($Gotham);
                    letter-spacing: 0.5px;
                    font-size: $base-font - 1px;
                    color: $SW-navy-blue;
                    padding-bottom: 5px;
                    &.login-brands {
                        @include GothamBook($Gotham);
                        letter-spacing: 1px;
                        font-size: $base-font - 1px;
                        color: $SW-navy-blue;
                        span {
                            @include GothamBook($Gotham);
                            letter-spacing: 1px;
                            font-size: $base-font - 1px;
                            color: $SW-navy-blue;
                        }
                    }
                }
            }
            &.login-account {
                @media screen and (max-width: 1023px) {
                    margin: 0 0 0 10px;
                }
                @media screen and (max-width: 767px) {
                    margin: 0 0 30px 0;
                }
            }
            &.login-general {
                @media screen and (max-width: 1023px) {
                    margin: 20px 0 20px 10px;
                }
                .or-link {
                    @media screen and (min-width: 768px) {
                        margin: 0 0 15px 2px;
                    }
                }
            }
        }
        &.check-order {
            .login-box {
                &.login-order-track {
                    @media screen and (max-width: 1023px) {
                        margin: 0 0 0 10px;
                        height: auto;
                    }
                    @media screen and (max-width: 767px) {
                        margin: 0 0 30px 0;
                        height: auto;
                    }
                }
            }
        }
    }
}
//whishlist email popup
.ui-dialog {
    &.wishlist-email {
        .ui-dialog-titlebar {
            .ui-dialog-title {
                font-size: $base-font + 12px;
                @include WorkSansMedium($WorkSans);
            }
        }
    }
}
.legacy-sitegen .sendtofriend {
    .sub-heading {
        font-size: $base-font + 2px;
        @include WorkSansMedium($WorkSans);
        line-height: 17px;
    }
    .form-row {
        &.subject {
            width: 53%;
            margin: 25px 0 0 0;
        }
        &.sendtome {
            margin: 16px 0 0 2px;
            label {
                font-size: $base-font;
                font-family: $HelveticaMedium;
                letter-spacing: 0.8px;
            }
        }
        &.message {
            input[type="text"] {
                padding: 0 15px 14px 15px;
            }
            label {
                &.input-focus {
                    span {
                        color: inherit;
                    }
                }
            }
        }
        &.form-row-button {
            margin: 20px 0 0 0;
            width: 100%;
            button {
                padding: 14.5px 53px;
                font-family: $HurmeBlack;
                font-size: $base-font + 4px;
                letter-spacing: 3px;
            }
        }
    }
    input[type="text"],
    input[type="number"],
    input[type="password"],
    input[type="date"],
    textarea {
        padding: 18px 0 16px 15px;
        border-color: $black;
    }
}
//My Wishlist share
.legacy-sitegen .list-share {
    .list-title {
        padding: 25px;
        font-size: $base-font + 12px;
        @include WorkSansMedium($WorkSans);
    }
    .top-section {
        margin: 25px 0;
        padding: 0 25px;
        .heading {
            font-size: $base-font + 10px;
            @include GothamBold($GothamBold);
            letter-spacing: 2px;
            color: $SW-navy-blue;
        }
        .share-option {
            font-size: $base-font + 1px;
            @include GothamBold($GothamBold);
            letter-spacing: 1px;
            color: $SW-navy-blue;
            background: transparent;
        }
    }
    .bottom-section {
        padding: 10px 25px;
        background: $SW-light-gray;
        @media screen and (max-width: 767px) {
            .share-option {
                font-size: $base-font + 1px;
                @include GothamBold($GothamBold);
                letter-spacing: 1px;
                color: $SW-navy-blue;
                background: transparent;
            }
        }
        .share-link-content {
            .share-link-copy {
                padding: 10px 20px;
                font-size: $base-font;
                @include GothamMedium($Gotham);
                color: $SW-navy-blue;
                > a {
                    color: $SW-navy-blue;
                }
            }
            .copy-link {
                font-size: $base-font - 1px;
                @include GothamBold($GothamBold);
                color: $SW-navy-blue;
                letter-spacing: 1px;
            }
        }
    }
}
//My Wishlist Items
.legacy-sitegen .item-list {
    .wishlist-row {
        label {
            span {
                &.label-text {
                    @include WorkSansSemiBold($WorkSans);
                }
            }
        }
        .column {
            &.col-1 {
                .item-details {
                    font-size: $base-font - 1px;
                    .name a {
                        font-size: $base-font + 1px;
                        @include GothamMedium($Gotham);
                        color: $SW-navy-blue;
                        letter-spacing: 1px;
                    }
                    
                    .sku {
                        span {
                            &.label {
                                font-size: $base-font - 1px;
                                @include GothamMedium($Gotham);
                                color: $SW-gray;
                                letter-spacing: 0.5px;
                                text-transform: uppercase;
                            }
                            &.value {
                                font-size: $base-font - 1px;
                                @include GothamMedium($Gotham);
                                color: $SW-gray;
                                letter-spacing: 0.5px;
                                text-transform: uppercase;
                            }
                        }
                    }
                    .attribute,
                    .Quantity,
                    .product-availability-list,
                    .price, .optioninfo {
                        span {
                            &.label {
                                font-size: $base-font;
                                @include GothamMedium($Gotham);
                                color: $SW-navy-blue;
                                letter-spacing: 1px;
                            }
                            &.value {
                                font-size: $base-font + 1px;
                                @include GothamBold($GothamBold);
                                color: $SW-navy-blue;
                                letter-spacing: 1px;
                            }
                            &.is-in-stock {
                                font-size: $base-font + 1px;
                                @include GothamBold($GothamBold);
                                color: $SW-navy-blue;
                                letter-spacing: 1px;
                            }
                            &.price-sales {
                                font-size: $base-font + 1px;
                                @include GothamBold($GothamBold);
                                color: $SW-navy-blue;
                                letter-spacing: 1px;
                            }
                            &.price-sale-standard {
                                color: $guardsman-Red;
                            }
                        }
                    }
                    .product-special-messages {
                        font-size: $base-font;
                        color: $caribbean-green;
                        @include GothamMedium($Gotham);
                    }
                }
            }
            &.col-2 {
                .price {
                    @include WorkSansMedium($WorkSans);
                    font-size: $base-font + 2px;
                    margin-top: 68px;
                    span {
                        .price-sale-standard {
                            color: $medium-carmine;
                        }
                    }
                }
            }
            &.col-3 {
                .option-date-added {
                    .label {
                        font-size: $base-font;
                        @include GothamMedium($Gotham);
                        color: $SW-navy-blue;
                        letter-spacing: 1px;
                    }
                    .value {
                        font-size: $base-font + 1px;
                        @include GothamBold($GothamBold);
                        color: $SW-navy-blue;
                        letter-spacing: 1px;
                    }
                }
                .option-add-to-cart {
                    display: block;
                    button {
                        &.add-to-cart {
                            background: $SW-navy-blue;
                            padding: 16px 35px;
                            @include GothamBold($GothamBold);
                            letter-spacing: 2px;
                            font-size: $base-font + 2px;
                            height: 50px;
                            border-bottom-left-radius: 0;
                            border-top-left-radius: 0;
                        }
                    }
                    .custom-select {
                        .selected-option {
                            padding: 0 16px;
                            border: 1px solid $SW-navy-blue;
                            font-size: $base-font + 2px;
                            @include GothamMedium($Gotham);
                            color: $SW-navy-blue;
                            letter-spacing: 1px;
                        }
                        @media screen and (max-width: 1024px) {
                            select {
                                padding: 0 11px;
                                border: 1px solid $SW-navy-blue;
                            }
                        }
                    }
                }
                .edit-section {
                    margin: 20px 0 0 0;
                    font-size: $base-font - 1px;
                    font-family: $HurmeBlack;
                    .edit-wishlist-detail {
                        padding-right: 13px;
                    }
                    .option-update {
                        float: left;
                        padding-left: 13px;
                        font-size: $base-font - 1px;
                        @include GothamBold($GothamBold);
                        color: $SW-navy-blue;
                        letter-spacing: 1px;
                        button {
                            padding: 0;
                            font-size: $base-font - 1px;
                            @include GothamBold($GothamBold);
                            color: $SW-navy-blue;
                            letter-spacing: 1px;
                        }
                    }
                }
                .option-toggle-public {
                    margin: 20px 0 0 0;
                }
            }
        }
    }
}
//Wishlist Empty
.legacy-sitegen .wishlist-empty {
    .heading {
        font-family: $HurmeBlack;
        letter-spacing: 3px;
        font-size: $base-font + 12px;
        text-transform: uppercase;
    }
    h2 {
        font-size: $base-font + 2px;
        @include WorkSansMedium($WorkSans);
        letter-spacing: normal;
    }
    .qa-section {
        .qa-content {
            @include WorkSansBold($WorkSans);
            font-size: $base-font + 2px;
            .answer {
                font-family: $WorkSans;
                font-size: $base-font + 2px;
            }
        }
    }
}
@media screen and (max-width: 1023px) {
    .legacy-sitegen .pt_wish-list {
        .primary-content {
            //My Wishlist bottom section
            .search-wishlist-bottom {
                .search-wishilist-box {
                    .form-row {
                        &.form-row-button {
                            button {
                                padding: 17px 67px;
                            }
                        }
                    }
                }
            }
        }
    }
    //Wishlist login page
    .create-login {
        .login-oauth {
            button {
                padding: 14px 40px;
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .legacy-sitegen .pt_wish-list {
        #primary {
            .search-wishlist {
                .form-row {
                    margin: 20px 0 0 0;
                }
                .search-wishilist-box {
                    padding: 20px;
                }
            }
            .results-found {
                .item-list {
                    .section-item-list {
                        .mobile-label {
                            font-size: $base-font;
                            font-family: $HelveticaBold;
                        }
                    }
                }
            }
        }
    }
    //Wishlist login page
    .create-login {
        .inner-block {
            .login-box {
                .login-data {
                    .login-email {
                        width: 73%;
                    }
                }
            }
        }
    }
    //whishlist email popup
    .ui-dialog {
        &.wishlist-email {
            .ui-dialog-titlebar {
                .ui-dialog-title {
                    font-size: $base-font + 8px;
                }
            }
        }
    }
    .legacy-sitegen .sendtofriend {
        .sub-heading {
            font-size: $base-font + 1px;
        }
        label {
            font-size: $base-font - 1px;
        }
    }
    //My Wishlist Items
    .legacy-sitegen .item-list {
        .wishlist-row {
            position: relative;
            .wishlist-columns {
                padding: 25px 0;
            }
            .column {
                width: 100%;
                &.col-1 {
                    max-width: 100%;
                    width: 100%;
                    .item-image {
                        width: 100px;
                    }
                    .item-details {
                        max-width: 100%;
                        width: 64%;
                        .edit-wishlist-detail-mobile {
                            margin: 0 0 10px 0;
                            text-transform: uppercase;
                            letter-spacing: 1px;
                            font-size: $base-font - 1px;
                            color: $black;
                            font-family: $HelveticaBold;
                        }
                    }
                }
                &.col-3 {
                    max-width: 100%;
                    width: 100%;
                    margin: 17px 0 0 0;
                    .option-date-added {
                        margin: 20px 0 0 0;
                    }
                    .item-dashboard {
                        width: 100%;
                    }
                    .option-add-to-cart {
                        width: 100%;
                        .inventory {
                            width: 20%;
                            font-size: 14px;
                            @include GothamMedium($Gotham);
                            color: $SW-navy-blue;
                            letter-spacing: 1px;
                        }
                        button {
                            &.add-to-cart {
                                width: 80%;
                                height: 50px;
                            }
                        }
                    }
                    .edit-section {
                        .option-update {
                            button {
                                text-indent: -9999px;
                                position: absolute;
                                right: 0;
                                top: 10px;
                                background-image: url("../../../images/close-icon.png");
                                height: 16px;
                                width: 16px;
                            }
                        }
                    }
                    .option-toggle-public {
                        width: 100%;
                        float: left;
                        margin: 20px 0;
                    }
                }
            }
        }
    }
    //My Wishlist
    .legacy-sitegen .list-share {
        .top-section {
            margin: 0 0 15px 0;
            padding: 0;
        }
        .bottom-section {
            padding: 0;
        }
    }
    //Wishlist Empty
    .legacy-sitegen .wishlist-empty {
        .qa-section {
            .qa-content {
                .question {
                    font-size: $base-font + 2px;
                }
                .answer {
                    font-size: $base-font + 2px;
                }
            }
        }
    }
}
.legacy-sitegen .pt_wish-list {
    .primary-content {
        .search-wishlist-bottom {
            .search-wishilist-box {
                .or-link.or-element {
                    font-size: $base-font - 1px;
                    @include GothamMedium($Gotham);
                    color: $SW-navy-blue;
                    letter-spacing: 0.5px;
                }
            }
        }
    }
}
.legacy-sitegen .item-list {
    .wishlist-row {
        .column.col-2 {
            .price span {
                .price-sales,
                .price-standard {
                    font-size: $base-font + 1px;
                    @include GothamBold($GothamBold);
                    color: $s4a-grey;
                    letter-spacing: 1px;
                }
                .price-sales,
                .price-sale-standard {
                    color: $SW-navy-blue;
                }
            }
        }
    }
}
.create-login .inner-block .login-box{background-color: $FB-lighter-gray;}
.create-login .orderstatus-section label{
	position: absolute;
	top:4px;
	left:20px;
	&.input-focus{
		 transform:translate(10px, 20px);
	}
}