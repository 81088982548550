.error-page {
    div {
        @include WorkSansMedium($WorkSans);
        font-size: $base-font + 180px;
    }
    p {
        @include WorkSansMedium($WorkSans);
    }
}
.pt_error {
    .trending-section {
        .recommendation-heading {
            span {
                &.trending {
                    @include WorkSansMedium($WorkSans);
                }
            }
        }
    }
}
