@media screen and (min-width: 1024px) {
    .hide-desktop {
        display: none;
    }
    .top-menu-utility {
        .menu-utility {
            .info {
                &.customer-service-info {
                    .customer-service-flyout {
                        .cs-flyout-phone {
                            span {
                                width: 190px;
                            }
                        }
                    }
                }
            }
        }
    }
    .header_fixed {
        .top-banner {
            .menu-category {
                .selected {
                    > a {
                        &:after {
                            border-left: solid 4px transparent;
                            border-right: solid 4px transparent;
                        }
                    }
                }
            }
            &:after {
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
            }
            .level-1 > li {
                &:hover,
                &.open {
                    &:after {
                        border-left: solid 4px black;
                        border-right: solid 4px black;
                    }
                }
                > a {
                    &.has-sub-menu {
                        &:after {
                            top: calc(100% - 2px);
                        }
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .hide-tablet {
        display: none;
    }
    .sub-total {
        &.hide-desktop {
            display: none;
        }
    }
}
@media screen and (max-width: 767px) {
    .hide-mobile {
        display: none;
    }
    .refinement-breadcrumb,
    .sort-breadcrumb {
        .refine-by-clearall {
            float: left;
            margin-top: 10px;
            width: 100%;
            .breadcrumb-refinement-value {
                float: right;
                margin-right: 0;
                background: transparent;
            }
        }
    }
}
@media screen and (min-width: 1025px) and (max-width: 1320px) {
    .top-banner {
        .header-secondary {
            .header-search {
                .simplesearch {
                    right: 0;
                }
                #search-suggestions {
                    .search-suggestion-wrapper {
                        right: 0;
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 1024px) {
    .header_fixed {
        .header-promo-bottom {
            display: none;
        }
        .fixed-header {
            position: fixed;
            top: 0;
            background: $white;
            z-index: 26;
            width: 100%;
            left: 0;
            transition: all 0.2s linear;
            box-shadow: 0 0 0 1px #dddddd;
        }
        #navigation {
            .menu-category {
                margin: 0;
            }
        }
        .top-menu-utility {
            height: 0;
            max-width: 1300px;
            margin: 0 auto;
            .menu-utility {
                position: relative;
            }
            .header-brand-selector {
                display: none;
            }
            .menu-utility-user {
                width: auto;
                position: absolute;
                right: 0;
                left: auto;
                z-index: 2;
                top: 8px;
            }
            .left-section {
                display: none;
            }
            .right-section {
                position: relative;
                float: none;
                .user-info {
                    display: none;
                }
                .info {
                    float: none;
                }
                #mini-cart {
                    .mini-cart-total {
                        .mini-cart-link {
                            padding: 0;
                            margin: 7px 0;
                            background-color: transparent;
                        }
                    }
                    span {
                        &.cart-bag-qty,
                        &.cart-bag {
                            float: left;
                            background: url(../../../images/bag-icon-white.svg)
                                no-repeat;
                            width: 21px;
                            height: 21px;
                            padding: 0;
                            background-size: 100%;
                        }
                        &.cart-bag {
                            background: url(../../../images/bag-icon.svg)
                                no-repeat;
                        }
                        &.my-bag {
                            display: none;
                        }
                        &.minicart-quantity {
                            right: 100%;
                            margin-right: -25px;
                            padding: 1px 7px 2px;
                            left: auto;
                            top: 0;
                            border: 2px solid $geraldine;
                        }
                    }
                    .mini-cart-content {
                        top: 38px;
                        box-shadow: 0 18px 24px 0 rgba(0, 0, 0, 0.25);
                    }
                    &:hover {
                        .mini-cart-link {
                            span {
                                &.minicart-quantity {
                                    border: 2px solid $geraldine;
                                }
                            }
                        }
                    }
                }
            }
        }
        .header-mian-banner {
            padding: 0;
            .primary-logo,
            .header-secondary {
                display: none;
            }
            #navigation {
                .menu-category {
                    border: none;
                    width: 96%;
                    li {
                        .level-2 {
                            .sub-cat-wrapper {
                                max-width: 1260px;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .header_fixed {
        .fixed-header {
            position: fixed;
            top: 0;
            background: $black;
            z-index: 26;
            width: 100%;
            height: 50px;
            border-bottom: 4px solid $blue-haze;
            .fixed-header-wrapper {
                height: 50px;
            }
            .user-info {
                padding: 1px;
                .user-account {
                    padding-left: 16px;
                }
            }
            .primary-logo {
                position: relative;
                z-index: 20;
                width: 172px;
                margin: 0 0 0 40px;
                display: inline-block;
                vertical-align: top;
                transition: none;
                height: 38px;
            }
            .search-button {
                z-index: 25;
            }
            .simplesearch {
                padding-bottom: 0;
                right: -8px;
                .header-search {
                    &.focus {
                        button .search {
                            margin: 7px 0 0 8px;
                        }
                    }
                }
                button {
                    .search {
                        background: url(../../../images/fb/search-icon-white.svg)
                            no-repeat;
                        width: 20px;
                        height: 20px;
                    }
                }
            }
            /* logged in user sees My Account dropdown in header, left of logo */
            &.registered {
                .menu-utility-user .user-info.info.registered {
                    display: inline-block;
                    padding: 2px 0;
                    .user-account.login {
                        padding-left: 16px;
                    }
                    .user-panel.registered-user {
                        padding: 15px;
                    }
                }
            }
            /* unregistered user sees Offers dropdown in header, left of log */
            &:not(.registered) {
                .top-banner .header-secondary .header-offers {
                    display: inline-block;
                    float: none;
                    margin: 0 auto;
                    vertical-align: middle;
                    a.offer-flyout {
                        padding: 12px;
                        margin: 0 auto;
                    }
                }
                .top-banner .header-main-search {
                    padding-bottom: 1px;
                    display: inline-block;
                    margin: 0 auto;
                }
                .top-banner.header-search-button
                    .header-secondary
                    .header-offers {
                    display: none;
                }
            }
        }
        .top-menu-utility {
            width: auto;
            max-width: 295px;
            position: absolute;
            top: 4px;
            right: 0;
            background: transparent;
            border-bottom: 1px solid transparent;
            .menu-utility-user {
                width: auto;
                .left-section {
                    display: none;
                }
                .right-section {
                    float: right;
                    width: 100%;
                    .info {
                        &.user-info {
                            .login {
                                padding: 6px 15px 6px;
                            }
                        }
                    }
                    #mini-cart {
                        float: none;
                        padding: 0;
                    }
                }
                .info {
                    background-color: transparent;
                    border-color: transparent;
                    float: none;
                    margin: 0;
                    padding: 7px 25px 11px;
                    text-align: center;
                    position: relative;
                    display: inline-block;
                    z-index: 2;
                    vertical-align: top;
                    &.user-info {
                        padding: 0;
                        &.DelayHover {
                            background: #fff;
                        }
                        .user-panel {
                            right: 0;
                            &.registered-user {
                                text-align: left;
                                #profilemenu {
                                    .account-links {
                                        text-align: left;
                                        float: left;
                                        a {
                                            text-align: left;
                                        }
                                    }
                                }
                            }
                        }
                        span {
                            &.sign-in {
                                padding: 0;
                            }
                        }
                    }
                    &.customer-service-info {
                        .customer-service-flyout {
                            .cs-flyout-phone {
                                span {
                                    width: 190px;
                                }
                            }
                        }
                    }
                    .mini-cart-link,
                    .user-account {
                        span {
                            font-size: $base-font + 1px;
                            text-transform: uppercase;
                            @include WorkSansMedium($WorkSans);
                            display: inline-block;
                            line-height: normal;
                            letter-spacing: 0;
                            padding-left: 10px;
                            &.cart-bag {
                                padding: 0;
                            }
                            &.cart-bag-qty {
                                float: left;
                                background: url(../../../images/bag-icon-white.svg)
                                    no-repeat;
                                width: 21px;
                                height: 21px;
                                padding: 0;
                                background-size: 100%;
                            }
                            &.my-bag {
                                padding: 3px 0 0 10px;
                            }
                            &.minicart-quantity {
                                right: 90px;
                                top: 5px;
                                left: auto;
                                padding: 2px 8px;
                                color: $white;
                                border: 2px solid $white;
                            }
                            &.log-in {
                                background: url(../../../images/bust-solid-black.svg)
                                    no-repeat;
                                width: 20px;
                                height: 20px;
                                padding: 0;
                                margin: 5px 0 0 0;
                            }
                        }
                        &.mini-cart-empty:hover span.my-bag {
                            color: $black;
                        }
                    }
                    .mini-cart-link {
                        padding: 11px 17px 14px;
                        .minicart-quantity {
                            right: 100%;
                            left: -23px;
                            top: -9px;
                        }
                    }
                }
            }
        }
        .top-banner {
            width: calc(100% - 293px);
            background: transparent;
            padding: 0;
            display: inline-block;
            &:after {
                border-bottom: none;
            }
            .header-mian-banner {
                border: 0;
                padding: 0;
                background: transparent;
            }
            .menu-toggle {
                padding: 0;
                margin: 15px 0 0 15px;
                transition: none;
            }
            .header-main-search {
                padding: 0;
                display: inline-block;
                width: auto;
            }
            &.header-search-button {
                width: 100%;
                .simplesearch {
                    padding-right: 0;
                }
                .header-secondary {
                    position: absolute;
                    right: 0;
                    z-index: 40;
                    top: 0;
                    .header-offers {
                        display: none;
                    }
                }
            }
            .header-secondary {
                display: inline-block;
                float: none;
                width: auto;
                margin: 0 auto;
                position: relative;
                .header-offers {
                    display: inline-block;
                    float: none;
                    margin: 0 auto;
                    vertical-align: middle;
                    a.offer-flyout {
                        padding: 10px;
                        background: transparent;
                        .offers {
                            color: $black;
                        }
                        .offer-flyout {
                            .fb-offers {
                                display: none;
                                &.on-hover-image {
                                    display: block;
                                }
                            }
                        }
                    }
                }
                .header-search {
                    float: none;
                    display: inline-block;
                    width: auto;
                    position: relative;
                    background: none;
                    margin: 0 auto;
                    vertical-align: middle;
                    .simplesearch {
                        padding-bottom: 0;
                    }
                    &.search-button,
                    &.focus,
                    &.active {
                        position: absolute;
                        width: 380px;
                        transition: width 0.5s;
                        -webkit-transition: width 0.5s;
                        -ms-transition: width 0.5s;
                        right: 0;
                        top: 0;
                        margin-right: 0;
                        .search-suggestion-wrapper {
                            top: 100%;
                        }
                        &.active {
                            background: transparent;
                            z-index: 40;
                        }
                        .simplesearch {
                            padding: 0;
                            margin: 5px auto 0;
                            &.fixed-active {
                                form {
                                    background: $white;
                                }
                            }
                        }
                    }
                    button {
                        padding: 9px 0 7px 5px;
                    }
                    &.focus {
                        position: relative;
                        .simplesearch {
                            form {
                                border: 1px solid $dusty-gray;
                                background-color: $white;
                                width: 96%;
                                input[type="text"] {
                                    opacity: 1;
                                    width: 85%;
                                    height: 36px;
                                }
                                button {
                                    padding: 9px 0 7px 5px;
                                    .search {
                                        background: url(../../../images/search-icon.png)
                                            no-repeat;
                                        background-size: 100%;
                                    }
                                }
                            }
                        }
                    }
                    &.active {
                        z-index: 20;
                        background: $white;
                        .simplesearch {
                            background: $white;
                            form {
                                border: 2px solid $black;
                                input[type="text"] {
                                    opacity: 1;
                                    width: 85%;
                                }
                            }
                            &.fixed-active {
                                form {
                                    border: 2px solid $black;
                                }
                            }
                        }
                    }
                    .simplesearch {
                        position: relative;
                        z-index: 2;
                        background: none;
                        padding: 0;
                        right: 0;
                        form {
                            border: 1px solid transparent;
                            transition: none;
                            background-color: $black;
                            input[type="text"] {
                                opacity: 0;
                                width: 0;
                                height: 0;
                                transition: none;
                            }
                            button {
                                transition: none;
                                &:focus {
                                    outline: none;
                                }
                            }
                        }
                        &.fixed-active {
                            form {
                                border: 1px solid $dusty-gray;
                                input[type="text"] {
                                    opacity: 1;
                                    background: $white;
                                }
                            }
                        }
                    }
                    .search-suggestion-wrapper {
                        top: 100%;
                        right: -8px;
                        z-index: 3;
                    }
                }
            }
        }
    }
    .grid-tile {
        #quickviewbutton {
            display: none !important;
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .pt_product-details {
        #main {
            .product-col-1 {
                &.product-bundle {
                    padding-right: 0px;
                    &.product-bundle-main {
                        width: 58%;
                    }
                }
            }
            .product-bundle {
                &.product-bundle-main {
                    .product-col-2 {
                        width: 100%;
                        &.product-set {
                            .product-add-to-cart {
                                width: 100%;
                                .inventory {
                                    width: 20%;
                                }
                                button {
                                    float: left;
                                    width: 80%;
                                    padding-left: 5px;
                                    padding-right: 5px;
                                    text-align: center;
                                }
                            }
                        }
                        &.product-detail {
                            .product-actions {
                                text-align: left;
                                .share-icon-container {
                                    display: inline-block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 800px) {
    /* large tablets and below */
    .product-tile #quickviewbutton {
        display: none;
    }
}
@media screen and (max-width: 768px) {
    .pt_product-details {
        #main {
            .product-bundle {
                &.product-bundle-main {
                    .product-col-2 {
                        &.product-set {
                            .product-add-to-cart {
                                width: 100%;
                                .inventory {
                                    width: 20%;
                                }
                                button {
                                    float: left;
                                    width: 80%;
                                }
                            }
                        }
                        &.product-detail {
                            .product-actions {
                                text-align: left;
                                .share-icon-container {
                                    display: inline-block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .header_fixed {
        top: 0;
    }
    img {
        max-width: 100%;
    }
    .tooltip {
        font-size: 0.9em;
    }
    .form-field-tooltip {
        float: none;
        margin: 0 0 10px 5px;
        width: auto;
    }
    #mini-cart {
        .mini-cart-name {
            a {
                color: $black;
            }
        }
    }
    .ui-dialog {
        max-width: 98%;
        .ui-dialog-content {
            height: auto !important;
            overflow: visible;
        }
    }
    .col-1,
    .col-2 {
        float: none;
        width: 100%;
    }
    .scrollable {
        height: auto;
        li {
            float: left;
            a {
                border: 1px solid $blue-berry;
                border-radius: 6px;
                display: block;
                padding: 0.2em 0.8em;
            }
        }
    }
    #header {
        height: auto;
        padding: 0;
        .primary-logo {
            padding: 8px 0 0 0;
            margin: 0 auto;
            z-index: 1;
            width: 105px;
            img {
                max-width: 100%;
                vertical-align: bottom;
            }
            svg {
                vertical-align: bottom;
            }
        }
    }
    .mini-cart-products {
        max-height: 100%;
        padding: 10px;
        overflow: auto;
        .mini-cart-product {
            border-bottom: 1px solid $silver;
            padding: 0 0 20px 0;
            .mini-cart-name {
                font-size: $base-font + 2px;
                width: 50%;
                font-style: normal;
                padding: 0 0 20px 0;
                a {
                    @include WorkSansMedium($WorkSans);
                    color: $black;
                }
            }
            .mini-cart-attributes,
            .mini-cart-pricing {
                font-size: $base-font - 1px;
                width: 50%;
                font-style: normal;
                span {
                    color: $black;
                    font-family: $HelveticaBold;
                    padding: 0 0 10px 0;
                    display: inline-block;
                }
            }
            &.collapsed {
                .mini-cart-image {
                    display: block;
                }
            }
        }
    }
    .mini-cart-totals {
        .button {
            height: auto;
            line-height: normal;
            overflow: inherit;
            padding: 15px 50px;
            margin-bottom: 25px;
            background: $wine-berry;
            color: $white;
            border: none;
        }
        .mini-cart-slot {
            background: none;
            border: none;
            font-size: 0.9em;
            font-style: italic;
            margin: 1em 1em;
        }
        .mini-cart-subtotals {
            padding: 15px 0 25px 0;
            font-size: $base-font - 1px;
            span {
                color: $black;
                font-family: $HelveticaBold;
                &.label {
                    margin: 0 0.5rem;
                }
                &.value {
                    margin-right: 0.5rem;
                }
            }
        }
    }
    .mini-cart-content {
        max-width: 280px;
        background: white;
        z-index: 1;
        margin-right: 10px;
        top: 4.3em;
        border: none;
        position: absolute;
        right: 0;
        display: none;
    }
    #quickviewbutton {
        display: none !important;
    }
    .search-result-options {
        margin: 0.5em 0;
        .sort-by {
            margin: 0;
            select {
                width: 120px;
            }
        }
        .items-per-page {
            display: none;
        }
        .pagination {
            margin: 0;
            .results-hits {
                display: none;
            }
        }
    }
    .product-tile {
        height: auto !important;
    }
    .wide-tiles .product-tile {
        .product-image {
            width: 41%;
        }
        .product-name,
        .product-pricing,
        .product-promo,
        .pr_stars,
        .product-swatches {
            float: right;
        }
        .product-name h2 {
            margin-top: 0px;
        }
    }
    .product-listing-1x4 ul.search-result-items .grid-tile {
        margin: 2%;
        width: 92%;
    }
    .product-compare,
    .compareitems {
        display: none !important;
    }
    .pt_product-details {
        .breadcrumb .last {
            display: none;
        }
    }
    #product-nav-container {
        bottom: 0;
        width: 100%;
        div {
            width: 60px;
            &.product-next {
                float: right;
            }
        }
        .divided span {
            border: 0;
        }
    }
    .sizinginformation {
        padding: 0;
        th {
            font-weight: normal;
            padding: 0 0 0 2px;
        }
        td {
            background: $white;
            padding: 5px;
            text-align: center;
        }
        .sizechart {
            padding: 0 !important;
        }
    }
    .ui-dialog .ui-dialog-content {
        padding: 0.25em;
    }
    .pt_cart #secondary {
        display: none;
    }
    .order-summary-footer h2 {
        width: 50%;
    }
    .place-order-totals .order-totals-table {
        width: 100%;
    }
    .payment-method-options {
        padding: 0;
        .form-row {
            width: 100%;
            label {
                float: left;
                margin: 0;
                padding: 0 0 0 1em;
                width: 150px;
            }
            .input-radio {
                margin: 0;
            }
        }
    }
    .pt_order-confirmation {
        .item-list {
            th {
                display: none;
            }
            td {
                float: left;
                padding: 1em 0;
                width: 98%;
            }
            .order-totals-table td {
                float: left;
                width: 48%;
            }
        }
        .order-shipment-table {
            th {
                display: none;
            }
            td {
                font-size: 0.9em;
                box-sizing: border-box;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                float: left;
            }
            .line-item-details {
                width: 70%;
            }
            .line-item-quantity {
                width: 10%;
            }
            .line-item-price {
                width: 20%;
            }
            .order-shipment-details {
                border: none;
                width: 100%;
            }
            .scrollable {
                height: auto;
                li {
                    float: left;
                    a {
                        border: 1px solid $blue-berry;
                        border-radius: 6px;
                        display: block;
                        padding: 0.2em 0.8em;
                    }
                }
            }
            #header {
                height: auto;
                padding: 1% 1% 30px;
                .primary-logo {
                    display: inline-block;
                    position: relative;
                    top: auto;
                    width: 65%;
                    img {
                        max-width: 100%;
                    }
                }
            }
            .mini-cart-products {
                max-height: 100%;
            }
            .mini-cart-content {
                max-width: 220px;
            }
            #quickviewbutton {
                display: none !important;
            }
            ul.search-result-items .grid-tile {
                margin: 2%;
                width: 92%;
            }
            .product-tile {
                height: auto !important;
            }
            .wide-tiles .product-tile {
                .product-image {
                    width: 41%;
                }
                .product-name,
                .product-pricing,
                .product-promo,
                .pr_stars,
                .product-swatches {
                    float: right;
                }
                .product-name h2 {
                    margin-top: 0px;
                }
            }
            .product-tile .product-swatches .swatch img {
                height: 2em;
                width: 2em;
            }
            .product-listing-1x4 ul.search-result-items .grid-tile {
                margin: 2%;
                width: 92%;
            }
            .product-compare,
            .compareitems {
                display: none !important;
            }
            .pt_product-details {
                .breadcrumb .last {
                    display: none;
                }
            }
            #product-nav-container {
                bottom: 0;
                width: 100%;
            }
        }
        .create-new-registry {
            overflow: hidden;
            p {
                display: none;
            }
        }
        .order-summary-footer h2 {
            width: 50%;
        }
        .place-order-totals .order-totals-table {
            width: 100%;
        }
        .payment-method-options {
            padding: 0;
            .form-row {
                width: 100%;
                label {
                    float: left;
                    margin: 0;
                    padding: 0 0 0 1em;
                    width: 150px;
                }
                .input-radio {
                    margin: 0;
                }
            }
        }
        .pt_order-confirmation {
            .item-list {
                th {
                    display: none;
                }
                td {
                    float: left;
                    padding: 1em 0;
                    width: 98%;
                }
                .order-totals-table td {
                    float: left;
                    width: 48%;
                }
            }
            .order-shipment-table {
                th {
                    display: none;
                }
                td {
                    font-size: 0.9em;
                    box-sizing: border-box;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    float: left;
                }
                .line-item-details {
                    width: 70%;
                }
                .line-item-quantity {
                    width: 10%;
                }
                .line-item-price {
                    width: 20%;
                }
                .order-shipment-details {
                    border: none;
                    width: 100%;
                }
            }
        }
        .order-history-header button {
            font-size: 1em;
            padding: 0.25em;
        }
        .page-content-tab-navigaton button {
            width: 100%;
        }
        .pt_gift-registry {
            .add-gift-cert {
                p {
                    width: 100%;
                }
            }
            .create-new-registry {
                overflow: hidden;
                p {
                    display: none;
                }
            }
            .item-list {
                width: 98%;
                th {
                    display: none;
                }
            }
        }
        .order-shipment-table .section-header {
            font-size: 0.9em !important;
            padding: 0.3em;
        }
        table.item-list .section-header,
        .order-shipment-table .section-header {
            background-image: none;
        }
        table.item-list {
            .item-image,
            .item-details,
            .item-availability,
            .item-dashboard {
                display: inline-block;
            }
            .item-image {
                width: 30%;
            }
            .item-details {
                width: 50%;
            }
            .item-availability,
            .item-dashboard {
                width: 97%;
            }
            .delete-registry {
                text-align: center;
            }
        }
        .list-table-header {
            padding: 1em !important;
            .button {
                display: block;
                margin: 20px 0;
                width: 196px;
            }
            p {
                display: none;
            }
            button {
                display: block;
                width: 260px;
            }
        }
        .home-bottom-left,
        .home-bottom-center {
            width: 100% !important;
        }
        .home-bottom-right {
            clear: both;
            float: none;
            width: 100% !important;
            .product-tile {
                min-height: 0;
            }
        }
        .product-tile .product-image {
            height: auto;
        }
        #email-alert-signup {
            margin: 0;
            width: 100%;
            label {
                text-align: center;
                width: 100%;
            }
            input [type="text"] {
                float: none;
                margin: 0;
                width: 50%;
            }
            input[type="image"] {
                float: none;
                vertical-align: bottom;
            }
        }
        .facebook-registration {
            left: -3px;
            position: relative;
            width: 100.7% !important;
            iframe {
                position: relative;
                width: 100.7% !important;
            }
        }
    }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
    .product-tile .pr_stars {
        left: 40px;
    }
    .wide-tiles .product-tile {
        .product-name h2 {
            margin-top: 10px;
        }
        .product-image {
            width: 38%;
        }
    }
    .facebook-registration {
        left: 0px;
        position: relative;
        width: 100% !important;
        .fb_iframe_widget {
            position: relative;
            width: 100% !important;
            span {
                position: relative;
                width: 100% !important;
                iframe {
                    position: relative;
                    width: 100% !important;
                }
            }
        }
    }
    #preferred-store-panel input[type="text"] {
        width: 49.5966%;
    }
}
@media screen and (min-width: 768px) and (max-width: 959px) {
    .pt_cart {
        .primary-content {
            width: 100%;
        }
    }
    #secondary {
        width: 171px;
    }
    .primary-focus {
        .primary-content {
            width: 540px;
        }
        #secondary {
            width: 190px;
        }
    }
    .html-slot-container img,
    .bottom-banner-cell img {
        max-width: 100%;
    }
    .ui-dialog {
        max-width: 98%;
        .ui-dialog-content {
            height: auto !important;
            overflow: visible;
        }
    }
    .scrollable {
        height: auto;
    }
    .mini-cart-total-label {
        display: none;
    }
    .mini-cart-products {
        max-height: 100%;
    }
    ul.search-result-items {
        .new-row {
            clear: none;
        }
    }
    table .section-header {
        font-size: 1em !important;
    }
    #compare-table td .product-tile {
        width: 79px;
    }
    .pt_gift-registry .add-gift-cert p {
        width: auto;
    }
    .home-bottom-right .product-tile {
        min-height: 0;
    }
    #email-alert-signup {
        margin: 0;
        width: 100%;
        label {
            float: none;
            display: block;
            text-align: center;
            width: 100%;
        }
        input[type="text"] {
            width: 73%;
        }
    }
    .facebook-registration {
        left: 0px;
        position: relative;
        width: 100% !important;
        .fb_iframe_widget {
            position: relative;
            width: 100% !important;
            span {
                position: relative;
                width: 100% !important;
                iframe {
                    position: relative;
                    width: 100% !important;
                }
            }
        }
    }
    .mini-cart-item-word {
        span {
            display: none;
        }
    }
}
@media screen and (max-width: 480px) {
    #preferred-store-panel input[type="text"] {
        width: 49.5966%;
    }
}
