// Store inventory
.availability-web {
    padding: 2px 0 10px;
    @include GothamMedium($Gotham);
    font-weight: 500;
    font-size: $base-font + 1px;
    letter-spacing: 0.5px;
    color: $SW-gray;
    b {
        @include GothamMedium($Gotham);
        font-weight: 500;
        font-size: $base-font + 1px;
        letter-spacing: 0.5px;
        color: $SW-gray;
    }
}
