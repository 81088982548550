.pt_account {
    .inner-block {
        &.check-order {
            h2 {
                font-size: $base-font + 8px;
                @include GothamMedium($Gotham);
                letter-spacing: 0.5px;
                color: $SW-navy-blue;
                padding: 0 0 15px 0;
                border: {
                    width: 2px;
                    color: $SW-navy-blue;
                }
            }
        }
    }
    .col-2 {
        h2 {
            font-size: $base-font + 1px;
            @include GothamBook($GothamBook);
            letter-spacing: 0;
        }
    }
    .create-login {
        .inner-block {
            .login-box {
                background-color: $SW-light-gray;
                .login-box-content {
                    h1 {
                        @include GothamBold($GothamBold);
                        font-size: $base-font + 4px;
                    }
                }
                .error-form {
                    background-color: transparent;
                    border: none;
                }
                .make-label-absolute {
                    .form-row {
                        label {
                            span {
                                font-size: $base-font + 1px;
                                @include GothamBook($GothamBook);
                                letter-spacing: 0;
                                color: $SW-gray;
                                &.error {
                                    color: $guardsman-Red;
                                }
                            }
                            &.input-focus {
                                span {
                                    font-size: $base-font + 1px;
                                    @include GothamBook($GothamBook);
                                    letter-spacing: 0;
                                    color: $SW-gray;
                                    &.error,
                                    &.server-error  {
                                        color: $guardsman-Red;
                                    }
                                }
                            }
                            &.input-focus {
                                font-size: $base-font + 1px;
                                @include GothamBook($GothamBook);
                                letter-spacing: 0;
                                color: $SW-gray;
                            }
                        }
                    }
                }
                .field-wrapper {
                    input[type="date"] textarea,
                    input[type="number"],
                    input[type="password"],
                    input[type="text"],
                    input[type="tel"] {
                        font-size: $base-font + 1px;
                        @include GothamBook($GothamBook);
                        letter-spacing: 0;
                        color: $SW-gray !important;
                    }
                }
            }
        }
    }
    .check-order {
        p {
            font-size: $base-font + 1px;
            @include GothamBook($GothamBook);
            letter-spacing: 0.5px;
            color: $SW-gray;
            line-height: 21px;
        }
        .ordererror {
            border: none;
            background-color: $wild-sand;
            padding: 0 0 10px 0;
            color: $RedRibbon;
        }
    }
    .registration-reward {
        margin-top: 40px;
        h2 {
            font-size: $base-font + 10px;
            @include GothamMedium($Gotham);
            letter-spacing: 0.5px;
            color: $SW-navy-blue;
            text-transform: capitalize;
        }
        h3 {
            @include GothamMedium($Gotham);
            color: $SW-navy-blue;
            font-size: $base-font + 2px;
            letter-spacing: 0.5px;
            text-transform: capitalize;
        }
        p {
            font-size: $base-font + 1px;
            @include GothamBook($GothamBook);
            letter-spacing: 0;
            color: $SW-gray;
            margin: 15px 0 20px 0;
            line-height: 21px;
        }
    }
    .create-login {
        .login-create-account {
            min-height: auto;
            .legal {
                font-size: $base-font + 1px;
                @include GothamBook($GothamBook);
                letter-spacing: 0.5px;
                color: $SW-gray;
                a {
                    color: $SW-navy-blue;
                }
            }
            @media screen and (min-width: 768px) {
                .form-row {
                    &.addtoemaillist {
                        label {
                            padding-left: 10px;
                        }
                    }
                }
            }
        }
        .login-create {
            .registration-reward {
                p {
                    font-size: $base-font + 1px;
                    @include GothamBook($GothamBook);
                }
            }
        }
    }
}
.create-login {
    .form-row:not(.error-handle) .form-caption {
        margin-left: 0;
        @include GothamBold($GothamBold);
        letter-spacing: 0.5px !important;
        color: $SW-navy-blue !important;
        font-size: 13px !important;
    }
}
@media screen and (max-width: 1023px) {
    .pt_account {
        .create-login {
            .login-create,
            .login-create-account {
                height: auto;
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .pt_account {
        .create-login {
            .login-create,
            .login-create-account {
                height: auto;
                .view-more {
                    @include GothamMedium($Gotham);
                    letter-spacing: 0.5px;
                    color: $SW-navy-blue;
                }
            }
            button {
                padding: 14px 25px;
            }
            h1 {
                .dialog-required {
                    display: none;
                }
            }
        }
        .col-2 {
            .label-inline {
                .field-wrapper {
                    margin: 0 10px 0 0;
                }
            }
        }
        .benfits-content {
            .registration-reward {
                h2 {
                    font-size: $base-font + 10px;
                }
            }
        }
        .registration-reward {
            p {
                margin: 0 0 30px 0;
            }
            h3 {
                margin: 0;
            }
        }
    }
    .create-login {
        .inner-block {
            .login-box {
                &.login-create-account {
                    .create-account {
                        padding: 14px 48px;
                    }
                }
            }
        }
    }
}
