.cart-top-banner {
    h1 {
        @include GothamBold($Gotham);
        margin: 13px 0 11px 0;
        @include text-properties(2px, $SW-navy-blue, $base-font + 18px);
    }
    .shopping-bag-id {
        padding: 15px 10px 0;
        @media screen and (max-width: 767px) {
            padding: 0px;
        }
        .pid {
            @include WorkSansSemiBold($WorkSans);
            font-size: $base-font + 2px;
            @media screen and (max-width: 767px) {
                font-size: $base-font + 1px;
            }
        }
    }
    .pid {
        a {
            @include Gotham($Gotham);
            @include text-properties(1px, $SW-navy-blue, $base-font);
        }
    }
}
.last-visited-section {
    margin-top: 45px;
    .search-result-items .grid-tile {
        .product-tile .product-image {
            &.product-brylane {
                img.p-image {
                    object-fit: cover;
                    min-height: 294px;
                    @media screen and (max-width: 767px) {
                        min-height: 317px;
                    }
                    @media screen and (min-width: 768px) and (max-width: 1023px) {
                        min-height: 296px;
                    }
                }
            }
        }
    }
    .last-visited-heading {
        @include Gotham($Gotham);
        @include text-properties(1px, $SW-navy-blue, $base-font + 7px);
        @media screen and (max-width: 767px) {
            font-size: $base-font + 7px;
        }
        h2 {
            color: $SW-navy-blue;
        }
    }
    .product-tile {
        .product-name {
            font-size: $base-font + 1px;
        }
        .product-pricing {
            font-size: $base-font;
            @include WorkSansMedium($WorkSans);
        }
    }
}
.international-cart-message {
    @include WorkSansRegular($WorkSans);
}
.pt_cart {
    .error-form {
        @include WorkSansMedium($WorkSans);
    }
    //Q&A
    .qa-section {
        .question-title {
            padding: 26px 25px 25px 0;
        }
        .qa-desc {
            padding-bottom: 6.7%;
        }
        .qa-content {
            padding: 23px 17px 20px 43px;
            letter-spacing: 1px;
            background-color: $FB-lightest-gray;
            &:nth-child(2n),
            &:nth-child(3n),
            &:nth-child(4n),
            &:nth-child(n) {
                padding: 23px 17px 20px 43px;
                letter-spacing: 1px;
            }
            .question {
                &::after {
                    background: url(../../../images/carat-down.svg) no-repeat;
                    background-position: 99.7% 4px;
                }
                &.active {
                    &::after {
                        background: url(../../../images/carat-up.svg) no-repeat;
                        background-position: 99.7% 4px;
                    }
                }
            }
            .answer {
                padding: 12px 0 2px 0;
            }
        }
    }
    .cart-right-content {
        //cart Recommendations
        .cart-recommendations {
            .you-may-like {
                background: transparent;
                border: 1px solid $light-gray;
                @media screen and (max-width: 1023px) {
                    background: transparent;
                    border: 0;
                }
            }
            h2.trending {
                @include Gotham($Gotham);
                @include text-properties(1px, $SW-navy-blue, $base-font + 7px);
                text-align: center;
                padding: 17px 0 13px;
                margin: 0 0;
                text-transform: uppercase;
                @media screen and (max-width: 1023px) {
                    font-size: $base-font + 7px;
                    padding-bottom: 20px;
                    margin-bottom: 20px;
                    text-transform: uppercase;
                    text-align: center;
                    padding-top: 0;
                }
                @media screen and (max-width: 767px) {
                    font-size: $base-font + 7px;
                }
            }
            .product-tile {
                color: $black;
                border: 0;
                .product-name {
                    font-size: $base-font;
                    margin: 3px 0 6px;
                    width: 100%;
                    line-height: normal;
                }
                .product-price {
                    font-size: $base-font;
                    margin: 5px 0;
                    color: $night-rider;
                    @include WorkSansMedium($WorkSans);
                    .price-standard {
                        padding-right: 6px;
                    }
                }
            }
            @media screen and (max-width: 1024px) {
                .recommendations-heading {
                    font-size: $base-font + 7px;
                }
                .product-tile {
                    .product-name {
                        font-size: $base-font + 3px !important;
                    }
                }
            }
        }
        //ends recommendation
    }
}
.cart-columns {
    .section-header {
        @include Gotham($Gotham);
        @include text-properties(0.5px, $SW-navy-blue, $base-font + 5px);
        text-transform: uppercase;
    }
    .column {
        &.col-4 {
            max-width: 120px;
            @media screen and (max-width: 767px) {
                max-width: 100%;
            }
        }
    }
}
.cart-row {
    border-top: 1px solid $silver;
    &.cart-product-brylane-lineitem {
        .cart-columns {
            .col-1 {
                .item-image img {
                    object-fit: cover;
                    min-height: 144px;
                    @media screen and (max-width: 360px) {
                        min-height: 196px;
                    }
                    @media screen and (min-width: 361px) and (max-width: 767px) {
                        min-height: 204px;
                    }
                }
            }
        }
    }
    .col-1 {
        .item-details {
            font-size: $base-font - 1px;
            @include WorkSansMedium($WorkSans);
            .name {
                margin: 11px 0 9px;
                a {
                    @include Gotham($Gotham);
                    @include text-properties(1px, $SW-navy-blue, $base-font);
                    text-transform: uppercase;
                }
            }
            .product-brand {
                @include Gotham($Gotham);
                text-transform: uppercase;
                @include text-properties(
                    0.2px,
                    $brand-name-color,
                    $base-font - 2px
                );
                margin-bottom: 6px;
            }
            .sku {
                margin-bottom: 8px;
                @include Gotham($Gotham);
                @include text-properties(0.5px, $SW-gray, $base-font - 2px);
                .value,
                span,
                div {
                    color: $SW-gray;
                }
            }
            .attribute {
                margin-bottom: 6px;
                .label {
                    margin-right: 3px;
                    text-transform: capitalize;
                    @include Gotham($Gotham);
                    @include text-properties(
                        1px,
                        $SW-navy-blue,
                        $base-font - 1px
                    );
                }
                .value {
                    @include GothamBold($Gotham);
                    @include text-properties(1px, $SW-navy-blue, $base-font);
                    text-transform: capitalize;
                }
            }
            .price-sales {
                @include GothamBold($Gotham);
                @include text-properties(1px, $SW-navy-blue, $base-font);
            }
            .price-promotion {
                .price-sales {
                    color: $SW-navy-blue;
                }
                .price-standard {
                    @include Gotham($Gotham);
                    margin: 0 5px 0 0;
                    text-decoration: line-through;
                    @include text-properties(1px, $SW-gray, $base-font);
                }
            }
            .product-option {
                margin-bottom: 9px;
                letter-spacing: 1px;
                text-transform: uppercase;
                @include WorkSansSemiBold($WorkSans);
            }
            .optioninfo {
                @include WorkSansSemiBold($WorkSans);
                .hemmable {
                    margin-top: 15px;
                }
                span {
                    letter-spacing: 1px;
                    &.monogram,
                    &.hemming {
                        margin: 10px 0;
                        cursor: pointer;
                        padding: 10px;
                        border: 1px solid $black;
                        display: inline-block;
                        border-radius: 0;
                        @media screen and (max-width: 767px) {
                            margin: 10px 0 0;
                        }
                        &:before {
                            content: "";
                            background: url("../../../images/ww/plus-symbol.png")
                                no-repeat;
                            width: 11px;
                            height: 11px;
                            display: inline-block;
                            padding-right: 10px;
                        }
                    }
                }
                .optionheading {
                    text-transform: uppercase;
                }
                .optionvalue {
                    @include WorkSansSemiBold($WorkSans);
                    line-height: 20px;
                }
            }
        }
    }
    .col-2 {
        .custom-cart-update {
            border-radius: 0;
            margin: 63px 0 0 15px;
            @include Gotham($Gotham);
            @include text-properties(1px, $SW-navy-blue, $base-font + 2px);
            border: 1px solid $SW-navy-blue;
            @media screen and (max-width: 1024px) {
                margin: 63px auto auto;
            }
            input:disabled {
                @include Gotham($Gotham);
                @include text-properties(1px, $SW-navy-blue, $base-font + 2px);
            }
        }
        .not-available {
            margin-left: 10px;
            @include WorkSansMedium($WorkSans);
        }
    }
    .col-3 {
        .item-total {
            margin-top: 72px;
            text-transform: uppercase;
            @include Gotham($Gotham);
            @include text-properties(0.5px, $SW-navy-blue, $base-font + 3px);
            .price-total-strikeoff {
                color: $SW-navy-blue;
            }
        }
        .price-unadjusted {
            text-decoration: line-through;
            color: $SW-gray;
        }
        .price-adjusted-total {
            color: $SW-navy-blue;
        }
    }
    .col-4 {
        .item-quantity-details {
            text-transform: uppercase;
            margin-top: 55px;
            font-size: $base-font;
            font-family: $HurmeBlack;
            letter-spacing: 0.7px;
            .btm-space {
                margin-bottom: 7px;
                .in-wishlist {
                    @include GothamBold($Gotham);
                    @include text-properties(
                        -0.4px,
                        $SW-navy-blue,
                        $base-font - 1px
                    );
                }
            }
            .button-text {
                width: 100%;
                text-align: right;
                font-size: $base-font - 1px;
                padding: 0 0;
                color: $black;
                text-transform: uppercase;
                @include WorkSansBold($WorkSans);
                letter-spacing: 1px;
            }
            .item-user-actions {
                a,
                .remove-btn,
                .button-text span {
                    @include GothamBold($Gotham);
                    @include text-properties(
                        0.5px,
                        $SW-navy-blue,
                        $base-font - 1px
                    );
                    line-height: 27px;
                }
            }
        }
    }
    &.bonus-product {
        .cart-columns {
            padding-bottom: 0;
        }
        .col-4 {
            .item-quantity-details {
                margin-top: 68px;
            }
        }
    }
    &.gift-Card {
        .col-1 {
            .item-details {
                width: 69%;
                max-width: 100%;
                .personalized-message {
                    font-size: $base-font - 1px;
                    font-family: $HelveticaBold;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    margin: 0 0 15px 0;
                }
                .attribute {
                    .value {
                        text-transform: capitalize;
                    }
                }
                .giftcard {
                    margin: 0 0 15px 0;
                    .label {
                        font-size: $base-font;
                        font-family: $HelveticaBold;
                        margin: 0 3px 0 0px;
                        letter-spacing: 1px;
                    }
                    .value {
                        font-size: $base-font;
                        font-family: $Helvetica;
                    }
                }
            }
            .giftmessage {
                font-size: $base-font;
                margin: 0 0 11px 0;
                width: 100%;
                display: inline-block;
                line-height: 15px;
                word-wrap: break-word;
            }
        }
    }
    &.specialproductsetchild {
        border: none;
        .col-1 {
            .item-image {
                width: 80px;
                padding: 0 0 0 20px;
            }
        }
        .promo-adjustment {
            width: 84%;
            display: inline-block;
            float: right;
            margin: 10px 0 0 0;
        }
    }
    .not-available {
        color: $radical-red;
        text-transform: none;
    }
    &.bonus {
        .price-promotion {
            display: inline;
        }
    }
}
.cart-row-contents {
    .item-price {
        order: 5;
        text-align: center;
        flex-basis: 33.3333%;
        @media screen and (min-width: 768px) {
            text-align: left;
        }
    }
}
.bonus-product-calloutMsg {
    font-size: $base-font;
    color: $light-red;
    @include WorkSansSemiBold($WorkSans);
    background: $white-smoke;
    &:before {
        border-color: transparent transparent transparent $white-smoke;
    }
}
.qty-error-msg {
    font-size: $base-font - 1px;
    @include WorkSansSemiBold($WorkSans);
}
.cart-promo {
    margin: 11px 0 20px;
    padding: 15px 0;
    font-size: $base-font;
    color: $light-red;
    @include WorkSansSemiBold($WorkSans);
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: 1px solid $sliver-light;
    width: 100%;
    display: inline-block;
    @media screen and (max-width: 1023px) {
        border-right: 0;
        border-left: 0;
    }
    &.cart-promo-bonus {
        border: none;
        background: $wild-sand-dark;
        padding: 15px 0 15px 37px;
        text-align: left;
        margin: 10px 0 15px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        @media screen and (max-width: 767px) {
            padding: 15px;
        }
    }
    .bonus-item-actions {
        font-size: 1em !important;
        text-align: right;
        vertical-align: middle;
    }
}
.cart-footer {
    padding: 38px 45px 31px;
    margin-top: 20px;
    border: 1px solid $FB-light-gray;
    @media screen and (max-width: 1023px) {
        padding: 37px 15px 31px 25px;
    }
    .payment-description {
        font-size: $base-font + 4px;
        .heading {
            @include Gotham($Gotham);
            @include text-properties(1px, $SW-navy-blue, $base-font + 1px);
        }
        .payment-cards {
            border-bottom: 1px solid $silver;
        }
        .brand-cards {
            margin: 15px 0 9px;
            @include Gotham($Gotham);
            @include text-properties(1px, $SW-navy-blue, $base-font);
            line-height: 29px;
        }
        .see-details {
            @include GothamBold($Gotham);
            @include text-properties(1px, $SW-navy-blue, $base-font + 1px);
        }
    }
    .cart-order-totals {
        max-width: 295px;
        padding-right: 4px;
        .order-detail {
            padding: 0 0 12px 0;
            .label,
            .value {
                @include Gotham($Gotham);
                @include text-properties(
                    0.5px,
                    $SW-navy-blue,
                    $base-font + 3px
                );
            }
            &.discount {
                color: $SW-green;
                .value,
                .label {
                    color: $SW-green;
                }
            }
            &.order-total {
                padding: 16px 0 10px 0;
                border-bottom: 1px solid $silver;
                .label,
                .value {
                    @include GothamBold($Gotham);
                    @include text-properties(
                        0.5px,
                        $SW-navy-blue,
                        $base-font + 6px
                    );
                }
                &.order-total-remaining {
                    padding: 0 0 12px 0;
                    .label {
                        font-size: $base-font + 2px;
                        @media screen and (max-width: 767px) {
                            font-size: $base-font + 4px;
                        }
                    }
                    .value {
                        font-size: $base-font + 2px;
                        @include WorkSansSemiBold($WorkSans);
                    }
                }
            }
            &.order-saving {
                .label,
                .value {
                    @include Gotham($Gotham);
                    @include text-properties(
                        0.5px,
                        $SW-green,
                        $base-font + 3px
                    );
                    @media screen and (max-width: 767px) {
                        font-size: $base-font + 3px;
                    }
                }
            }
            &.remaining-total {
                padding: 16px 0 10px 0;
                span {
                    float: none;
                    display: inline-block;
                    vertical-align: middle;
                }
                .label {
                    font-size: $base-font + 8px;
                    @include WorkSansSemiBold($WorkSans);
                    width: 73%;
                }
                .value {
                    font-size: $base-font + 6px;
                    @include WorkSansBold($WorkSans);
                    width: 25%;
                }
            }
            &.order-shipping {
                .label {
                    .shippingoverlay-link {
                        background: url(../../../images/tips.svg) no-repeat;
                        width: 16px;
                        height: 16px;
                        background-size: cover;
                        vertical-align: sub;
                    }
                }
            }
        }
    }
    .cart-actions {
        .cart-action-checkout {
            margin: 31px 5px 0 0;
            .button-fancy-large {
                padding: 17px 30px;
                @include GothamBold($Gotham);
                margin: 13px 0 11px 0;
                background: $SW-navy-blue;
                border-color: $SW-navy-blue;
                @include text-properties(2px, $white, $base-font + 2px);
            }
            .paypal-button {
                margin: 24px 10% 0 0;
                @media screen and (width: 1024px) {
                    margin-right: 2%;
                }
                .or-link {
                    @include Gotham($Gotham);
                    text-transform: uppercase;
                    line-height: 52px;
                    @include text-properties(
                        1px,
                        $SW-navy-blue,
                        $base-font + 1px
                    );
                }
                .paypal-image {
                    padding: 0;
                    border: none;
                    float: right;
                    background: transparent;
                }
            }
        }
    }
    .tax-disclaimer {
        @include GothamBook($GothamBook);
        @include text-properties(0px, $SW-gray, $base-font - 1px);
    }
}
.cart-coupon-code {
    float: left;
    width: 100%;
    padding: 25px 30px 25px 37px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    background: $SW-light-gray;
    @media screen and (max-width: 1023px) {
        padding: 25px 20px 25px 20px;
    }
    @media screen and (max-width: 767px) {
        padding: 15px;
    }
    .cart-message {
        width: 65%;
        float: left;
        @include Gotham($Gotham);
        @include text-properties(1px, $SW-navy-blue, $base-font + 4px);
        max-width: 465px;
        text-transform: uppercase;
        @media screen and (max-width: 767px) {
            width: 100%;
            font-size: $base-font + 4px;
        }
    }
    span {
        .coupon-tooltip,
        .coupon-tooltip-link {
            background: url(../../../images/tips.svg) no-repeat;
            width: 16px;
            height: 16px;
            background-size: cover;
            padding: 0;
            margin: 0 0 0 10px;
            display: inline-block;
        }
    }
    .error-messages,
    .input-fields {
        width: 35%;
        float: right;
        max-width: 290px;
        margin-right: 20px;
        @media screen and (max-width: 767px) {
            width: 100%;
            margin-top: 20px;
            max-width: 100%;
            line-height: normal;
        }
        @media screen and (max-width: 1023px) {
            margin-right: 0;
        }
    }
    .error-messages {
        @media screen and (max-width: 767px) {
            margin: 0;
        }
    }
    .rowcoupons {
        width: 60%;
        float: left;
        padding: 10px 0 0 0;
        font-size: $base-font - 1px;
        border-bottom: 1px solid $alto;
        &.rowcoupons-last {
            border-bottom: 0;
        }
        @media screen and (max-width: 767px) {
            padding: 20px 0 0 0;
            width: 100%;
        }
        .item-details {
            position: relative;
        }
        .cartcoupon {
            text-transform: uppercase;
            span {
                @include WorkSansBold($WorkSans);
                padding: 0 2px;
                letter-spacing: 0.6px;
            }
            button {
                padding: 0 0 0 6px;
                border: none;
                margin: 0;
                @include WorkSansBold($WorkSans);
                font-size: $base-font - 1px;
                letter-spacing: 0.6px;
                background: transparent;
                color: $black;
                border-radius: 0;
                -webkit-border-radius: 0;
                -moz-border-radius: 0;
            }
        }
        .discount {
            @include WorkSansMedium($WorkSans);
            padding: 8px 2px;
            letter-spacing: 0.5px;
            width: 100%;
            .see-details {
                @include WorkSansBold($WorkSans);
                display: inline-block;
                cursor: pointer;
                text-transform: uppercase;
                font-size: $base-font - 1px;
            }
        }
    }
    .cart-left-section {
        width: 100%;
        float: left;
    }
    .cart-right-section {
        width: 100%;
        float: right;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
    }
    input {
        width: 60%;
        height: 48px;
        float: left;
        border-color: $SW-navy-blue;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        @include Gotham($Gotham);
        @include text-properties(1px, $SW-navy-blue, $base-font);
    }
    .error {
        padding-top: 0.4em;
        width: auto;
        font-size: $base-font + 2;
        @include WorkSansRegular($WorkSans);
    }
    .rw-error {
        padding: 10px 0 0 0;
        @media screen and (max-width: 767px) {
            float: left;
        }
    }
    button {
        &#add-coupon {
            width: 40%;
            float: left;
            padding: 10px 29px;
            margin: 0;
            background: $SW-navy-blue;
            border: none;
            height: 48px;
            border-top-left-radius: 0;
            -webkit-border-top-left-radius: 0;
            -moz-border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            -webkit-border-bottom-left-radius: 0;
            -moz-border-bottom-left-radius: 0;
            @include GothamBold($Gotham);
            @include text-properties(2px, $white, $base-font + 2px);
            @media screen and (max-width: 1024px) {
                padding: 11px 24px;
            }
        }
    }
}
.order-value {
    white-space: nowrap;
}
.cart-actions {
    @extend %clearfix;
    .dw-apple-pay-button {
        font-size: 1.35em;
        padding: 0.5em 2em;
    }
}
.cart-empty {
    @extend %clearfix;
    .cart-content {
        position: relative;
        background: $SW-light-gray;
        padding: 21px 0 21px;
        .button,
        button {
            @include GothamBold($Gotham);
            background: $SW-navy-blue;
            border-color: $SW-navy-blue;
            @include text-properties(2px, $white, $base-font + 2px);
        }
    }
    .cat-banner {
        text-align: center;
        h1 {
            @include GothamBold($Gotham);
            @include text-properties(2px, $SW-navy-blue, $base-font + 18px);
            margin: 13px 0 10px 0;
        }
        .cartempty-message {
            @include WorkSansMedium($WorkSans);
            font-size: $base-font + 6px;
            margin: 20px 0 0 0;
            font-weight: normal;
        }
    }
    .cart-action-continue-shopping {
        margin-top: 30px;
        button {
            padding: 16px 38px;
            @include GothamBold($Gotham);
            background: $SW-navy-blue;
            border-color: $SW-navy-blue;
            @include text-properties(2px, $white, $base-font + 2px);
            a {
                &:hover {
                    color: $white;
                }
            }
        }
    }
    .product-listing {
        @extend %clearfix;
        padding: 48px 0;
        margin: 30px 0 0 0;
        h2 {
            @include Gotham($Gotham);
            @include text-properties(1px, $SW-navy-blue, $base-font + 7px);
        }
        .carousel-recommendations {
            width: 100%;
            display: inline-block;
            text-align: center;
            .product-tile {
                width: calc(20% - 4px);
                width: -moz-calc(20% - 4px);
                width: -webkit-calc(20% - 4px);
                width: -o-calc(20% - 4px);
                float: none;
                display: inline-block;
                vertical-align: top;
                box-sizing: border-box;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                .product-swatches {
                    display: block;
                }
                .p-image-rollover {
                    display: none;
                }
            }
            button {
                &.slick-arrow {
                    width: 17px;
                    height: 23px;
                    text-indent: -9999px;
                    position: absolute;
                    bottom: 0;
                    margin: 0 auto;
                    z-index: 9;
                    -webkit-transition: initial;
                    transition: initial;
                    -ms-transition: initial;
                    padding: 0;
                    border: 0;
                    top: 50%;
                }
                &.slick-prev {
                    left: -20px;
                    background: url(../../../images/trending-arrows-left.svg)
                        no-repeat;
                    &.slick-disabled {
                        background: url(../../../images/recently-viewed-arrows-left-unactivated.svg)
                            no-repeat;
                        cursor: not-allowed;
                    }
                }
                &.slick-next {
                    right: -20px;
                    background: url(../../../images/trending-arrows-right.svg)
                        no-repeat;
                    &.slick-disabled {
                        background: url(../../../images/recently-viewed-arrows-right-unactivated.svg)
                            no-repeat;
                        cursor: not-allowed;
                    }
                }
            }
        }
    }
}
.promo-adjustment {
    @include Gotham($Gotham);
    @include text-properties(0.5px, $SW-green, $base-font - 1px);
}
.specialmessaging {
    p {
        @include Gotham($Gotham);
        @include text-properties(0.5px, $SW-gray, $base-font - 1px);
    }
}
.specialmessag p.expeditedship {
    @include Gotham($Gotham);
    @include text-properties(0.5px, $SW-gray, $base-font - 1px);
}
.intl-shipping-btn {
    @include Gotham($Gotham);
    @include text-properties(1px, $SW-gray, $base-font - 1px);
    @media screen and (max-width: 767px) {
        padding: 0 10px 6px;
    }
}
.cart-row {
    .specialmessag {
        &.monogrammed {
            @media screen and (min-width: 768px) {
                .optioninfo {
                    color: $black;
                    @include WorkSansSemiBold($WorkSans);
                    font-size: $base-font - 1px;
                    .optionheading {
                        text-transform: uppercase;
                    }
                    .optionvalue {
                        @include WorkSansMedium($WorkSans);
                    }
                    span {
                        &.monogram,
                        &.hemming {
                            a {
                                padding: 10px;
                                border: 1px solid $black;
                                border-radius: 0;
                                display: inline-block;
                                &:before {
                                    content: "";
                                    background: url("../../../images/ww/plus-symbol.png")
                                        no-repeat;
                                    width: 11px;
                                    height: 11px;
                                    display: inline-block;
                                    padding-right: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.quick-order-badge {
    .quick-order-badge-link {
        font-size: $base-font - 2px;
        @include WorkSansSemiBold($WorkSans);
        color: $black;
        line-height: 10px;
        &::after {
            background: url("../../../images/fb/catalog-quickorder-badge.svg")
                no-repeat;
            background-size: 14px;
        }
    }
}
.ui-dialog {
    &.shipping-overlay {
        .ui-widget-header {
            .ui-dialog-title {
                font-size: $base-font;
                @include WorkSansBold($WorkSans);
            }
        }
        #dialog-container {
            .order-detail {
                font-size: $base-font;
                @include WorkSansMedium($WorkSans);
                &.shippingtotal {
                    .value {
                        @include WorkSansBold($WorkSans);
                    }
                }
            }
        }
    }
}
.qa-section {
    .question-title {
        font-size: $base-font + 8px;
        font-family: $HurmeBlack;
        letter-spacing: 3px;
        text-transform: uppercase;
    }
    .gift-card-intro {
        p {
            font-family: $WorkSans;
            font-size: $base-font + 4px;
            letter-spacing: 0.3px;
        }
        h3 {
            font-family: $WorkSans;
            font-weight: 700;
            font-size: $base-font + 1px;
            letter-spacing: 0.3px;
        }
    }
    .qa-content {
        .question {
            font-size: $base-font + 2px;
            font-family: $WorkSans;
            font-weight: 700;
            letter-spacing: 0.5px;
            &:hover {
                color: $black;
            }
        }
        .answer,
        .answer p {
            @include WorkSansMedium($WorkSans);
            font-size: $base-font + 2px;
            letter-spacing: 0.3px;
            line-height: 1.5;
            color: $black;
        }
        .answer h3 {
            font-size: $base-font + 2px;
            letter-spacing: 0.3px;
        }
    }
}
@media screen and (min-width: 767px) {
    .cart-columns {
        .column {
            &.col-1 {
                max-width: 457px;
                width: 48%;
            }
            &.col-2 {
                width: 15.5%;
            }
            &.col-3 {
                &.section-header {
                    padding-left: 14px;
                }
            }
        }
    }
}
.account-welcome {
    .welcome-message {
        font-size: 1.2em;
        font-style: italic;
    }
    .not-user {
        margin: 0.83em 0;
    }
}
#password-reset {
    white-space: nowrap;
}
//PLCC
.cart-plcc-section {
    button {
        background: url(../../../images/arrow-down.png) no-repeat;
    }
    &.active {
        button {
            background: url(../../../images/x-icon.svg) no-repeat;
        }
    }
}
.ui-dialog {
    &.coupon-tooltip {
        .dialog-content {
            &.ui-dialog-content {
                padding: 0 14px 0 25px;
            }
        }
    }
}
@media screen and (max-width: 1023px) {
    .cart-empty {
        .cat-banner {
            padding: 45px 0;
            h1 {
                @include WorkSansExtraBold($WorkSans);
            }
        }
        .cart-action-continue-shopping {
            margin-top: 35px;
        }
    }
    .pt_cart {
        .qa-section {
            .qa-content {
                padding: 25px 17px 20px 30px;
                &:nth-child(2n),
                &:nth-child(3n),
                &:nth-child(4n),
                &:nth-child(n) {
                    padding: 25px 17px 20px 30px;
                }
            }
        }
    }
    .cart-row {
        .col-2 {
            .not-available {
                margin-left: 0;
            }
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .cart-footer {
        .cart-order-totals {
            width: 42%;
        }
        .cart-actions {
            .cart-action-checkout {
                width: 42%;
                .paypal-button {
                    margin: 25px 9.3% 0 0;
                }
            }
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .cart-coupon-code {
        input {
            width: 170px;
        }
        button {
            &#add-coupon {
                width: 115px;
            }
        }
        .cart-message {
            width: auto;
        }
        .input-fields {
            width: auto;
            max-width: inherit;
        }
        .cart-right-section {
            .error-messages {
                width: 41%;
            }
        }
    }
    .cart-footer {
        .cart-actions {
            .cart-action-checkout {
                .paypal-button {
                    margin: 37px 0 0;
                    width: 100%;
                    .or-link {
                        margin: 0 0;
                    }
                    + a {
                        margin: 43px 0 0 20px;
                        display: inline-block;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .section-header {
        font-size: $base-font + 8px;
    }
    .cart-empty {
        .cat-banner {
            h1 {
                font-size: $base-font + 18px;
                margin: 0 0 30px 0;
            }
        }
        .product-listing {
            padding: 0;
            h2 {
                font-size: $base-font + 7px;
                margin: 30px 0 0 0;
            }
            .trending {
                margin: 0;
            }
        }
        .cart-action-continue-shopping {
            margin-top: 50px;
        }
    }
    .cart-footer {
        padding: 8px 8px 23px 11px;
        .payment-description {
            font-size: $base-font + 3px;
            margin-top: 20px;
            .heading {
                @include Gotham($Gotham);
                @include text-properties(1px, $SW-navy-blue, $base-font + 1px);
                margin-bottom: 12px;
            }
            .see-details {
                width: 100%;
            }
            .payment-cards {
                padding-bottom: 13px;
                li {
                    padding-right: 4px;
                }
            }
            .brand-cards {
                line-height: 24px;
                margin: 12px 0 5px;
            }
        }
        .cart-order-totals {
            margin-top: 18px;
            padding-right: 0;
            max-width: 100%;
            .order-detail {
                padding: 0 0 10px 0;
                .label {
                    font-size: $base-font + 3px;
                    width: 54%;
                }
                .value {
                    width: 46%;
                }
                &.order-total {
                    padding: 25px 0 10px 0;
                    margin-top: 14px;
                    &.order-total-remaining {
                        padding: 0 0 10px 0;
                        margin-top: 0;
                    }
                }
                &.remaining-total {
                    padding: 25px 0 10px 0;
                    margin-top: 14px;
                }
            }
        }
        .cart-actions {
            .cart-action-checkout {
                margin: 20px 0px 0 0;
                .button-fancy-large {
                    padding: 17px 0;
                }
                .paypal-button {
                    margin: 17px 7% 0 0;
                    .or-link {
                        padding-left: 0;
                        margin: 7px 0px 0 9px;
                    }
                }
            }
        }
    }
    .cart-row {
        .cart-columns {
            .column {
                &.col-1 {
                    .item-details {
                        width: 59%;
                    }
                }
            }
        }
        .column {
            &.col-1 {
                .item-details {
                    .name {
                        font-size: 16px;
                        margin: 0 0 13px;
                    }
                    .attribute {
                        margin-bottom: 8px;
                    }
                }
            }
            &.col-2 {
                .custom-cart-update {
                    margin: 0 0;
                    input {
                        text-align: left;
                    }
                }
            }
            &.col-3 {
                .item-total {
                    margin-top: 24px;
                    text-align: left;
                    .sub-total {
                        display: inline-block;
                        width: auto;
                        @include Gotham($Gotham);
                        @include text-properties(
                            1px,
                            $SW-navy-blue,
                            $base-font - 1px
                        );
                    }
                    .price-unadjusted,
                    .price-adjusted-total {
                        width: auto;
                        display: inline-block;
                    }
                    .price-total,
                    .price-total-strikeoff {
                        text-align: left;
                        margin-left: 30px;
                    }
                }
            }
            &.col-4 {
                .item-quantity-details {
                    margin-top: 10px;
                    text-align: left;
                    font-size: $base-font - 2px;
                    .btm-space {
                        margin-bottom: 5px;
                    }
                    .button-text {
                        text-align: left;
                        font-size: $base-font - 2px;
                    }
                }
            }
        }
        &.specialproductsetchild {
            .promo-adjustment {
                width: 100%;
                display: inline-block;
                padding: 0 0 0 20px;
            }
        }
        .specialmessag {
            display: inline-block;
            margin: 10px 0 0;
            &.specialmessagpromo {
                margin: 0;
            }
            &:nth-child(2n) {
                display: none;
            }
        }
    }
    .cart-top-banner {
        h1 {
            @include GothamBold($Gotham);
            @include text-properties(2px, $SW-navy-blue, $base-font + 18px);
            margin: 20px 0 10px 0;
        }
        button {
            padding: 15px 42px;
        }
        .pid {
            letter-spacing: 0;
            font-size: 12px;
        }
    }
    .cart-empty {
        .cat-banner {
            top: 95px;
            h1 {
                font-size: $base-font + 18px;
                letter-spacing: 2px;
                margin: 0;
            }
            .cartempty-message {
                font-size: $base-font + 3px;
                margin: 15px 0 0;
            }
            .cart-action-continue-shopping {
                margin: 20px 0 0;
            }
            .cart-action-continue-shopping button {
                padding: 16px 22px;
            }
        }
        .product-listing {
            h2 {
                font-size: $base-font + 7px;
            }
        }
    }
    .last-visited-section {
        margin-top: 28px;
    }
    .pt_cart {
        .qa-section {
            .question-title {
                padding: 25px 0;
            }
            .qa-content {
                padding: 21px;
                &:nth-child(2n),
                &:nth-child(3n),
                &:nth-child(4n),
                &:nth-child(n) {
                    padding: 21px;
                }
            }
        }
    }
}
.live-chat .content-asset .bcStatic img {
    width: 20px;
}
.intl-shipping {
    font-family: WorkSansRegular($WorkSans), $Helvetica;
    button {
        font-family: $Helvetica;
    }
    label {
        font-family: $HelveticaBold;
    }
    .intlshipping-exclusions {
        button {
            font-family: $Hurme;
            &.cancel {
                background: $manatee;
                border: $manatee;
            }
        }
    }
}
.cart-top-banner {
    .cart-action-checkout {
        .button-fancy-large {
            max-width: 290px;
            width: 23%;
            @include GothamBold($Gotham);
            background: $SW-navy-blue;
            border-color: $SW-navy-blue;
            @include text-properties(2px, $white, $base-font + 2px);
            @media screen and (max-width: 767px) {
                width: 100%;
            }
            @media screen and (min-width: 768px) and (max-width: 1024px) {
                width: 37%;
            }
        }
    }
    .paypal-button {
        .or-link {
            @include Gotham($Gotham);
            text-transform: uppercase;
            @include text-properties(1px, $SW-navy-blue, $base-font + 1px);
        }
        .checkout-paypal-btn {
            @media screen and (min-width: 768px) and (max-width: 1024px) {
                margin-left: 30px;
            }
        }
    }
}
.cart-overlay .mini-cart-content .cart-recommendations .you-may-like {
    .product-tile .product-image {
        &.product-brylane .thumb-link img.p-image {
            height: 100%;
            object-fit: cover;
        }
    }
}
@media only screen and (max-width: 767px) and (min-width: 320px) and (orientation: portrait) {
    .pt_cart_template_b {
        .cart-row .column.col-3 .item-total {
            .price-total,
            .price-total-strikeoff {
                margin-left: 0;
            }
        }
    }
}
#wrapper.pt_cart_template_b,
#wrapper.pt_cart {
    .cart-row.sps-cart-row {
        .column {
            &.col-1 {
                .item-details {
                    .product-list-item {
                        .product-brand {
                            @include Gotham($Gotham);
                            text-transform: uppercase;
                            @include text-properties(
                                0.2px,
                                $brand-name-color,
                                $base-font - 2px
                            );
                        }
                        .name {
                            a {
                                @include Gotham($Gotham);
                                @include text-properties(
                                    1px,
                                    $SW-navy-blue,
                                    $base-font
                                );
                                text-transform: uppercase;
                            }
                        }
                        .sku {
                            @include Gotham($Gotham);
                            @include text-properties(
                                0.5px,
                                $SW-gray,
                                $base-font - 2px
                            );
                            div,
                            span,
                            .value {
                                color: $SW-gray;
                            }
                        }
                        .swsps-top-bottom-label {
                            text-transform: capitalize;
                            @include Gotham($Gotham);
                            @include text-properties(
                                1px,
                                $SW-navy-blue,
                                $base-font - 1px
                            );
                        }
                        .swsps-top-bottom-val {
                            @include GothamBold($Gotham);
                            @include text-properties(
                                1px,
                                $SW-navy-blue,
                                $base-font
                            );
                            text-transform: capitalize;
                        }
                        .product-availability-list {
                            font-size: 12px;
                            margin-bottom: 0;
                            span.label {
                                text-transform: capitalize;
                                @include Gotham($Gotham);
                                @include text-properties(
                                    1px,
                                    $SW-navy-blue,
                                    $base-font - 1px
                                );
                            }
                            span:not(.label) {
                                @include GothamBold($Gotham);
                                @include text-properties(
                                    1px,
                                    $SW-navy-blue,
                                    $base-font
                                );
                                text-transform: capitalize;
                            }
                            span.is-in-stock {
                                @include GothamBold($Gotham);
                                @include text-properties(
                                    1px,
                                    $SW-navy-blue,
                                    $base-font
                                );
                                text-transform: capitalize;
                            }
                        }
                    }
                    .cart-unit-price {
                        font-size: 12px;
                        margin: 0 0 7px;
                        span.label {
                            text-transform: capitalize;
                            @include Gotham($Gotham);
                            @include text-properties(
                                1px,
                                $SW-navy-blue,
                                $base-font - 1px
                            );
                        }
                        span.price-promotion {
                            font-weight: bold;
                            letter-spacing: 1px;
                            .price-sales {
                                font-weight: bold;
                                letter-spacing: 1px;
                            }
                        }
                    }
                    .specialmessaging {
                        p,
                        span {
                            font-weight: 600;
                            font-size: 12px;
                            letter-spacing: 0.26px;
                        }
                        p {
                            @include Gotham($Gotham);
                            @include text-properties(
                                0.5px,
                                $SW-gray,
                                $base-font - 1px
                            );
                        }
                        p.nationalmessage,
                        p.clearancemessage,
                        p.exclusionmessage {
                            color: #c55300;
                        }
                        .finalsalemessage {
                            font-weight: bold;
                            font-size: 12px;
                            letter-spacing: 0.5px;
                        }
                    }
                }
            }
            &.col-2 {
                .custom-cart-update {
                    @include Gotham($Gotham);
                    @include text-properties(
                        1px,
                        $SW-navy-blue,
                        $base-font + 2px
                    );
                    border: 1px solid $SW-navy-blue;
                }
            }
            &.col-3 {
                span {
                    &.price-sales {
                        @include Gotham($Gotham);
                        font-size: 15px;
                        color: #af3434;
                        letter-spacing: 0.5px;
                    }
                    &.price-total {
                        @include Gotham($Gotham);
                        font-size: 15px;
                        letter-spacing: 0.5px;
                    }
                    &.price-unadjusted {
                        @include Gotham($Gotham);
                        font-size: 15px;
                        letter-spacing: 0.5px;
                    }
                }
            }
            &.col-4 {
                .item-quantity-details .item-user-actions {
                    .button-text span,
                    a {
                        letter-spacing: 0.5px;
                        font-size: 11px;
                    }
                }
            }
        }
        @media only screen and (max-width: 767px) {
            .column {
                &.col-3 {
                    .item-total {
                        .sub-total.hide-desktop {
                            display: inline;
                            font-weight: 500;
                            font-size: 11px;
                        }
                        .price-sales,
                        .price-unadjusted {
                            color: #af3434;
                            font-size: 12px;
                        }
                        .price-total {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}
/*------------- SC-7521 For SA Specific Styling --------*/
#wrapper.pt_cart_template_b {
    .cart-left-content {
        .combined-cart-footer {
            .combined-cart-footer-left {
                .cart-top-banner {
                    h1 {
                        @include GothamBold($Gotham);
                        @include text-properties(
                            2px,
                            $SW-navy-blue,
                            $base-font + 18px
                        );
                        @media screen and (max-width: 767px) {
                            @include text-properties(2px, $SW-navy-blue, $base-font + 8px);
                        }
                        @media screen and (max-width: 370px) {
                            @include text-properties(2px, $SW-navy-blue, $base-font + 3px);
                        }
                        @media screen and (min-width: 768px) and (max-width: 1023px) {
                            @include text-properties(
                                1px,
                                $SW-navy-blue,
                                $base-font + 9px
                            );
                            margin-left: 8px;
                        }
                    }
                    .shopping-bag-id {
                        .pid {
                            a {
                                @include GothamMedium($Gotham);
                                @include text-properties(
                                    1px,
                                    $SW-navy-blue,
                                    $base-font
                                );
                                @media screen and (min-width: 768px) and (max-width: 1023px) {
                                    @include text-properties(
                                        0,
                                        $SW-navy-blue,
                                        $base-font - 2px
                                    );
                                    margin-right: 5px;
                                }
                            }
                        }
                    }
                    @media only screen and (min-width: 320px) and (max-width: 767px) {
                        form button {
                            background-color: $SW-navy-blue;
                            border-color: $SW-navy-blue;
                            @include GothamBold($GothamBold);
                            @include text-properties(
                                2px,
                                $white,
                                $base-font + 2px
                            );
                        }
                        .shopping-bag-id {
                            margin-top: 3px;
                        }
                        .upper-subtotal {
                            .label {
                                @include GothamMedium($Gotham);
                                @include text-properties(
                                    0.5px,
                                    $SW-navy-blue
                                );
                            }
                            .value {
                                @include GothamBold($GothamBold);
                                @include text-properties(
                                    0.5px,
                                    $SW-navy-blue
                                );
                            }
                        }
                    }
                }
                form {
                    #cart-table {
                        .thead {
                            .section-header {
                                @include GothamMedium($Gotham);
                                @include text-properties(
                                    0.5px,
                                    $SW-navy-blue,
                                    $base-font + 5px
                                );
                                text-transform: uppercase;
                            }
                        }
                    }
                }
            }
            .cart-footer {
                .cart-right-lower {
                    .cart-footer-left {
                        .payment-description {
                            .heading {
                                @include GothamMedium($Gotham);
                                @include text-properties(
                                    1px,
                                    $SW-navy-blue,
                                    $base-font + 5px
                                );
                            }
                        }
                    }
                    .tax-disclaimer {
                        @include GothamBook($GothamBook);
                        @include text-properties(
                            0px,
                            $SW-navy-blue,
                            $base-font - 1px
                        );
                    }
                }
                .cart-right-upper {
                    .cart-coupon-code {
                        .cart-message {
                            #promo-icon-plus {
                                @include text-properties(
                                    0px,
                                    $SW-navy-blue,
                                    $base-font + 2px
                                );
                                @include GothamMedium($Gotham);
                            }
                            @include text-properties(
                                0.5px,
                                $SW-navy-blue,
                                $base-font + 2px
                            );
                            @media only screen and (min-width: 768px) and (max-width: 1080px) {
                            	font-size:11px;
                            	margin-left:0;
                            }
                            @include GothamMedium($Gotham);
                            a {
                                color: $SW-navy-blue;
                            }
                        }
                        .cart-left-section {
                            .input-fields {
                                input.coupon-code {
                                    @include text-properties(
                                        0.5px,
                                        $s4a-grey,
                                        $base-font + 1px
                                    );
                                    @include GothamBook($GothamBook);
                                    border: 1px solid $SW-navy-blue;
                                    &::placeholder {
										font-size: $base-font;
									}
                                }
                                button#add-coupon {
                                    background-color: $SW-navy-blue;
                                    @include text-properties(
                                        2px,
                                        $white,
                                        $base-font + 1px
                                    );
                                    @include GothamBold($GothamBold);
                                    border-color: $SW-navy-blue;
                                }
                            }
                        }
                    }
                    .intl-shipping-btn {
                        @include text-properties(
                            0.5px,
                            $s4a-grey,
                            $base-font - 1px
                        );
                        @include GothamMedium($Gotham);
                    }
                    .cart-order-totals {
                        .order-detail-heading {
                            @include text-properties(
                                1px,
                                $SW-navy-blue,
                                $base-font + 6px
                            );
                            @include GothamMedium($Gotham);
                            text-transform: uppercase;
                        }
                        .order-detail {
                            .label,
                            .value {
                                @include text-properties(
                                    0.5px,
                                    $SW-navy-blue,
                                    $base-font + 1px
                                );
                                @include GothamMedium($Gotham);
                            }
                            &.order-total {
                                .label,
                                .value {
                                    @include text-properties(
                                        0.5px,
                                        $SW-navy-blue,
                                        $base-font + 4px
                                    );
                                    @include GothamBold($GothamBold);
                                }
                            }
                            &.discount {
                                .label,
                                .value {
                                    @include text-properties(
                                        0.5px,
                                        $s4a-green,
                                        $base-font + 1px
                                    );
                                    @include GothamMedium($Gotham);
                                }
                            }
                        }
                    }
                    .cart-actions {
                        .cart-action-checkout.clearboth {
                            button {
                                @include text-properties(
                                    2px,
                                    $white,
                                    $base-font + 2px
                                );
                                @include GothamBold($GothamBold);
                                background-color: $SW-navy-blue;
                                border-color: $SW-navy-blue;
                            }
                            .secure-checkout-msg {
                                @include text-properties(
                                    0.5px,
                                    $s4a-grey,
                                    $base-font - 2px
                                );
                                @include GothamBook($GothamBook);
                            }
                        }
                    }
                    .intl-shipping-btn {
                        @include text-properties(
                            0.5px,
                            $s4a-grey,
                            $base-font - 1px
                        );
                        @include GothamMedium($Gotham);
                    }
                }
            }
        }
    }
    .you-may-like {
        .recommendation-heading {
            color: $SW-navy-blue;
            .trending {
                @include text-properties(1px, $SW-navy-blue, $base-font + 4px);
                @include GothamMedium($Gotham);
            }
        }
    }
    .last-visited-section {
        .last-visited-heading {
            @include text-properties(1px, $SW-navy-blue, $base-font + 4px);
            @include GothamMedium($Gotham);
            text-align: center;
            h2 {
                color: $SW-navy-blue;
            }
        }
    }
    @media screen and (max-width: 767px) {
        .cart-row .column.col-3 .item-total {
            font-size: 13px;
        }
    }
}
.pt_cart_template_b .cart-left-content .combined-cart-footer .combined-cart-footer-left form #cart-table .cart-promo.bonus-product-calloutMsg{
    .bonus-item-details {
        color: $light-red;
        a.tooltip {
            color: $RedRibbon;
        }
    }
    .bonus-item-actions {
        color: $RedRibbon;      
        a.select-bonus {           
            color: $RedRibbon; 
        }
    }
}

.perks-discount {
    @include text-properties(0, $SW-navy-blue, $base-font);
    @include GothamMedium($Gotham);
    span {
        @include text-properties(0, $SW-navy-blue, $base-font);
        @include GothamMedium($Gotham);
    }
    a {
        color: $cobalt-blue;
        font-size: $base-font;
        font-family:$Helvetica;
        span {
            color: $cobalt-blue;
            font-size: $base-font;
            font-family:$Helvetica;
        }
    }
}