.checkout-body.plcc-redesign {
.ui-dialog {
	&.checkout-plcc-modal,
	&.checkout-plcc-modal-step2,
	&.plcc-acquisition-rebuttal-model,
	&.checkout-plcc-modal-terms,
	&.checkout-plcc-modal-step3 {
		.approved-model, .review-model {
			.header {
				color: $sw-brand-blue;
			}
		}

		.plcc-model-dialog .plcc-offer-main .plcc-greeting {
			color: $sw-brand-blue;
		}

		.plcc-offer-banner {
			background: linear-gradient(270deg, $sw-brand-blue 0%, #4E6788 100%);
		}

		.plcc-application-banner,
		.plcc-terms-banner-container {
			background: $sw-brand-blue;
		}
	}
}
}