.catalog-quick-order {
    .product-image-container {
        &.quick-order-product-brylane {
            .product-thumbnails {
                .productthumbnail {
                    object-fit: cover;
                    min-height: 106px;
                    @media screen and (max-width: 360px) {
                        min-height: 387px;
                    }
                    @media screen and (min-width: 361px) and (max-width: 767px) {
                        min-height: 403px;
                    }
                    @media screen and (min-width: 768px) and (max-width: 1023px) {
                        min-height: 104px;
                    }
                    @media screen and (min-width: 1024px) and (max-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
                        min-height: 99px;
                    }
                }
            }
            .primary-image-quick-order {
                object-fit: cover;
                min-height: 317px;
                @media screen and (max-width: 360px) {
                    min-height: 387px;
                }
                @media screen and (min-width: 361px) and (max-width: 767px) {
                    min-height: 403px;
                }
                @media screen and (min-width: 768px) and (max-width: 1023px) {
                    min-height: 288px;
                }
                @media screen and (min-width: 1024px) and (max-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
                    min-height: 297px;
                }
            }
        }
    }
    .product-add-to-cart {
        .selected-option,
        select {
            border-width: 2px;
            height: 52px;
        }
    }
    .pdpForm {
        .form-row {
            label {
                span.error {
                    font-size: $base-font - 2px;
                }
                .second-label {
                    display: none;
                }
            }
        }
    }
    .pdp-main {
        .heading {
            @include WorkSansBold($WorkSans);
            font-size: $base-font + 2px;
        }
        .pdp-main .product-col-2 .tab-content {
            @include WorkSansMedium($WorkSans);
            font-size: $base-font + 2px;
        }
        .product-price {
            span {
                font-size: $base-font + 6px;
            }
            .price-standard {
                padding: 0 35px 0 0;
                @media screen and (max-width: 767px) {
                    padding: 0 19px 0 0;
                }
            }
        }
        .savingmessage {
            color: $reddish-monza;
            margin: 0 0 13px 0;
            @media screen and (max-width: 1023px) {
                margin: 0 0 18px 0;
            }
        }
        .promotion {
            color: $reddish-monza;
            margin: 0 0 17px 0;
            .promotion-callout {
                font-size: $base-font + 1px;
                letter-spacing: 0.5px;
            }
            @media screen and (max-width: 767px) {
                margin: 0 0 23px 0;
            }
        }
        .product-variations {
            .attribute {
                .attribute_label {
                    margin: 15px 0 3px 0;
                    @media screen and (max-width: 1023px) {
                        margin: 15px 0 9px 0;
                    }
                    @media screen and (max-width: 767px) {
                        font-size: $base-font + 2px;
                        margin-bottom: 1px;
                        margin-top: 16px;
                    }
                }
                &.size_border {
                    .attribute_label {
                        margin: 16px 0 11px 0;
                    }
                }
            }
        }
        .product-col-2 {
            .tab-content {
                p,
                ul {
                    @include WorkSansMedium($WorkSans);
                    font-size: $base-font + 2px;
                    @media screen and (max-width: 767px) {
                        font-size: $base-font + 2px;
                    }
                }
            }
            .productinfo {
                font-size: $base-font + 2px;
            }
            @media screen and (max-width: 767px) {
                .shippingdrawer {
                    p {
                        font-size: $base-font + 2px;
                    }
                }
            }
        }
        @media screen and (max-width: 767px) {
            .heading,
            .read-more,
            .clickhere {
                font-size: $base-font + 2px;
            }
            .productinfo {
                .moreinfo {
                    font-size: $base-font + 2px;
                }
            }
        }
    }
    .catalog-quick-order__main {
        .product-name {
            .name-text {
                padding: 0 0 20px 0;
                @media screen and (max-width: 767px) {
                    padding: 0 0 7px 0;
                }
            }
        }
        .pdp-main {
            .product-price {
                margin: 0 0 17px 0;
            }
            .product-variations {
                .attribute {
                    border-top: 1px solid $gainsboro;
                    padding: 0 0 10px 0;
                    @media screen and (max-width: 1023px) {
                        padding: 0 0 11px 0;
                    }
                    @media screen and (max-width: 767px) {
                        padding: 0 0 12px 0;
                    }
                }
            }
            @media screen and (max-width: 767px) {
                .expired-price {
                    margin: 0 0 13px 0;
                }
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .catalog-quick-order {
        .product-add-to-cart {
            button {
                width: calc(100% - 80px);
            }
        }
    }
}
