.pt_checkout {
    &#wrapper {
		#main {
			padding-top: 80px !important;
		}
	}
    
    .checkout-progress-indicator-wrapper {
        .step-1,
        .step-2,
        .step-3 {
            &.submitted {
                &:hover {
                    .progress-text {
                        color: $shipping-progress-active-bg-color;
                    }
                }
            }
            &.inactive {
                &:not(.submitted) a {
                    cursor: default;
                }
                .progress-text,
                .progress-count {
                    @include Gotham($Gotham);
                    @include text-properties(1px, $SW-gray, $base-font);
                }
                .progress-count {
                    background: $white;
                    border: 1px solid $SW-gray;
                    @include Gotham($Gotham);
                    @include text-properties(1px, $SW-gray, $base-font);
                }
            }
            &.active {
                .progress-text {
                    @include Gotham($Gotham);
                    @include text-properties(1px, $SW-navy-blue, $base-font);
                }
                .progress-count {
                    background: $SW-navy-blue;
                    border: 1px solid $SW-navy-blue;
                    @include Gotham($Gotham);
                    @include text-properties(1px, $white, $base-font);
                }
            }
        }
    }
    .checkout-tab {
        width: 100%;
        display: inline-block;
        .checkout-tab-head h2,
        h1 {
            @include Gotham($Gotham);
            @include text-properties(1px, $SW-navy-blue, $base-font + 6px);
        }
    }
    .customer-signin-section,
    .checkout-tab-content {
        background: $SW-light-gray;
    }
    input[type="checkbox"] {
        outline-color: $SW-navy-blue;
    }
    .form-row {
        .field-wrapper {
            input[type="date"],
            textarea,
            input[type="number"],
            input[type="password"],
            input[type="text"],
            input[type="tel"],
            .custom-select select,
            .custom-select .selected-option,
            .custom-select.address-dropdown .selected-option {
                @include Gotham($Gotham);
                border-color: $SW-navy-blue;
                @include text-properties(1px, $SW-navy-blue, $base-font);
            }
            .custom-select.address-dropdown .selected-option {
                .nickname-bold {
                    @include Gotham($Gotham);
                    @include text-properties(1px, $SW-navy-blue, $base-font);
                }
            }
        }
        .button-fancy-large {
            @include GothamBold($Gotham);
            background: $SW-navy-blue;
            border-color: $SW-navy-blue;
            @include text-properties(2px, $white, $base-font + 2px);
            span {
                @include GothamBold($Gotham);
                letter-spacing: 1px;
                font-size: $base-font + 2px;
            }
        }
    }
    .address-section.make-label-absolute .form-row.label-inline,
    .gift-section .form-row.form-indent,
    .make-label-absolute .form-row:not(.label-inline) {
        label span {
            @include Gotham($Gotham);
            @include text-properties(1px, $SW-navy-blue, $base-font);
        }
    }
    legend {
        @include GothamBook($GothamBook);
        @include text-properties(0.5px, $SW-navy-blue, $base-font + 4px);
        background: $SW-light-gray;
        .required-field {
            @include GothamBook($GothamBook);
            @include text-properties(0.5px, $promo-red, $base-font);
        }
        &.head-wrapper {
            .required-field {
                @include GothamBook($GothamBook);
                @include text-properties(0.5px, $promo-red, $base-font);
            }
        }
    }
    .shipping-section {
        .form-row .field-desc span.form-caption {
            @include GothamBook($GothamBook);
            @include text-properties(0px, $SW-navy-blue, $base-font + 1px);
        }
        label.field-desc {
            @include Gotham($Gotham);
            @include text-properties(0px, $SW-navy-blue, $base-font + 1px);
            .base-shipping {
                @include Gotham($Gotham);
                @include text-properties(0px, $SW-navy-blue, $base-font + 1px);
            }
            .form-caption {
                @include GothamBook($GothamBook);
                @include text-properties(0px, $SW-navy-blue, $base-font + 1px);
            }
        }
    }
    .error-tender-rebuttal {
        font-family: $Helvetica;
    }
    .basket-details {
        .cart-row.product-brylane-lineitem {
            .cart-columns .col-1 {
                .item-image img {
                    object-fit: cover;
                    min-height: 144px;
                    @media screen and (max-width: 360px) {
                        min-height: 196px;
                    }
                    @media screen and (min-width: 361px) and (max-width: 767px) {
                        min-height: 204px;
                    }
                }
            }
        }
    }
    .cart-row {
        .column.col-1 {
            .item-details {
                .attribute {
                    margin-bottom: 7px;
                    &[data-attribute="braCupSize"] {
                        text-transform: uppercase;
                    }
                    .value,
                    .label {
                        font-weight: normal;
                        text-transform: uppercase;
                    }
                }
                .name {
                    margin: 0 0 15px;
                    a {
                        @include Gotham($Gotham);
                        @include text-properties(
                            1px,
                            $SW-navy-blue,
                            $base-font
                        );
                        text-transform: uppercase;
                    }
                }
                .product-brand {
                    @include Gotham($Gotham);
                    text-transform: uppercase;
                    @include text-properties(
                        0.2px,
                        $brand-name-color,
                        $base-font - 2px
                    );
                }
                .sku {
                    margin: 2px 0 10px 0;
                    @include Gotham($Gotham);
                    @include text-properties(0.5px, $SW-gray, $base-font - 2px);
                }
                .attribute {
                    margin-bottom: 7px;
                    .label {
                        text-transform: capitalize;
                        @include GothamBold($Gotham);
                        @include text-properties(
                            1px,
                            $black,
                            $base-font - 1px
                        );
                    }
                    .value {
                        @include Gotham($Gotham);
                        text-transform: capitalize;
                        @include text-properties(
                            1px,
                            $black,
                            $base-font
                        );
                    }
                }
                .price-sales {
                    @include GothamBold($Gotham);
                    @include text-properties(1px, $SW-navy-blue, $base-font);
                }
                .price-promotion {
                    .price-sales {
                        color: $SW-navy-blue;
                    }
                    .price-standard {
                        @include Gotham($Gotham);
                        text-decoration: line-through;
                        @include text-properties(1px, $SW-gray, $base-font);
                    }
                }
                .promo-adjustment {
                    margin-left: 0;
                    @include Gotham($Gotham);
                    @include text-properties(
                        0.5px,
                        $SW-green,
                        $base-font - 1px
                    );
                }
                .custom-cart-qty {
                    .label {
                        text-transform: capitalize;
                        @include GothamBold($Gotham);
                        @include text-properties(
                            1px,
                            $SW-navy-blue,
                            $base-font - 1px
                        );
                    }
                    .value {
                        @include GothamLight($Gotham);
                        text-transform: capitalize;
                        @include text-properties(
                            1px,
                            $SW-navy-blue,
                            $base-font
                        );
                    }
                }
            }
        }
        .column {
            &.col-2 {
                width: 25%;
                text-align: right;
                max-width: 100%;
                .custom-cart-qty {
                    margin-top: 42%;
                    .value {
                        @include Gotham($Gotham);
                        @include text-properties(
                            1px,
                            $SW-navy-blue,
                            $base-font
                        );
                    }
                }
            }
            &.col-3 {
                width: 21%;
                max-width: 100%;
                .item-total {
                    margin-top: 49%;
                    text-transform: uppercase;
                    @include Gotham($Gotham);
                    @include text-properties(
                        1px,
                        $black,
                        $base-font + 2px
                    );
                    .price-total-strikeoff {
                        color: $SW-navy-blue;
                    }
                }
                .price-unadjusted {
                    text-decoration: line-through;
                    color: $SW-gray;
                }
                .price-adjusted-total {
                    color: $SW-navy-blue;
                }
            }
        }
    }
    .form-row {
        label {
            span {
                font-family: $HelveticaMedium;
                color: $dim-gray;
                text-transform: capitalize;
            }
        }
    }
    .make-label-absolute {
        .form-row .field-wrapper {
            .custom-select .selected-option {
                @include Gotham($Gotham);
                @include text-properties(1px, $SW-navy-blue, $base-font);
            }
        }
        label {
            span {
                &.error {
                    font-size: $base-font;
                    font-family: $HelveticaMedium;
                }
            }
            &.input-focus {
                span {
                    &.error {
                        font-size: $base-font - 1px;
                    }
                }
            }
        }
    }
    //Q&A
    .qa-section {
        .question-title {
            padding: 26px 25px 25px 39px;
            @include GothamBold($Gotham);
            @include text-properties(2px, $SW-navy-blue, $base-font + 6px);
            text-transform: uppercase;
        }
        .qa-content {
            padding: 23px 17px 20px 43px;
            letter-spacing: 1px;
            background: $SW-light-gray;
            &:nth-child(2n),
            &:nth-child(3n),
            &:nth-child(4n),
            &:nth-child(n) {
                padding: 23px 17px 20px 43px;
                letter-spacing: 1px;
            }
            .question {
                @include GothamBold($Gotham);
                @include text-properties(1px, $SW-navy-blue, $base-font + 1px);
                &::after {
                    background: url(../../../images/arrow-down.png) no-repeat;
                    background-position: 99.7% 4px;
                }
                &.active {
                    &::after {
                        background: url(../../../images/arrow-up.png) no-repeat;
                        background-position: 99.7% 4px;
                    }
                }
            }
            .answer {
                @include GothamBook($GothamBook);
                @include text-properties(1px, $SW-navy-blue, $base-font + 1px);
                padding: 12px 0 2px 0;
                a {
                    @include GothamBook($GothamBook);
                    @include text-properties(
                        1px,
                        $SW-navy-blue,
                        $base-font + 1px
                    );
                }
            }
        }
    }
    .billing-coupon-code {
        button {
            @include GothamBold($Gotham);
            background: $SW-navy-blue;
            border-color: $SW-navy-blue;
            @include text-properties(2px, $white, $base-font + 2px);
            span {
                @include GothamBold($Gotham);
                letter-spacing: 2px;
                font-size: $base-font + 2px;
            }
        }
    }
    .block-section {
        padding: 0 6.2% 0 7.4%;
        @media screen and (max-width: 767px) {
            padding: 0 3.3%;
        }
    }
    .spc-shipping {
        .customer-signin-section {
            padding: 26px 30px 26px;
            margin-top: 27px;
            @media screen and (max-width: 767px) {
                padding: 15px 15px 20px;
                margin-top: 20px;
            }
        }
        .saved-address-block {
            .head-wrapper {
                padding-bottom: 17px;
                margin: 30px 0 26px 0;
                font-weight: 500;
                .required-field {
                    @include GothamBook($GothamBook);
                    @include text-properties(0.5px, $promo-red, $base-font);
                }
                @media screen and (max-width: 767px) {
                    padding-bottom: 15px;
                    margin: 21px 0 12px;
                }
            }
        }
        .billing {
            h2 {
                margin-bottom: 14px;
            }
        }
        .summary {
            h2 {
                margin: 19px 0 14px 0;
            }
        }
    }
    .spc-shipping {
        .checkout-tab-head {
            h2 {
                padding: 0 0 0 2px;
                @media screen and (max-width: 767px) {
                    padding-top: 0;
                }
            }
        }
        .shipping {
            h2 {
                padding: 12px 0 0 0;
            }
        }
        &.registered-shipping {
            .shipping {
                h2 {
                    padding: 15px 0 0 0;
                    @media screen and (max-width: 767px) {
                        padding-bottom: 0;
                    }
                }
            }
            .saved-address-block {
                padding: 0 6.4% 0 7.4%;
                @media screen and (max-width: 767px) {
                    padding: 0 3.3%;
                }
                .head-wrapper {
                    margin: 30px 0 6px 0;
                }
                .saved-address-count {
                    margin: 7px 0 18px;
                    @media screen and (max-width: 767px) {
                        margin: 0px 0 8px;
                    }
                }
            }
            .shipping-section {
                .delivery-option-heading {
                    legend {
                        padding: 17px 0 16px 0;
                        margin-bottom: 21px;
                        @media screen and (max-width: 767px) {
                            padding: 15px 0 16px 0;
                        }
                    }
                    a {
                        margin: 4px 0 0 0;
                        @media screen and (max-width: 767px) {
                            margin: 0;
                        }
                    }
                }
            }
            .address-actions {
                .seperator {
                    margin: 0 13px;
                    @media screen and (max-width: 767px) {
                        margin: 0 6px;
                    }
                }
            }
            @media screen and (max-width: 1023px) {
                .select-address {
                    width: 100%;
                }
                .address-section {
                    .form-row {
                        &.useAsBillingAddress {
                            padding: 0px 0 0 2px;
                            margin-bottom: 5px;
                        }
                    }
                }
            }
        }
    }
    .form-row {
        margin: 0 0 19px 0;
    }
    .address-section {
        .form-row {
            @media screen and (max-width: 767px) {
                width: 100%;
                padding: 0;
            }
            &.postal {
                @media screen and (max-width: 767px) {
                    width: 50%;
                    padding-right: 6%;
                }
            }
            &.label-inline {
                label {
                    span {
                        line-height: 14px;
                    }
                }
            }
        }
    }
    .shipping-section {
        .delivery-option-heading {
            legend {
                padding: 32px 0 16px 0;
                @media screen and (max-width: 767px) {
                    padding: 15px 0 16px 0;
                }
            }
        }
        .form-row {
            &.even {
                width: 50%;
                @media screen and (max-width: 767px) {
                    width: 100%;
                }
            }
        }
    }
    .gift-section {
        legend {
            padding: 35px 0 16px 0;
            margin-bottom: 20px;
            @media screen and (max-width: 767px) {
                padding: 15px 0 16px 0;
                margin-bottom: 21px;
            }
        }
        .gift-message-text {
            .field-wrapper {
                width: 82.8%;
            }
        }
        .gift-left {
            .form-row {
                .field-wrapper {
                    margin: 1px 10px 0 4px;
                    @media screen and (max-width: 767px) {
                        margin: 0 10px 0 0;
                    }
                }
                &:nth-child(2n) {
                    width: 44.5%;
                    @media screen and (max-width: 767px) {
                        width: 100%;
                    }
                }
                &.gift-message-text {
                    width: 100%;
                    margin: 0 0 6px 0;
                    .form-caption {
                        margin: 0;
                    }
                }
            }
        }
        .gift-right {
            padding: 0px 0 10px 0;
            .form-row-button {
                margin: 24px 0 0 0;
                button {
                    padding: 14px 50px 15px;
                }
            }
        }
    }
    .checkout-billing,
    .spc-express-checkout {
        .payment-option-section {
            .tab-section {
                .right-content {
                    .error-msg-content {
                        .error {
                            font-size: $base-font + 2;
                            @include WorkSansRegular($WorkSans);
                        }
                    }
                    .form-row {
                        label {
                            span.label-text {
                                letter-spacing: -0.6px;
                            }
                        }
                    } 
                }
            }
        }
    }
    .checkout-order-summary .form-row .button-fancy-large {
        @include GothamBold($Gotham);
        background: $SW-navy-blue;
        &.paypal {
            background-color: $white;
        }
        border-color: $SW-navy-blue;
        @include text-properties(2px, $white, $base-font + 2px);
    }
    .items-in-bag.saved-address-block .head-wrapper {
        legend {
            @include Gotham($Gotham);
            text-transform: uppercase;
            @include text-properties(1px, $SW-navy-blue, $base-font + 4px);
        }
        .address-actions {
            @include GothamBold($Gotham);
            @include text-properties(0.5px, $SW-navy-blue, $base-font - 1px);
            a {
                @include GothamBold($Gotham);
                @include text-properties(
                    0.5px,
                    $SW-navy-blue,
                    $base-font - 1px
                );
            }
        }
    }
}
.customer-signin-section {
    h2 {
        &.align-left {
            @include GothamBook($GothamBook);
            @include text-properties(0.5px, $SW-navy-blue, $base-font + 4px);
            padding: 0;
        }
    }
    .head-wrapper {
        padding-bottom: 14px;
        margin: 1px 0 15px;
        @media screen and (max-width: 767px) {
            margin: 11px 0 10px;
            padding-bottom: 13px;
        }
    }
    .edit-link {
        line-height: 24px;
    }
    .checkout-guest {
        line-height: 18px;
    }
    .checkout-guest,
    .welcome-check,
    .edit-link {
        @include Gotham($Gotham);
        @include text-properties(1px, $SW-navy-blue, $base-font + 1px);
    }
}
.address-validation-dialog {
    button {
        &.corrected-avs-button {
            color: white;
            margin: 0;
            padding: 13px 10px;
            @media screen and (max-width: 767px) {
                padding: 14px 19px;
            }
        }
    }
    .update-address-button {
        margin-left: 2%;
        @media screen and (max-width: 767px) {
            margin-left: 0;
        }
    }
    .address-controls {
        button {
            font-family: $HelveticaBold;
            font-size: $base-font + 2px;
            &#cancel-avs-button {
                color: $black;
            }
            &.corrected-avs-button {
                background: none;
                border: none;
                margin: 18px 19px;
                padding: 0;
                letter-spacing: 0.2px;
            }
        }
    }
}
.pobox-exclusions {
    button {
        font-family: $HelveticaBold;
        font-size: $base-font + 2px;
        &.cancel-ship-method-except {
            color: $black;
            font-size: $base-font + 2px;
        }
    }
    .pobox-items {
        .attribute {
            .price-promotion {
                .price-sales {
                    color: $RedRibbon;
                }
            }
        }
    }
}

 /* Later we will move this code into asset */
.chk-out-header-right-section {
    @include Gotham($Gotham);
    letter-spacing: 1px;
    font-size: $base-font;
    color: $SW-gray;
    * {
        color: $SW-gray;
    }
    @media screen and (max-width: 1023px) {
        color: $SW-gray;
        * {
            color: $SW-gray;
        }
    }
    .live-chat {
        a {
            color: $SW-gray;
            div {
                color: $SW-gray;
            }
        }
    }
}
.checkout-progress-indicator {
    .progress-text,
    .progress-count {
        @include Gotham($Gotham);
        letter-spacing: 1px;
    }
}
//login content styling
.spc-login {
    h2 {
        font-size: $base-font + 8px;
        font-family: $Helvetica;
        letter-spacing: 0;
        &.heading {
            padding-bottom: 14px;
            margin-bottom: 19px;
        }
    }
    .checkout-tab.login {
        & > .checkout-tab-head {
            h2,
            h1 {
                text-transform: uppercase;
                @include Gotham($Gotham);
                @include text-properties(1px, $SW-navy-blue, $base-font + 4px);
                &.heading {
                    font-size: $base-font + 6px;
                    text-transform: capitalize;
                }
                span {
                    @include Gotham($Gotham);
                    @include text-properties(
                        1px,
                        $SW-navy-blue,
                        $base-font + 4px
                    );
                }
                .intl-shipping-btn span {
                    @include Gotham($Gotham);
                    @include text-properties(1px, $SW-gray, $base-font - 1px);
                }
            }
        }
    }
    .login-data {
        .checkout-login-email {
            @include GothamBook($GothamBook);
            @include text-properties(1px, $SW-navy-blue, $base-font);
        }
        .checkout-login-brands {
            margin-top: 5px;
            @include GothamBook($GothamBook);
            @include text-properties(1px, $SW-navy-blue, $base-font);
        }
    }
    button:not(.intl-shipping-btn) {
        @include GothamBold($Gotham);
        letter-spacing: 2px;
        font-size: $base-font + 2px;
        background: $SW-navy-blue;
        border-color: $SW-navy-blue;
    }
    .make-label-absolute {
        .form-row {
            &.login-rememberme {
                margin: 7.3% 0 0;
                label {
                    position: static;
                    -webkit-transform: none;
                    -ms-transform: none;
                    transform: none;
                    padding: 0 0 0 11px;
                    span {
                        @include GothamBook($GothamBook);
                        @include text-properties(0.5px, $empress, $base-font);
                        -webkit-transition: none;
                        -moz-transition: none;
                        -o-transition: none;
                        -ms-transition: none;
                        position: static;
                        padding: 0;
                        -webkit-transform: none;
                        -ms-transform: none;
                        transform: none;
                        margin: 0;
                    }
                }
            }
        }
    }
}
.checkoutlogin {
    .col-1 {
        padding: 36px 63px 39px 63px;
        background: $SW-light-gray;
        h2 {
            &.heading {
                text-transform: none;
                padding-bottom: 14px;
                margin: 0 0 20px;
                @include Gotham($Gotham);
                @include text-properties(1px, $SW-navy-blue, $base-font + 5px);
            }
        }
        .form-row {
            margin-bottom: 20px;
        }
        button {
            margin-top: 2%;
            padding: 14px 48px;
        }
        #password-reset {
            margin: 13px 2px 14px;
            @include GothamBold($Gotham);
            @include text-properties(0px, $SW-navy-blue, $base-font);
        }
    }
    .col-2 {
        padding: 24px 63px 49px 61px;
        background: $SW-light-gray;
        .form-row {
            margin: 0 0 19px 0;
        }
        .formbuttonrow {
            margin: 11% 0 3.1%;
        }
        h2.heading {
            margin-bottom: 14px;
            @include Gotham($Gotham);
            @include text-properties(1px, $SW-navy-blue, $base-font + 5px);
        }
        button {
            padding: 15px 45px;
        }
        .login-box {
            p {
                line-height: 21px;
                margin: 2px 0 14px 0;
                display: inline-block;
                @include GothamBook($GothamBook);
                @include text-properties(0.5px, $empress, $base-font);
            }
        }
        .form-row {
            &.newsletter-signup {
                label {
                    -webkit-transform: none;
                    -ms-transform: none;
                    transform: none;
                    margin: 0 0 0 11px;
                    width: 87%;
                    span {
                        @include GothamBook($GothamBook);
                        @include text-properties(0.5px, $empress, $base-font);
                    }
                }
            }
        }
    }
}
.saved-address-block {
    .shipping-address-save {
        color: $black;
    }
}
.items-in-bag {
    &.saved-address-block {
        .head-wrapper {
            margin: 57px 0 11px;
        }
    }
}
//save, cancel, edit buttons
.address-actions,
.newcreditcard {
    @include Gotham($Gotham);
    @include text-properties(1px, $SW-navy-blue, $base-font + 1px);
    span,
    a {
        @include Gotham($Gotham);
        @include text-properties(1px, $SW-navy-blue, $base-font + 1px);
    }
}
.address-actions {
    margin-top: 9px;
}
.checkout-order-summary {
    .review-disclaimer {
        @include GothamBook($GothamBook);
        @include text-properties(0px, $SW-gray, $base-font - 1px);
        line-height: 18px;
    }
    .form-row {
        margin: 13px 0 19px;
        .button-fancy-large {
            background: $black;
            font-size: $base-font + 2px;
            font-family: $HelveticaMedium;
        }
    }
    .secure-message {
        @include GothamBook($GothamBook);
        @include text-properties(0px, $SW-gray, $base-font);
    }
    .tax-disclaimer {
        @include GothamBook($GothamBook);
        @include text-properties(0px, $SW-gray, $base-font - 2px);
    }
}
.checkout-order-totals {
    padding: 20px 20px 25px 23px;
    .summary-heading {
        text-transform: capitalize;
        @include Gotham($Gotham);
        @include text-properties(1px, $SW-navy-blue, $base-font + 8px);
    }
    .order-detail {
        padding: 0 0 12px 0;
        .label,
        .value {
            @include Gotham($Gotham);
            @include text-properties(0.5px, $SW-navy-blue, $base-font + 3px);
            text-transform: capitalize;
        }
        .label {
            .shippingoverlay-link {
				background: url(../../../images/tips.svg) no-repeat;
                width: 16px;
                height: 16px;
                background-size: cover;
                vertical-align: bottom;
            }
        }
        .value {
            box-sizing: border-box;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
        }
        &.order-total {
            padding: 18px 0 10px 0;
            margin-top: 9px;
            .label,
            .value {
                @include GothamBold($Gotham);
                @include text-properties(
                    0.5px,
                    $SW-navy-blue,
                    $base-font + 6px
                );
            }
            &.order-total-remaining {
                padding: 0 0 12px 0;
                margin-top: 0;
                .label {
                    @include Gotham($Gotham);
                    @include text-properties(
                        0.5px,
                        $SW-navy-blue,
                        $base-font + 2px
                    );
                    text-transform: capitalize;
                }
                .value {
                    @include Gotham($Gotham);
                    @include text-properties(
                        0.5px,
                        $SW-navy-blue,
                        $base-font + 2px
                    );
                    padding-right: 2px;
                    box-sizing: border-box;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                }
            }
        }
        &.order-discount {
            &.discount {
                .value,
                .label {
                    @include Gotham($Gotham);
                    @include text-properties(
                        0.5px,
                        $SW-green,
                        $base-font + 3px
                    );
                }
            }
        }
        &.remaining-total {
            padding: 18px 0 10px 0;
            margin-top: 9px;
            .label,
            .value {
                @include Gotham($Gotham);
                @include text-properties(
                    0.5px,
                    $SW-navy-blue,
                    $base-font + 6px
                );
            }
            .value {
                font-family: $HelveticaBold;
            }
        }
        &.discount {
            .value,
            .label {
                @include Gotham($Gotham);
                @include text-properties(0.5px, $SW-green, $base-font + 3px);
            }
        }
    }
}
.spc-billing {
    .customer-signin-section {
        padding: 25px 31px 28px;
        margin-top: 29px;
    }
    .checkout-tab-head {
        .section-heading {
            margin: 35px 0 15px 0;
        }
        .details {
            .inner-block {
                .address {
                    padding: 16px 0 0 4px;
                    line-height: 22px;
                    .default {
                        padding: 0 0 3px 0;
                    }
                }
                .info {
                    &.giftmessage {
                        padding-top: 9px;
                    }
                }
            }
        }
    }
    .checkout-tab {
        &.billing {
            .checkout-tab-head {
                .section-heading {
                    margin: 42px 0 17px 0;
                }
                .saved-address-block {
                    .head-wrapper {
                        margin: 36px 0 0 0;
                    }
                }
            }
            .saved-address-block {
                .head-wrapper {
                    margin: 35px 0 0 0;
                }
                .saved-addr-dropdown-section {
                    .form-row-button {
                        margin: 0;
                    }
                }
                .saved-address-count {
                    margin-top: 14px;
                }
            }
        }
    }
    .billing-cards-section {
        .payment-method-options {
            .form-row {
                label {
                    @include Gotham($Gotham);
                    @include text-properties(1px, $SW-navy-blue, $base-font);
                    line-height: 14px;
                }
                &.label-inline {
                    margin-left: 4px;
                }
                .required-field {
                    @include GothamBook($GothamBook);
                    @include text-properties(0.5px, $promo-red, $base-font);
                }
            }
        }
        .form-row-button {
            margin: 0 0 14px 0;
        }
        .payment-method {
            padding-top: 1px;
            .billingfields {
                .enter-card-detials-wrapper {
                    .left-cont {
                        width: 50%;
                        .form-row {
                            &.month {
                                @media screen and (min-width: 768px) and (max-width: 1024px) {
                                    width: 48%;
                                }
                            }
                            .custom-select {
                                .selected-option {
                                    &:before {
                                        right: 2px;
                                    }
                                }
                                select {
                                    background-position: right 5px center;
                                    @media screen and (max-width: 767px) {
                                        background-position: right 13px center;
                                    }
                                }
                            }
                        }
                    }
                    .right-cont {
                        max-width: 302px;
                        width: 46%;
                        .payment-card-section {
                            .heading {
                                line-height: 10px;
                                @include GothamBook($GothamBook);
                                @include text-properties(
                                    1px,
                                    $SW-navy-blue,
                                    $base-font + 1px
                                );
                            }
                        }
                        .billing-payment-description {
                            .brand-cards span {
                                @include GothamBook($GothamBook);
                                @include text-properties(
                                    1px,
                                    $SW-navy-blue,
                                    $base-font + 1px
                                );
                            }
                            .payment-cards {
                                padding: 18px 0;
                                img {
                                    padding-right: 11px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.checkout-billing {
    .form-row-button {
        &.billing-page-continue {
            button:not(.btn-ds) {
                padding: 14px 50px 15px;
            }
        }
    }
}
@media screen and (min-width: 1024px) {
    .pt_checkout {
        .items-in-bag {
            &.saved-address-block {
                .head-wrapper {
                    margin: 26px 0 11px;
                    .address-actions {
                        margin-right: 28px;
                        margin-top: 5px;
                    }
                }
            }
        }
    }
    .spc-summary {
        .checkout-tab-head {
            .customer-signin-section {
                padding: 26px 33px 27px;
                margin-top: 27px;
            }
        }
        .checkout-tab {
            &.shipping {
                .checkout-tab-head {
                    .section-heading {
                        margin: 36px 0 15px 0;
                        .edit-link {
                            padding-right: 28px;
                            line-height: 31px;
                        }
                    }
                    .details {
                        padding: 35px 5.2% 70px 7.4%;
                        .inner-block {
                            h3 {
                                padding: 0 0 15px 0;
                            }
                        }
                    }
                }
            }
            &.billing {
                .checkout-tab-head {
                    .section-heading {
                        margin: 36px 0 22px 0;
                        .edit-link {
                            padding-right: 28px;
                            line-height: 30px;
                        }
                    }
                    .details {
                        padding: 37px 5.2% 71px 7.3%;
                        .billing-left-section {
                            .mini-payment-instrument {
                                margin-top: 15.6%;
                                .detail {
                                    span {
                                        &.img {
                                            margin-top: 10px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &.review {
                .checkout-tab-head {
                    .section-heading {
                        margin: 35px 0 14px 0;
                    }
                }
                .place-order {
                    .order-summary-footer {
                        padding: 20px 0 22px 27px;
                        .left-section {
                            .review-disclaimer {
                                line-height: 18px;
                            }
                            .submit-order {
                                .form-row {
                                    .button-fancy-large {
                                        margin-top: 10px;
                                        padding-top: 15px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.spc-summary,
.spc-billing,
.spc-express-checkout {
    .checkout-tab-head {
        .section-heading {
            .edit-link {
                @include Gotham($Gotham);
                @include text-properties(1px, $SW-navy-blue, $base-font + 1px);
            }
        }
        .details {
            .inner-block {
                @include Gotham($Gotham);
                h3 {
                    @include GothamBook($GothamBook);
                    @include text-properties(
                        0.5px,
                        $SW-navy-blue,
                        $base-font + 4px
                    );
                }
                .address {
                    @include Gotham($Gotham);
                    @include text-properties(
                        1px,
                        $SW-navy-blue,
                        $base-font + 1px
                    );
                    .default {
                        @include Gotham($Gotham);
                        @include text-properties(
                            1px,
                            $SW-navy-blue,
                            $base-font + 1px
                        );
                    }
                }
                .minishipments-method {
                    @include Gotham($Gotham);
                    @include text-properties(
                        1px,
                        $SW-navy-blue,
                        $base-font + 1px
                    );
                    .option-heading,
                    .promo {
                        @include Gotham($Gotham);
                        @include text-properties(
                            1px,
                            $SW-navy-blue,
                            $base-font + 1px
                        );
                    }
                }
                .info {
                    @include Gotham($Gotham);
                    @include text-properties(
                        1px,
                        $SW-navy-blue,
                        $base-font + 1px
                    );
                    .label {
                        @include Gotham($Gotham);
                        @include text-properties(
                            1px,
                            $SW-navy-blue,
                            $base-font + 1px
                        );
                    }
                }
            }
        }
    }
}
.billing-left-section,
.billing-right-section {
    .mini-billing-address,
    .mini-payment-instrument,
    .reward-certificate,
    .promo-code,
    .gift-card {
        @include Gotham($Gotham);
        @include text-properties(1px, $SW-navy-blue, $base-font + 1px);
        h3 {
            @include GothamBook($GothamBook);
            @include text-properties(0.5px, $SW-navy-blue, $base-font + 4px);
        }
        .detail {
            font-size: $base-font + 1px;
            .cc-owner {
                @include Gotham($Gotham);
            }
        }
    }
}
.place-order {
    .order-summary-footer {
        .left-section,
        .right-section {
            font-family: $Helvetica;
            .submit-order {
                .form-row {
                    .button-fancy-large {
                        @include GothamBold($Gotham);
                        @include text-properties(2px, $white, $base-font + 2px);
                        @media screen and (max-width: 1024px) {
                            width: 270px;
                        }
                        @media screen and (max-width: 767px) {
                            width: 100%;
                            margin: 0;
                            padding: 14.5px 30px;
                        }
                    }
                }
            }
            .secure-message {
                @include GothamBook($GothamBook);
                @include text-properties(0px, $SW-gray, $base-font);
            }
            .tax-disclaimer {
                @include GothamBook($GothamBook);
                @include text-properties(0px, $SW-gray, $base-font - 2px);
            }
            .review-disclaimer {
                @include GothamBook($GothamBook);
                @include text-properties(0px, $SW-gray, $base-font - 1px);
            }
        }
        .left-section {
            fieldset {
                .form-row {
                    button {
                        color: $white;
                        font-family: inherit;
                    }
                }
            }
        }
    }
}
.billing-cards-section {
    .checkout-rebuttal {
        .acquisition-rebuttal {
            color: $black;
        }
    }
    .card-count span {
        @include Gotham($Gotham);
        @include text-properties(1px, $SW-navy-blue, $base-font + 1px);
    }
}
@media screen and (max-width: 1024px) {
    .checkoutlogin {
        .col-2 {
            padding: 7px 20px 31px;
            .login-box {
                p {
                    font-size: $base-font + 1px;
                }
            }
        }
    }
}
@media screen and (max-width: 1023px) {
    .pt_checkout {
        .qa-section {
            .qa-content {
                padding: 25px 17px 20px 30px;
                &:nth-child(2n),
                &:nth-child(3n),
                &:nth-child(4n),
                &:nth-child(n) {
                    padding: 25px 17px 20px 30px;
                }
            }
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .billing-cards-section {
        .payment-method {
            .billingfields {
                .cvn {
                    width: 50%;
                }
                .enter-card-detials-wrapper {
                    .left-cont {
                        .cvn {
                            .field-wrapper {
                                width: 92%;
                            }
                        }
                    }
                }
            }
        }
    }
    .checkoutlogin {
        .col-1 {
            padding: 30px 20px;
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .pt_checkout {
        .cart-row {
            .column {
                &.col-2 {
                    .custom-cart-qty {
                        margin-top: 47%;
                    }
                }
                &.col-3 {
                    width: 22.8%;
                }
            }
        }
        #secondary {
            &.nav {
                margin: 0 10px;
            }
            .checkout-order-totals {
                padding: 20px 20px 33px 19px;
                .summary-heading {
                    font-size: 20px;
                    margin: 0 0 18px;
                }
                .order-detail {
                    &.order-total {
                        padding: 25px 0 10px 0;
                        margin-top: 12px;
                        .label,
                        .value {
                            font-size: 18px;
                        }
                        &.order-total-remaining {
                            padding: 0 0 12px 0;
                            margin-top: 0;
                            .label,
                            .value {
                                font-size: $base-font + 2px;
                            }
                        }
                    }
                    &.remaining-total {
                        padding: 25px 0 10px 0;
                        margin-top: 12px;
                        .label,
                        .value {
                            font-size: $base-font + 12px;
                        }
                    }
                }
            }
        }
    }
    .checkoutlogin {
        .col-2 {
            padding: 24px 41px 49px 39px;
        }
        .col-1 {
            padding: 36px 41px 39px 39px;
            .spc-login-btn {
                margin: 6px 0 9px;
            }
            #password-reset {
                text-align: right;
                margin: 4px 0px 14px;
                width: 100%;
            }
        }
        .col-seperator {
            display: block;
        }
    }
    .address-actions {
        margin-right: 0px;
    }
    .billing-cards-section {
        .payment-method {
            .billingfields {
                .cvn {
                    width: 46%;
                }
                .enter-card-detials-wrapper {
                    .left-cont {
                        .cvn {
                            .field-wrapper {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
    .checkout-tabs {
        &.spc-shipping {
            .customer-signin-section {
                margin-top: 28px;
            }
            .checkout-tab {
                &.shipping {
                    .checkout-tab-head {
                        h2 {
                            margin-top: 28px;
                        }
                    }
                }
            }
            .block-section {
                &.shipping-section {
                    legend {
                        padding-top: 25px;
                    }
                    .form-row {
                        &.even {
                            width: 49%;
                        }
                    }
                }
            }
        }
    }
    .spc-billing {
        .billing-cards-section {
            .payment-method {
                .billingfields {
                    .enter-card-detials-wrapper {
                        .left-cont {
                            width: 44%;
                        }
                    }
                }
            }
        }
    }
    .spc-summary {
        .checkout-tab {
            &.review {
                .place-order {
                    .order-summary-footer {
                        .left-section {
                            .submit-order {
                                .form-row {
                                    .button-fancy-large {
                                        padding-top: 15px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .pt_checkout {
        .gift-section {
            .gift-right {
                .form-row-button {
                    button {
                        padding: 15px 30px 14px;
                    }
                }
            }
        }
        .spc-login {
            &.checkout-tabs {
                .checkout-tab {
                    &.login {
                        .checkout-tab-head {
                            h2,
                            h1 {
                                padding-bottom: 25px;
                            }
                        }
                        .checkout-login-email {
                            line-height: 18px;
                        }
                        .checkout-login-brands {
                            margin-top: 9px;
                            line-height: 18px;
                        }
                    }
                    &.shipping,
                    &.billing,
                    &.summary {
                        .checkout-tab-head {
                            h2 {
                                padding-bottom: 22px;
                                margin-bottom: 19px;
                            }
                        }
                    }
                    .checkoutlogin {
                        padding: 24px 0 24px;
                        .col-1 {
                            padding: 24px 10px 30px;
                            h2 {
                                &.heading {
                                    padding-bottom: 16px;
                                }
                            }
                        }
                    }
                }
            }
            .form-row {
                .field-wrapper {
                    input[type="text"] {
                        padding: 19px 15px 13px 15px;
                    }
                }
                &.password {
                    .field-wrapper {
                        input[type="text"] {
                            padding: 20px 15px 14px 15px;
                        }
                    }
                }
            }
            .login-rememberme {
                margin-top: 0;
            }
            .col-1 {
                #password-reset {
                    margin: 19px 11px 7px 0px;
                }
                .spc-login-btn {
                    padding: 14px 51px 15px;
                    margin: 10px 0 0 0;
                }
                button {
                    &.oAuthIcon {
                        margin-top: 15px;
                    }
                }
            }
        }
        .items-in-bag {
            &.saved-address-block {
                .head-wrapper {
                    margin: 11px 0 18px;
                    .address-actions {
                        float: right;
                        width: auto;
                    }
                }
            }
        }
        .saved-address-block {
            .address-actions {
                .edit-section {
                    .seperator {
                        display: inline-block;
                    }
                }
            }
        }
        .cart-columns {
            padding-top: 26px;
        }
        .cart-row {
            .column {
                &.col-1 {
                    .item-details {
                        .name {
                            margin: 0 0 15px;
                        }
                        .attribute {
                            margin-bottom: 8px;
                        }
                        .sku {
                            margin: 0 0 14px 0;
                        }
                    }
                }
            }
            .columns-combine {
                .column {
                    &.col-2 {
                        text-align: left;
                        margin: 6px 0;
                    }
                    &.col-3 {
                        width: 100%;
                        max-width: 100%;
                        .item-total {
                            margin: 10px 0 16px 0;
                        }
                    }
                }
            }
        }
        .checkout-order-totals {
            padding: 18px 10px 18px 10px;
            .summary-heading {
                margin: 0 0 18px;
            }
            div {
                &.order-detail {
                    padding: 0 0 14px 0;
                    &.order-total {
                        margin-top: 2px;
                        padding-top: 22px;
                        &.order-total-remaining {
                            padding: 0 0 14px 0;
                        }
                    }
                    &.remaining-total {
                        margin-top: 2px;
                        padding-top: 22px;
                    }
                }
            }
        }
        .shipping-section {
            .form-row {
                .field-wrapper {
                    width: 3%;
                    input[type="radio"] {
                        &:checked {
                            + .custom-radio-icon {
                                top: 0;
                                left: 0;
                            }
                        }
                    }
                    .custom-radio-icon {
                        top: 0;
                        left: 0;
                    }
                }
                .field-desc {
                    width: 94%;
                }
            }
        }
        .form-row {
            .field-wrapper {
                input[type="text"] {
                    font-size: 14px;
                }
            }
        }
        .qa-section {
            .question-title {
                padding: 25px 0;
            }
            .qa-content {
                padding: 21px;
                &:nth-child(2n),
                &:nth-child(3n),
                &:nth-child(4n),
                &:nth-child(n) {
                    padding: 21px;
                }
            }
        }
    }
    .checkout-order-totals {
        .summary-heading {
            font-size: 20px;
        }
    }
    .checkoutlogin {
        .col-2 {
            padding: 25px 10px 7px;
            .login-box {
                p {
                    margin: 0px 0 15px 0;
                }
            }
            .form-row {
                margin: 0 0 21px 0;
            }
        }
    }
    .spc-billing {
        .checkout-tab.billing {
            .saved-address-block {
                .head-wrapper {
                    margin: 17px 0 0;
                }
            }
        }
        .billing-cards-section {
            .payment-method {
                .billingfields {
                    .enter-card-detials-wrapper {
                        .left-cont {
                            width: 100%;
                        }
                    }
                }
            }
        }
        .billing-cards-section {
            .payment-method {
                .billingfields {
                    .enter-card-detials-wrapper {
                        .right-cont {
                            width: 100%;
                            max-width: 100%;
                        }
                    }
                }
            }
        }
        .customer-signin-section {
            padding: 15px 15px 20px;
            margin-top: 20px;
        }
    }
    .checkout-billing {
        .form-row-button {
            &.billing-page-continue {
				button:not(.btn-ds) {
                    padding: 14.5px 20px;
                }
            }
        }
    }
}
@media screen and (max-width: 480px) {
    .pt_checkout {
        .shipping-section {
            .form-row {
                .field-wrapper {
                    width: 5%;
                }
                .field-desc {
                    width: 90%;
                }
            }
        }
    }
}
@media screen and (min-width: 1260px) {
    .pt_checkout {
        .gift-section {
            .gift-message-text {
                .field-wrapper {
                    width: 91%;
                }
            }
        }
    }
}
@media screen and (max-width: 1023px) {
    .checkout-order-summary {
        .form-row {
            width: auto;
            float: none;
            .button-fancy-large {
                float: none;
            }
        }
    }
}
#secondary {
    .checkout-order-summary {
        .order-summary-footer {
            .form-row {
                .add-to-email-list {
                    .add-to-emaillist-warningmsg {
                        .arrow-up {
                            @media screen and(min-width:768px) and (max-width: 1024px) {
                                left: 35px;
                            }
                            @media screen and(width:1024px) and (orientation: landscape) {
                                left: 0px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.defer-code-error {
    .error {
        font-size: $base-font + 2;
        @include WorkSansRegular($WorkSans);
        color: $medium-carmine;
    }
}
.add-to-emaillist-warningmsg {
    @include Gotham($Gotham);
    @include text-properties(0px, $white, $base-font - 2px);
    background: $SW-navy-blue;
    .arrow-up {
        border-bottom-color: $SW-navy-blue;
    }
}
.spc-summary
    .place-order
    .left-section
    .form-row
    .add-to-email-list
    div.content-asset,
.spc-express-checkout
    .place-order
    .left-section
    .form-row
    .add-to-email-list
    div.content-asset,
#secondary
    .checkout-order-summary
    .order-summary-footer
    .form-row
    .add-to-email-list {
    .add-to-emaillist-warningmsg {
        background: $SW-navy-blue;
        .arrow-up {
            border-bottom-color: $SW-navy-blue;
        }
    }
}
.spc-summary,
.spc-billing,
.spc-express-checkout {
    .checkout-tab-head {
        .details {
            background: $SW-light-gray;
        }
        @media screen and (max-width: 767px) {
            .section-heading a.edit-link {
                font-size: 13px;
            }
        }
    }
}
.billing-cards-section .payment-method {
    .billingfields .enter-card-detials-wrapper {
        .card-actions .checkboxes span {
            @include GothamBook($GothamBook);
            @include text-properties(0.5px, $empress, $base-font);
        }
    }
}
.pt_checkout {
    .gift-section .form-row.form-indent label span,
    .address-section.make-label-absolute
        .form-row.makeDefaultAddress.label-inline
        label
        span,
    .address-section.make-label-absolute
        .form-row.useAsBillingAddress.label-inline
        label
        span,
    .address-section.make-label-absolute
        .form-row.addToAddressBook.label-inline
        label
        span,
    .address-section.make-label-absolute
        .form-row.makeDefault.label-inline
        label
        span {
        @include GothamBook($GothamBook);
        @include text-properties(0.5px, $empress, $base-font);
    }
}
.billing-cards-section {
    .payment-method-options {
        .form-row {
            label {
                @include Gotham($Gotham);
                @include text-properties(1px, $SW-navy-blue, $base-font);
                line-height: 14px;
            }
            &.label-inline {
                margin-left: 4px;
            }
            .required-field {
                @include GothamBook($GothamBook);
                @include text-properties(0.5px, $promo-red, $base-font);
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .pt_checkout .form-row div.field-wrapper input[type="text"] {
        font-size: 12px;
    }
}
.checkout-body {
    .cart-row {
        &.sps-checkout {
            .cart-columns {
                .col-1 {
                    .item-details {
                        .attribute {
                            .label {
                                text-transform: capitalize;
                                @include GothamBold($Gotham);
                                @include text-properties(
                                    1px,
                                    $black,
                                    $base-font - 1px
                                );
                            }
                            .value {
                                @include GothamLight($Gotham);
                                text-transform: capitalize;
                                @include text-properties(
                                    1px,
                                    $black,
                                    $base-font
                                );
                            }
                        }
                        .product-availability-list {
                            span:not(.label) {
                                @include Gotham($Gotham);
                                @include text-properties(
                                    1px,
                                    $black,
                                    $base-font
                                );
                                text-transform: capitalize;
                            }
                            span.is-in-stock {
                                @include Gotham($Gotham);
                                @include text-properties(
                                    1px,
                                    $black,
                                    $base-font
                                );
                                text-transform: capitalize;
                            }
                        }
                        .product-list-item {
                            .product-brand {
                                @include Gotham($Gotham);
                                @include text-properties(
                                    0.2px,
                                    $brand-name-color,
                                    $base-font - 2px
                                );
                            }
                            .name {
                                @include Gotham($Gotham);
                                @include text-properties(
                                    1px,
                                    $SW-navy-blue,
                                    $base-font
                                );
                                text-transform: uppercase;
                                a {
                                    @include text-properties(
                                        1px,
                                        $SW-navy-blue,
                                        $base-font
                                    );
                                }
                            }
                            .sku {
                                @include Gotham($Gotham);
                                @include text-properties(
                                    0.5px,
                                    $SW-gray,
                                    $base-font - 2px
                                );
                                span,
                                div {
                                    @include text-properties(
                                        0.5px,
                                        $SW-gray,
                                        $base-font - 2px
                                    );
                                }
                            }
                            .variant-availability-group {
                                .swsps-top-bottom-label {
                                    text-transform: capitalize;
                                    @include GothamBold($Gotham);
                                    @include text-properties(
                                        1px,
                                        $black,
                                        $base-font - 1px
                                    );
                                }
                                .swsps-top-bottom-val {
                                    @include Gotham($Gotham);
                                    text-transform: capitalize;
                                    @include text-properties(
                                        1px,
                                        $black,
                                        $base-font
                                    );
                                }
                            }
                        }
                        .cart-unit-price {
                            .price-promotion {
                                .price-standard,
                                .price-sales,
                                span {
                                    @include GothamBold($Gotham);
                                    text-transform: capitalize;
                                    @include text-properties(
                                        1px,
                                        $SW-navy-blue,
                                        $base-font
                                    );
                                }
                                .price-standard {
                                    @include text-properties(
                                        1px,
                                        $SW-gray,
                                        $base-font
                                    );
                                }
                                .price-sales {
                                    color: $medium-carmine;
                                }
                            }
                        }
                    }
                }
                .specialmessag {
                    .specialmessaging {
                        line-height: normal;
                        margin-left: 0;
                        letter-spacing: 0.55px;
                        color: $black;
                        p {
                            @include Gotham($Gotham);
                            @include text-properties(
                                0.5px,
                                $SW-gray,
                                $base-font - 1px
                            );
                            &.finalsalemessage {
                                color: #af3434;
                                text-transform: uppercase;
                                letter-spacing: 0.5px;
                                font-size: 11px;
                                line-height: normal;
                                @include Gotham($Gotham);
                            }
                        }
                    }
                }
                .item-quantity {
                    .custom-cart-qty {
                        .value {
                            @include Gotham($Gotham);
                            @include text-properties(
                                1px,
                                $SW-navy-blue,
                                $base-font
                            );
                        }
                    }
                }
                .col-3 {
                    .item-total {
                        .sub-total {
                            @include GothamBold($Gotham);
                            @include text-properties(
                                1px,
                                $SW-navy-blue,
                                $base-font - 1px
                            );
                        }
                        .price-total,
                        .price-unadjusted,
                        .price-sales {
                            @include Gotham($Gotham);
                            font-size: 14px;
                            letter-spacing: 0.5px;
                            @media screen and (max-width: 767px) {
                                @include Gotham($Gotham);
                                font-size: 14px;
                                letter-spacing: 0.5px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.address-validation-dialog {
    .addr-dialog-heading {
        @include GothamBook($GothamBook);
        @include text-properties(inherit, $SW-navy-blue, $base-font + 1px);
        .bold {
            @include GothamBook($GothamBook);
            @include text-properties(inherit, $SW-navy-blue, $base-font + 1px);
        }
    }
    .addr-dialog-sub-heading {
        @include Gotham($Gotham);
        @include text-properties(inherit, $SW-navy-blue, $base-font);
        text-transform: uppercase;
    }
    .suggested-address-text,
    .current-shipping-address,
    .or-text {
        @include GothamBook($GothamBook);
        @include text-properties(inherit, $SW-navy-blue, $base-font + 1px);
    }
    .address-controls button {
        @include GothamBold($GothamBold);
        &#cancel-avs-button {
            @include text-properties(inherit, $SW-navy-blue, $base-font + 2px);
        }
        &#selected-address-continue {
            @include text-properties(inherit, $white, $base-font + 2px);
            background: $SW-navy-blue;
            border-color: $SW-navy-blue;
        }
    }
    .addresses-section {
        .current-addr-heading {
            @include Gotham($Gotham);
            @include text-properties(inherit, $SW-navy-blue, $base-font);
            text-transform: uppercase;
        }
        .current-address .fields-value {
            @include GothamBook($GothamBook);
            @include text-properties(inherit, $SW-navy-blue, $base-font + 1px);
        }
        button {
            @include GothamBold($GothamBold);
            &.corrected-avs-button {
                @include text-properties(
                    inherit,
                    $white,
                    $base-font + 2px
                );
            }
            &.update-avs-button {
                @include text-properties(inherit, $white, $base-font + 2px);
                background: $SW-navy-blue;
                border-color: $SW-navy-blue;
            }
        }
    }
}
.saved-address-block .addresssection {
    @include Gotham($Gotham);
    @include text-properties(1px, $SW-navy-blue, $base-font + 1px);
    .address-nickname {
        @include Gotham($Gotham);
    }
}
.billing-left-section .mini-payment-instrument .detail {
    max-width: 217px;
}
.billing-right-section {
    .reward-certificate,
    .promo-code,
    .gift-card {
        .detail {
            @include Gotham($Gotham);
            @include text-properties(1px, $SW-navy-blue, $base-font + 1px);
            .payment-promo-code,
            .promo-callout-message {
                @include Gotham($Gotham);
                @include text-properties(1px, $SW-navy-blue, $base-font + 1px);
            }
        }
    }
}
.checkout-billing .payment-option-section .tab-section .right-content .rewardCode .field-wrapper .reward-code,
.checkout-billing .payment-option-section .tab-section .right-content .couponCode .field-wrapper .coupon-code{
	font-size: $base-font;
}