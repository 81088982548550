.only-for-bh-section {
    .bh-choose-item-below {
        font-size: $base-font + 4px;
        letter-spacing: 1px;
        @media screen and (min-width: 768px) and (max-width: 1023px) {
            font-size: $base-font + 6px;
            line-height: 1.11;
            color: $black;
        }
    }
}
.div-trending-section {
    .recommendations-heading {
        text-align: center;
        margin-bottom: 45px;
        width: 100%;
        display: inline-block;
        text-transform: capitalize;
        @include WorkSansMedium($WorkSans);
        font-size: $base-font + 12px;
    }
    #carousel-recommendations {
        width: 100%;
        display: inline-block;
        text-align: center;
        .product-tile {
            width: calc(20% - 4px);
            float: none;
            display: inline-block;
            vertical-align: top;
            box-sizing: border-box;
            .product-swatches {
                display: block;
            }
            .product-name {
                margin: 20px 0 11px 0 !important;
                font-size: $base-font + 1px;
                line-height: 20px;
            }
            .product-image {
                img {
                    width: 120px;
                    height: 173px;
                }
            }
            .p-image-rollover {
                display: none;
            }
        }
        button {
            &.slick-arrow {
                width: 17px;
                height: 23px;
                text-indent: -9999px;
                position: absolute;
                bottom: 0;
                margin: 0 auto;
                z-index: 9;
                transition: initial;
                padding: 0;
                border: 0;
                top: 50%;
            }
        }
    }
}
// PDP Recommandation styles
.pt_product-details {
    #main {
        .pdp-main {
            .last-visited-section {
                .last-visited-heading {
                    border: none;
                    text-align: center;
                    margin-bottom: 23px;
                    padding-bottom: 0;
                    @include GothamMedium($Gotham);
                    text-transform: uppercase !important;
                    color: $SW-navy-blue;
                    font-size: $base-font + 6px;
                    h2 {
                        color: $SW-navy-blue;
                    }
                }
                .product-tile {
                    text-align: center;
                    width: 100%;
                    .product-image {
                        margin-bottom: 10px;
                        &.product-brylane {
                            .thumb-link {
                                img.p-image {
                                    object-fit: cover;
                                    min-height: 265px;
                                    @media screen and (max-width: 767px) {
                                        min-height: 317px;
                                    }
                                    @media screen and (max-width: 360px) {
                                        min-height: 302px;
                                    }
                                    @media screen and (min-width: 768px) and (max-width: 1023px) {
                                        min-height: 275px;
                                    }
                                    @media screen and (min-width: 1024px) and (max-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
                                        min-height: 305px;
                                    }
                                }
                            }
                        }
                    }
                    .product-pricing {
                        @include GothamMedium($Gotham);
                        font-size: $base-font !important;
                        letter-spacing: 0.5px !important;
                    }
                    .product-name {
                        line-height: normal;
                        font-size: $base-font;
                        margin-bottom: 10px;
                        margin-top: 10px;
                    }
                    img.p-image {
                        display: inline-block;
                    }
                }
                .search-result-items {
                    max-width: 954px;
                    margin: 0 auto;
                    padding-right: 0;
                    .grid-tile {
                        padding: 0 10px 0 10px;
                        border: none;
                    }
                }
                button {
                    &.slick-arrow {
                        margin: 0 auto;
                    }
                }
                @media screen and (min-width: 1024px) and (max-width: 1300px) {
                    .search-result-items {
                        .grid-tile {
                            padding: 0 53px;
                        }
                    }
                }
                @media screen and (min-width: 768px) and (max-width: 1030px) {
                    button {
                        &.slick-arrow {
                            top: 38%;
                        }
                    }
                }
                @media screen and (max-width: 1023px) and (min-width: 768px) {
                    .search-result-items {
                        max-width: 93%;
                        .grid-tile {
                            padding-right: 25px;
                        }
                    }
                }
                @media screen and (max-width: 767px) {
                    .last-visited-heading {
                        text-transform: capitalize;
                    }
                    .search-result-items {
                        .grid-tile {
                            padding-right: 15px;
                            padding-left: 15px;
                            max-width: inherit;
                            float: none;
                        }
                    }
                }
            }
        }
        .standardproductset {
            .promotion {
                width: 100%;
                display: inline-block;
            }
            .product-price {
                margin-bottom: 10px;
            }
            .product-col-2 {
                .top-add-all {
                    &.product-add-to-cart {
                        border-bottom: none;
                    }
                }
                .product-set-item {
                    border-top: 1px solid $manatee;
                }
                .pdpForm {
                    .product-add-to-cart {
                        border-top: 1px solid $manatee;
                    }
                }
                .product-variations {
                    .attribute {
                        &:last-child {
                            border-bottom: 1px solid $manatee;
                        }
                    }
                }
                .product-hemmable {
                    border-top: none;
                }
                .product-monograming {
                    display: inline-block;
                    width: 100%;
                    border-bottom: 1px solid $manatee;
                }
            }
            .product-set {
                #thumbnails {
                    .thumb {
                        @media screen and (max-width: 1023px) {
                            width: 21%;
                        }
                        @media screen and (max-width: 767px) {
                            width: 25%;
                        }
                    }
                }
            }
            @media screen and (max-width: 1023px) {
                .product-thumbnails {
                    button {
                        &.slick-arrow {
                            &.slick-next {
                                display: none !important;
                            }
                            &.slick-prev {
                                display: none !important;
                            }
                        }
                    }
                    &.slick-arrow-bar {
                        &:before {
                            display: none !important;
                        }
                    }
                }
                .product-add-to-cart {
                    .wishlist {
                        float: left;
                        width: auto;
                        @media screen and (max-width: 767px) {
                            width: 100%;
                            margin: 0;
                        }
                    }
                }
            }
        }
        // Handling special product set page
        .specialproductset {
            .prod-detail-page {
                .info {
                    .product-bundle-main {
                        width: 100%;
                        @media screen and (max-width: 1023px) {
                            width: 100%;
                        }
                        .product-col-2 {
                            &.product-set {
                                padding: 0;
                                box-sizing: border-box;
                                @media screen and (max-width: 1023px) {
                                    padding: 0;
                                }
                            }
                        }
                    }
                    .recommendation {
                        width: 16.1%;
                        @media screen and (max-width: 1023px) {
                            width: 100%;
                        }
                    }
                }
            }
            .product-col-1 {
                &.product-bundle {
                    padding: 0;
                }
            }
            .product-set {
                border-bottom: 0;
            }
            .product-bundle {
                .product-col-2 {
                    .product-actions {
                        border: 0;
                    }
                }
            }
            .product-teasers {
                margin-top: 5px;
            }
            .product-name {
                margin: 16px 0 5px;
            }
            .product-set {
                .product-price {
                    margin-bottom: 12px;
                }
            }
            .product-set-list {
                border-top: 1px solid $manatee;
                margin-top: 24px;
                .product-set-item {
                    border-bottom: 6px solid $manatee;
                }
                .product-set-item:last-child {
                    border-bottom: 0;
                }
                .product-variations {
                    ul {
                        .attribute {
                            border-bottom: 1px solid $manatee;
                            padding: 0 0 17px 0;
                            &.size {
                                .attribute_label {
                                    margin: 24px 0 12px;
                                }
                                .swatches li a {
                                    margin: 3px 10px 7px 0px;
                                    @media screen and (max-width: 768px) {
                                        font-size: 11px;
                                    }
                                }
                            }
                        }
                        .attribute:last-child {
                            border-bottom: 0;
                        }
                    }
                }
                .promotion {
                    margin: 4px 0;
                    clear: both;
                }
            }
            .product-set-details .product-variations .attribute:nth-child(2n) {
                border: 0;
            }
            .product-add-to-cart {
                border-top: 1px solid $light-gray;
                .availability-web {
                    padding: 15px 0;
                }
                .shippingdetails {
                    margin: 29px 0 0;
                }
            }
            .share-icon-container {
                margin: 0 0;
            }
            .tabs {
                margin: 37px 0 0 0;
            }
        }
    }
}
//PDP page layouts settings
.pt_product-details {
    .b_product_badge {
        position: absolute;
        top: 0;
        left: 0;
    }
    @media screen and (min-width: 1025px) {
        #main {
            max-width: 100%;
            // Adding this class to div will adjust the content to center
            .adjust-inner-content {
                max-width: 1260px;
                margin: 0 auto;
                width: 100%;
            }
        }
    }
    @media screen and (max-width: 1023px) {
        #main {
            margin: 0;
            padding: 0 20px;
            .adjust-inner-content {
                margin: 0;
                padding: 0;
                width: 100%;
                box-sizing: border-box;
            }
            .primary-content {
                padding: 13px 0 0;
            }
            .product-col-1 {
                margin-right: 14px;
            }
            .product-name {
                line-height: 33px;
            }
            .product-add-to-cart {
                button {
                    &.all-set-button {
                        width: 100%;
                    }
                }
                .add-to-cart {
                    width: 75.2%;
                }
                .inventory {
                    width: 24.8%;
                }
                .wishlist {
                    float: none;
                    width: 100%;
                    padding: 20px 0 0 0;
                    a {
                        float: none;
                        display: inline-block;
                        vertical-align: middle;
                        line-height: 18px;
                    }
                }
                .shippingdetails {
                    margin: 17px 0 0;
                }
            }
            // Handling special product set page
            .specialproductset {
                .product-col-1 {
                    &.product-bundle {
                        max-width: none;
                        padding: 0;
                        margin: 0;
                    }
                }
                .product-bundle {
                    &.product-bundle-main {
                        .product-col-2 {
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1023px) {
        #main {
            .e-giftcard-detail {
                .product-add-to-cart {
                    button {
                        width: 100%;
                    }
                }
            }
            .pdp-main {
                .product-review {
                    display: block;
                }
            }
            .adjust-inner-content {
                padding: 0;
            }
            .product-col-1 {
                &.product-set {
                    width: 100%;
                    padding-right: 0;
                    margin-right: 0;
                    .product-primary-image {
                        width: 48.7%;
                        float: left;
                        .product-primary-image {
                            width: 100%;
                        }
                    }
                    .product-col-1-productdetail {
                        float: right;
                        width: 46.4%;
                        @media screen and (max-width: 767px) {
                            width: 100%;
                            float: none;
                            padding: 0 10px;
                        }
                    }
                }
            }
            .product-col-2 {
                &.product-set {
                    width: 100%;
                    margin-right: 0;
                    .product-add-to-cart {
                        button {
                            width: 218px;
                            &.all-set-button {
                                float: right;
                            }
                        }
                        .inventory {
                            width: 13.33%;
                        }
                    }
                }
            }
        }
    }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
        .header-promo-bottom {
            .promo-banner {
                margin: 15px auto 10px;
            }
        }
        #main {
            .product-col-2 {
                padding: 0;
                .product-name {
                    margin: 3px 0 22px -2px;
                }
                .product-price {
                    .price-standard {
                        padding-right: 20px;
                        margin-left: -2px;
                    }
                }
                .product-variations {
                    .attribute {
                        .attribute_label {
                            margin: 11px 0 11px;
                        }
                    }
                }
            }
            .product-add-to-cart {
                .inventory {
                    .custom-select {
                        select {
                            border-top-left-radius: 0;
                            border-bottom-left-radius: 0;
                            font-size: $base-font + 4px;
                            @include WorkSansRegular($WorkSans);
                            height: 52px;
                        }
                    }
                }
            }
            .share-icon-container {
                .share-icon-label {
                    letter-spacing: 2px;
                }
            }
            .product-actions {
                a {
                    &.share-icon {
                        padding: 0;
                        &.share-facebook {
                            padding: 0 26px;
                        }
                        &.share-twitter {
                            padding: 0 4px;
                        }
                        &.share-mail {
                            padding: 0 18px;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 767px) {
        #main {
            margin: 0 0;
            // Handling special product set page
            .specialproductset {
                .product-col-1 {
                    &.product-bundle {
                        max-width: none;
                        width: 100%;
                        padding: 0 0;
                        margin: 0 0;
                    }
                }
                .product-bundle {
                    &.product-bundle-main {
                        width: 100%;
                    }
                }
                .product-set-list {
                    .promotion {
                        padding: 0 0;
                    }
                    .product-set-details {
                        padding: 0 0;
                        .product-hemmable {
                            @media screen and (max-width: 768px) {
                                border-top: 1px solid #958faa;
                            }
                        }
                    }
                }
            }
            .standardproductset {
                .adjust-inner-content {
                    padding: 0;
                }
                .product-thumbnails {
                    &.hide-mobile {
                        display: block;
                        .thumb {
                            width: 100%;
                            position: relative;
                            a.thumbnail-link {
                                left: 0;
                            }
                        }
                        .slick-dots {
                            margin: 15px 20px 15px 0;
                            float: right;
                            li {
                                float: left;
                                margin-left: 10px;
                                button {
                                    text-indent: -99999px;
                                    border: 1px solid #cccccc;
                                    background: #cccccc;
                                    width: 10px;
                                    border-radius: 50%;
                                    padding: 0;
                                    height: 10px;
                                }
                                &.slick-active {
                                    button {
                                        border: 1px solid $black;
                                        background: $black;
                                    }
                                }
                            }
                        }
                    }
                }
                .product-col-1 {
                    &.product-set {
                        .product-primary-image {
                            width: 100%;
                        }
                    }
                }
                .product-col-2 {
                    &.product-set {
                        padding: 0 10px;
                        box-sizing: border-box;
                        .product-add-to-cart {
                            .inventory {
                                width: 80px;
                            }
                            button {
                                width: 220px;
                                &.all-set-button {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
            .adjust-inner-content {
                padding: 0 10px;
            }
            .product-col-wrapper {
                margin-top: 0;
            }
            .product-col-1 {
                width: 100%;
                margin-right: 0;
            }
            .product-col-2 {
                width: 100%;
                margin-left: 0;
                padding: 0;
            }
            .product-name {
                margin: 0 0 8px 0;
            }
        }
        .pdp-main {
            .product-set-details {
                .heading {
                    .title {
                        font-family: $WorkSans;
                        font-weight: 900;
                        font-size: 12px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 767px) {
        #main {
            .tabs {
                margin: 30px 0 0 0;
                .tab-label {
                    padding: 0;
                }
            }
            .promotion {
                letter-spacing: 0.5px;
            }
            .product-price {
                margin-bottom: 10px;
            }
        }
    }
    #main {
        .product-set-long-description {
            .read-more-content {
                .read-more {
                    color: $black;
                }
            }
        }
    }
}
#main {
    .standardproductset {
        .product-col-2 {
            .top-add-all {
                &.product-add-to-cart {
                    .shop-the-look {
                        @media screen and (max-width: 1023px) {
                            float: left;
                        }
                    }
                    .product-set-main-button-parent {
                        &.hide-tablet-landscape {
                            @media screen and (max-width: 1023px) {
                                display: none;
                            }
                            @media screen and (max-width: 1023px) {
                                display: block;
                            }
                        }
                        &.show-tablet-landscape {
                            display: none;
                            @media screen and (max-width: 1023px) {
                                display: block;
                                float: right;
                                width: 50%;
                                text-align: right;
                            }
                            @media screen and (max-width: 1023px) {
                                display: none;
                            }
                        }
                    }
                }
            }
            .product-set-addall {
                .product-add-to-cart {
                    .all-set-button {
                        @media screen and (max-width: 1023px) {
                            float: right;
                        }
                    }
                }
            }
        }
    }
}
.ui-dialog {
    &.terms-and-conditions {
        min-height: 260px !important;
        .ui-dialog-content {
            font-size: $base-font + 2px;
            font-family: $Helvetica;
            .terms-scrollbar-height {
                padding: 0 20px;
                box-sizing: border-box;
            }
        }
    }
}
.js {
    .zoomContainer {
        &.js-zoom-container {
            z-index: 1;
        }
    }
    .zoomWindow {
        z-index: 99999 !important;
    }
    &.pdp-page {
        .zoomWindow {
            z-index: 1 !important;
        }
    }
}
.enlarge-video-sec {
    display: inline-block;
    width: 100%;
    padding: 25px 0 0 0;
    @include GothamMedium($Gotham);
    font-size: $base-font - 2px;
    color: $SW-navy-blue;
    letter-spacing: 1.5px;
    &.mobile-enlarge-video-sec {
        width: auto;
        padding: 0;
        position: relative;
        top: -7px;
    }
    .enlarge-video {
        display: inline-block;
        @include GothamMedium($Gotham);
        font-size: $base-font - 2px;
        color: $SW-navy-blue;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        padding-left: 20px;
        line-height: 15px;
        &.enlarge {
            background: url("../../../images/zoom-icon-black.svg") no-repeat;
            float: left;
        }
        &.video-link {
            background: url("../../../images/video-icon-black.svg") no-repeat;
            float: right;
            visibility: hidden;
        }
        &.visible {
            visibility: visible;
        }
    }
}
.padding-eight {
    margin: 0 0 8px 0;
}
.quantity-ats-message {
    font-family: $HelveticaMedium;
    color: $radical-red;
    font-size: $base-font;
}
.max-cart-qty,
.max-cart-qty-set {
    @include WorkSansMedium($WorkSans);
    font-size: $base-font;
    margin: 0 0 10px 0;
    color: $light-red;
}
.max-cart-qty-set {
    margin-top: 10px;
}
.color-not-selected {
    font-family: $HelveticaMedium;
    color: $radical-red;
    font-size: $base-font;
    &.hide-error {
        display: none;
    }
    &.show-error {
        display: block;
    }
}
.float-right {
    float: right;
}
.float-left {
    float: left;
}
.full-width {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
}
@media screen and (max-width: 1023px) {
    .md-full-left {
        float: left;
    }
    .md-full-right {
        float: right;
    }
    .md-full-width {
        width: 100%;
    }
    .md-half-width {
        width: 46%;
    }
}
.enlarge-dialog {
    &.ui-dialog {
        max-width: 100%;
        .ui-icon-closethick {
            background-image: url(../../../images/dialog-close-icon.png);
            height: 23px;
            width: 23px;
            z-index: 9999;
        }
        .ui-dialog-titlebar-close {
            right: 25px;
            top: 22px;
            z-index: 99999;
        }
    }
    @media screen and (max-width: 1023px) {
        width: 100% !important;
        max-width: 100% !important;
    }
    .ui-widget-header {
        height: 0;
    }
    img.productthumbnail-zoom-image {
        display: none;
    }
    .image-zoom-out {
        position: absolute;
        top: 100px;
        right: 100px;
        font-size: 42px;
        display: none;
    }
    .image-zoom-in {
        position: absolute;
        top: 110px;
        right: 110px;
        font-size: 42px;
        display: none;
    }
    .enlarge-hero-image {
        width: 100%;
        overflow: hidden;
        @media screen and (min-width: 1024px) {
            width: 57.2%;
            display: inline-block;
            max-height: 700px;
            .image-zoom-out {
                display: inline-block;
            }
            .pinch-zoom-container {
                a {
                    left: 0;
                }
            }
            .enlarge-hero-container {
                width: 100%;
                margin: 0 auto;
                max-height: 700px;
            }
            &.zoom-active {
                .thumb.slick-slide {
                    img.productthumbnail {
                        opacity: 0;
                    }
                }
            }
            .thumb.slick-slide {
                height: 100%;
                text-align: center;
                img {
                    max-height: 100%;
                    display: inline-block;
                }
                img.productthumbnail-zoom-image {
                    display: none;
                    position: absolute;
                    top: -183px;
                    left: 0;
                }
            }
        }
    }
    .enlarge-right-sec {
        padding: 20px;
        box-sizing: border-box;
        background: $white;
        .pdpForm {
            float: left;
            width: 100%;
        }
        .product-options .color li a,
        .product-variations .color li a {
            width: 42px;
            min-width: 42px;
            height: 42px;
        }
        @media screen and (min-width: 1024px) {
            width: 35.56%;
            padding: 0;
            margin-right: 50px;
            margin-top: 65px;
            margin-bottom: 20px;
        }
        .slick-track {
            width: 100% !important;
            transform: initial !important;
            -ms-transform: translate(0cm) rotate(0deg) !important; /* Internet Explorer */
            -ms-transform-origin: top right !important;
        }
        .apply-scroll-bar {
            display: inline-block;
            width: 100%;
            max-height: 615px;
            @media screen and (min-width: 768px) and (max-width: 1023px) {
                max-height: 200px;
                .enlarge-thumb-image {
                    .thumb {
                        margin-right: 11px;
                    }
                }
            }
            .product-variation-content {
                .product-variations {
                    .swatches {
                        &.color {
                            float: left;
                            width: 100%;
                            @media screen and (min-width: 768px) and (max-width: 1023px) {
                                max-width: 270px;
                            }
                        }
                    }
                }
            }
        }
        .slimScrollRail,
        .slimScrollBar {
            -moz-border-radius: 0 !important;
            border-radius: 0 !important;
            -webkit-border-radius: 0 !important;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=1)" !important; // IE8
            filter: alpha(opacity=1) !important; // IE 5-7
            opacity: 1 !important;
        }
    }
    .pdp-main {
        .product-name {
            margin: 0;
            @media screen and (max-width: 1023px) {
                font-size: $base-font + 6px;
            }
        }
        .enlarge-thumb-image {
            height: auto;
            width: 100%;
            padding: 10px 0 0 0;
            @media screen and (min-width: 1024px) {
                padding: 10px 0 0 0;
            }
        }
        .product-variations {
            .attribute {
                border: none !important;
            }
        }
    }
    .enlarge-thumb-image {
        // thumbnail
        .thumb {
            margin-right: 12px;
            width: 69px !important;
            @media screen and (min-width: 1024px) {
                width: 73px !important;
            }
            a {
                img {
                    margin-bottom: 10px;
                }
            }
        }
        // thumbnail image
        img {
            cursor: pointer;
            max-width: 100%;
            height: auto;
        }
        h2 {
            margin: 1rem 0 0.3rem;
        }
    }
    button {
        &.slick-arrow {
            background: url("../../../images/enlarge-slider-lt-rt-icon.png")
                no-repeat;
            width: 15px;
            height: 25px;
            padding: 0;
            border: none;
            text-indent: -9999px;
            position: absolute;
            -webkit-transition: initial;
            -ms-transition: initial;
            transition: initial;
            z-index: 9999;
            top: 0;
            bottom: 0;
            margin: auto;
            &.slick-prev {
                left: 7px;
                background-position: 0 -68px;
                @media screen and (min-width: 1024px) {
                }
            }
            &.slick-next {
                background-position: 0 0;
                right: 7px;
                @media screen and (min-width: 1024px) {
                }
            }
        }
    }
}
#product-nav-container {
    overflow: hidden;
    position: absolute;
    right: 0;
    width: 160px;
    div {
        float: left;
        text-align: center;
        width: 77px;
    }
    img {
        max-height: 100%;
        max-width: 80%;
    }
    span {
        display: block;
        height: 65px;
    }
    .divided span {
        border-right: 1px solid $gainsboro;
        padding-right: 5px;
    }
}
.pdp-main {
    @extend %clearfix;
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    label {
        padding: 0;
        text-align: left;
        text-transform: uppercase;
        width: auto;
    }
    .product-review {
        display: none;
    }
    .product-col-1 {
        float: left;
        width: 31.7%;
        @media screen and (max-width: 767px) {
            width: 100%;
        }
        &.product-set {
            width: 38.1%;
            box-sizing: border-box;
            &.bh-product-col-1-product-set {
                width: 100%;
                > .product-primary-image {
                    width: 38.1%;
                    box-sizing: border-box;
                    display: inline-block;
                }
                .bh-product-col-1-productdetail {
                    width: 60.9%;
                    padding: 0;
                    display: inline-block;
                    vertical-align: top;
                    box-sizing: border-box;
                    @media screen and (min-width: 1024px) {
                        padding: 0 20px 0 3.2%;
                    }
                    .share-icon-container {
                        display: block;
                    }
                }
            }
        }
        &.product-bundle {
            padding: 0 12% 0 0;
            .product-primary-image {
                text-align: left;
            }
        }
    }
    .product-bundle {
        float: left;
        padding: 0;
        .product-col-2 {
            &.product-set {
                width: 100%;
                padding: 0;
            }
            .product-thumbnails {
                display: none;
            }
            .product-name {
                margin: 0 0 37px 0;
            }
            .promotion {
                margin: 0 0 31px 0;
            }
            .product-shortdescription {
                display: none;
            }
            .product-longdescription {
                display: none;
            }
            &.product-detail {
                .product-set-item {
                    padding: 20px 0 0 0;
                    border-width: 6px;
                    &:last-child {
                        border-width: 1px;
                    }
                    .product-add-to-cart {
                        display: none;
                    }
                }
                .product-set-image {
                    width: 14.5%;
                    .view-details {
                        display: none;
                    }
                    .product-id {
                        display: none;
                    }
                }
                .product-set-details {
                    width: 100%;
                    padding: 0;
                    .product-name {
                        display: none;
                    }
                    .product-price {
                        display: none;
                    }
                    .attribute {
                        display: none;
                    }
                    .promotion {
                        display: none;
                    }
                    .product-variations {
                        .attribute {
                            display: block;
                            margin: 0;
                            padding: 0 0 19px 0;
                            border-top: none;
                            &:nth-child(2n),
                            &:nth-child(3n) {
                                border-top: 1px solid $very-light-gray;
                                padding: 0 0 10px 0;
                            }
                        }
                        .size_label {
                            text-transform: uppercase;
                        }
                    }
                }
            }
            .product-add-to-cart {
                margin: 0 0 29px 0;
                width: 100%;
                padding: 0;
                display: inline-block;
                float: left;
                border-bottom: none;
                .availability-web {
                    border-top: none;
                    .availability-novariation {
                        font-size: $base-font + 1px;
                        @include WorkSansMedium($WorkSans);
                        letter-spacing: 0.5px;
                        color: $black;
                    }
                }
                button {
                    background-color: $SW-navy-blue;
                    color: $white;
                    border-color: $SW-navy-blue;
                    margin: 0;
                    float: left;
                }
                .product-set-main-button-parent {
                    margin: 0;
                }
            }
            .product-actions {
                padding: 0;
                border-bottom: 1px solid $manatee;
                .wishlist {
                    padding: 60px 0 0 0;
                }
                .gift-registry {
                    display: none;
                }
            }
        }
        .share-icon-container {
            display: inline-block;
            margin: 22px 0 0 0;
        }
    }
    .product-teasers {
        display: inline-block;
        width: 100%;
        margin-bottom: 14px;
        .TurnToReviewsTeaser {
            @include GothamMedium($Gotham);
            font-size: $base-font - 1px !important;
            color: $SW-gray !important;
            text-transform: uppercase !important;
            a#writeReview {
                vertical-align: middle !important;
            }
        }
        .rating-text {
            font-size: $base-font;
            @include WorkSansBold($WorkSans);
            text-transform: capitalize;
            line-height: 21px;
            letter-spacing: 0.4px;
            margin: -3px 0 0 3px;
            cursor: pointer;
            @media screen and (max-width: 767px) {
                letter-spacing: 0.5px;
            }
        }
        .TTratingBox {
            float: left;
            width: 100px;
            margin-left: 3px;
            background-size: 324px 4151px;
        }
    }
    .turntofit {
        font-size: $base-font - 1px;
        @include WorkSansMedium($WorkSans);
        display: inline-block;
        width: 100%;
        margin: 10px 0 12px;
        .turntofitcounts {
            @include WorkSansBold($WorkSans);
            text-transform: uppercase;
            letter-spacing: 1px;
        }
    }
    .availability-web {
        .availability-novariation {
            color: $manatee;
        }
    }
    .product-col-2 {
        float: left;
        width: 54%;
        padding: 0 9.5%;
        box-sizing: border-box;
        #zoom-image-container {
            display: inline-block;
            width: 100%;
        }
        @media screen and (max-width: 767px) {
            width: 100%;
            padding: 0;
        }
        .product-review {
            display: block;
        }
        &.giftcard-detail {
            .inventory {
                display: block;
                margin-top: 10px;
            }
        }
        &.e-giftcard-detail {
            .inventory {
                display: none;
            }
        }
        &.e-giftcard-detail,
        &.giftcard-detail {
            .product-name {
                line-height: 29px;
            }
            .custom-select {
                select {
                    &.error ~ .selected-option {
                        color: $medium-carmine;
                    }
                }
            }
            .please-select {
                &.error {
                    color: $medium-carmine;
                }
            }
            .product-special-messages {
                padding: 10px 0;
            }
            .tab-content {
                padding: 0;
                p {
                    margin-bottom: 0;
                }
                ul {
                    padding: 0 0 15px 15px;
                }
            }
            .terms-conditions {
                margin: 0 0 29px 0;
            }
            .product-variations {
                &.gift-card {
                    padding: 0;
                }
                .attribute {
                    width: 100%;
                    &:first-child {
                        padding: 0 0 29px 0;
                        margin: 26px 0 0 0;
                        border-bottom: 1px solid $manatee;
                        border-top: 0;
                    }
                    &:last-child {
                        margin: 25px 0 0 0;
                        padding: 0;
                    }
                }
            }
            .product-add-to-cart {
                padding-top: 4px;
                border: 0;
            }
            .personal-message {
                margin: 16px 0 0 0;
                .label-inline {
                    .field-wrapper {
                        input[type="checkbox"] {
                            margin: 0 3px 0 2px;
                            width: 16px;
                            height: 16px;
                            border: 1px solid $white;
                            outline: 1px solid $black;
                        }
                    }
                }
            }
            .fields-address {
                .form-row {
                    margin: 14px 0 6px 0;
                    width: 100%;
                    input[type="text"],
                    textarea {
                        @media screen and (max-width: 1023px) {
                            width: 100%;
                        }
                        &.error {
                            &::-webkit-input-placeholder {
                                color: $medium-carmine;
                            }
                            &::-moz-placeholder {
                                color: $medium-carmine;
                            }
                            &:-ms-input-placeholder {
                                color: $medium-carmine;
                            }
                            &:-moz-placeholder {
                                color: $medium-carmine;
                            }
                        }
                    }
                }
            }
            .product-add-to-cart {
                button {
                    margin-top: 15px;
                }
            }
            .product-actions {
                clear: both;
            }
        }
        &.e-giftcard-detail,
        &.giftcard-detail {
            .product-variations {
                .swatches {
                    li {
                        a {
                            width: 60px;
                            height: 85px;
                            border-radius: 0;
                            img {
                                border-radius: 0;
                            }
                        }
                        &.selected {
                            a {
                                border-radius: 0;
                            }
                        }
                    }
                }
            }
            .gift-card {
                padding: 10px 0 0 0;
            }
            .product-add-to-cart {
                button {
                    margin-top: 10px;
                }
                .wishlist {
                    padding: 25px 0 0 0;
                }
            }
            .personal-message {
                margin: 20px 0 0 0;
            }
            .e-gift-email {
                .form-row {
                    .option-text {
                        display: none;
                    }
                    &.error {
                        label {
                            span {
                                color: $medium-carmine;
                            }
                        }
                    }
                }
                .field-wrapper {
                    span {
                        &.error {
                            float: left;
                            width: 100%;
                            padding: 0;
                            display: block !important;
                            color: $medium-carmine;
                        }
                    }
                }
                input[type="text"] {
                    width: 290px;
                    height: 50px;
                    @media screen and (max-width: 767px) {
                        width: 100%;
                    }
                    &.error {
                        &::-webkit-input-placeholder {
                            color: $medium-carmine;
                        }
                        &::-moz-placeholder {
                            color: $medium-carmine;
                        }
                        &:-ms-input-placeholder {
                            color: $medium-carmine;
                        }
                        &:-moz-placeholder {
                            color: $medium-carmine;
                        }
                    }
                }
            }
        }
        &.giftcard-detail,
        &.e-giftcard-detail {
            .product-add-to-cart {
                .fields-address {
                    .form-row {
                        .option-text {
                            display: none;
                        }
                        &.error {
                            label {
                                span {
                                    color: $medium-carmine;
                                }
                            }
                        }
                    }
                    .form-row {
                        input[type="text"] {
                            @media screen and (max-width: 767px) {
                                width: 100%;
                            }
                        }
                    }
                    .field-wrapper {
                        span {
                            &.error {
                                float: left;
                                width: 100%;
                                padding: 10px 0;
                                display: none !important;
                            }
                        }
                    }
                }
            }
        }
        &.giftcard-detail,
        &.e-giftcard-detail {
            .gift-card {
                padding: 20px 0 0 0;
                border-top: 1px solid $alto;
                .variant-dropdown {
                    border: none;
                    position: relative;
                    width: 180px;
                    display: inline-block;
                    @media screen and (max-width: 1023px) {
                        width: 100%;
                    }
                    select {
                        width: 180px;
                        outline: none;
                        appearance: none;
                        padding: 0 10px;
                        font-size: $base-font - 1px;
                        @include WorkSansMedium($WorkSans);
                        letter-spacing: 1px;
                        height: 50px;
                    }
                    .custom-select {
                        .variation-select {
                            border-radius: 0x;
                            @media screen and (min-width: 768px) and (max-width: 1023px) {
                                margin-left: 5px;
                            }
                        }
                        .selected-option {
                            text-transform: capitalize;
                            border-radius: 0;
                        }
                    }
                }
            }
        }
        .product-special-messages {
            padding: 5px 0;
            font-size: $base-font + 2px;
            color: $special-product-messages-color;
            font-family: $HelveticaBold;
            @media screen and (max-width: 767px) {
                font-size: $base-font + 2px;
            }
        }
        .product-feature-messages {
            padding: 5px 0;
            font-family: $Helvetica;
            color: $night-rider;
            font-size: $base-font + 2px;
            text-transform: uppercase;
        }
        .tab-content {
            font-size: $base-font;
            font-family: $Helvetica;
            padding: 20px 0;
            ul {
                list-style-type: disc;
                padding: 0 0 30px 15px;
                li {
                    list-style-type: disc;
                    line-height: 18px;
                }
            }
        }
        .terms-conditions {
            width: 100%;
            display: inline-block;
            margin: 0 0 25px 0;
            font-size: $base-font;
            font-family: $Helvetica;
            a {
                width: auto;
                text-decoration: underline;
                @include WorkSansMedium($WorkSans);
            }
            #dialog {
                display: none;
            }
        }
        .product-add-to-cart {
            > label {
                display: none;
            }
            .shop-the-look {
                display: none;
            }
            .product-price {
                display: none;
            }
            .all-set-button {
                float: right;
                padding: 0 36px;
                margin: 40px 0 20px;
            }
            .product-set-main-button-parent {
                margin: 40px 0 20px;
            }
        }
        .top-add-all {
            &.product-add-to-cart {
                width: 100%;
                border-top: 1px solid $manatee;
                border-bottom: 1px solid $manatee;
                padding: 12px 0;
                margin-top: 30px;
                .all-set-button,
                .product-set-main-button-parent {
                    margin: 0;
                }
                .shop-the-look {
                    line-height: 50px;
                    font-size: 24px;
                    @include WorkSansMedium($WorkSans);
                    display: inline-block;
                    vertical-align: middle;
                }
            }
        }
        .product-set-main-button-error,
        .variation-selection-error-msg,
        .product-set-product-button-error {
            color: $medium-carmine;
            font-size: 12px;
            @include WorkSansSemiBold($WorkSans);
        }
        .product-set-main-button-parent {
            display: inline-block;
            vertical-align: middle;
            line-height: 50px;
            padding-left: 20px;
            .product-set-main-button-error {
                padding: 0;
            }
        }
        &.product-set {
            width: 61.9%;
            padding: 0 3.2%;
            padding-right: 20px;
            float: right;
            &.bh-product-col-2-product-detail {
                width: 100%;
                padding: 0;
                float: none;
                margin-top: 41px;
                clear: both;
                display: inline-block;
            }
        }
        .price-standard {
            border-right: 1px solid $gainsboro;
            padding-right: 0.5rem;
        }
    }
    .product-name {
        float: left;
        @include GothamMedium($Gotham);
        font-size: $base-font + 6px;
        line-height: normal;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $SW-navy-blue;
        margin: 11px 0 10px 0;
        width: 100%;
        .item-name {
            @include WorkSansMedium($WorkSans);
            font-size: 24px;
        }
    }
    .product-brand {
        text-transform: uppercase;
        padding: 0 0 10px 0;
        color: $brand-name-color;
    }
    .product-number {
        color: $very-light-gray;
        font-size: 0.65rem;
        margin-bottom: 0.5rem;
    }
    .expired-price {
        font-family: $WorkSans;
    }
    .product-price {
        font-size: $base-font + 8px;
        margin-bottom: 11px;
        letter-spacing: 0.5px;
        color: $SW-navy-blue;
        @include GothamMedium($Gotham);
        .price-standard {
            @include GothamMedium($Gotham);
            font-size: $base-font + 4px;
            letter-spacing: 0.5px;
            border-right: none;
            padding-right: 9px !important;
            color: $SW-gray;
            text-decoration: line-through;
        }
        .price-tiered {
            color: $sangria;
        }
    }
    .product-review {
        @extend %clearfix;
        margin-bottom: 15px;
    }
    .product-main-attributes {
        .attribute {
            padding: 0.2em 0;
        }
        .label {
            padding: 0;
        }
    }
    .product-primary-image {
        position: relative;
        &.not-a-device {
            max-width: 100%;
            text-align: center;
            display: inline-block;
            .main-image {
                display: block;
                overflow: visible;
                position: relative;
            }
        }
    }
    .product-info {
        clear: both;
        padding-top: 1em;
        ul {
            font-size: 0.8rem;
            list-style: square;
            padding: 0 5em;
            @media screen and (min-width: 768px) {
                padding-bottom: 1em;
                padding-top: 1em;
            }
        }
        .product-details-container {
            @media screen and (min-width: 768px) {
                display: flex;
                flex-direction: row-reverse;
                .product-details-description {
                    flex: 1;
                }
                .product-details-video {
                    min-width: 320px;
                    padding-left: 1em;
                }
            }
        }
    }
    .productmessage {
        span {
            color: #dc8700;
            @include WorkSansMedium($WorkSans);
            margin-bottom: 10px;
            display: inline-block;
            width: 74%;
        }
    }
    .product-content-quickorder {
        .product-add-to-cart {
            overflow: inherit;
        }
    }
    // Product Add to Cart Area (Availability, Quantity)
    .product-add-to-cart {
        display: block;
        margin: 0;
        overflow: visible;
        border-top: 1px solid $light-gray;
        padding-top: 15px;
        .wishlist {
            width: auto;
            display: inline-block;
            @include GothamMedium($Gotham);
            font-size: $base-font + 1px;
            color: $SW-navy-blue;
            font-style: normal;
            letter-spacing: 1px;
            padding: 15px 0 0 0;
            @media screen and (min-width: 768px) {
                margin-left: 24px;
            }
            .wishlist-text {
                color: $SW-navy-blue;
                text-transform: uppercase;
            }
            a {
                .wishlist-logo {
                    display: inline-block;
                    line-height: normal;
                    float: left;
                    background: url("../../../images/wishlist.svg") no-repeat;
                    width: auto;
                    height: 18px;
                    padding-left: 30px;
                    &.added-to-wishlist {
                        background: url("../../../images/wishlist-solid.svg")
                            no-repeat;
                    }
                }
            }
        }
        .inventory {
            width: 80px;
            float: left;
            white-space: nowrap;
            height: 50px;
            position: relative;
            label {
                float: none;
                text-align: right;
            }
            input {
                font-size: 1.5em;
                height: 50px;
                width: 50px;
                text-align: center;
                @media screen and (min-width: 768px) {
                    height: 37px;
                    width: 37px;
                }
            }
        }
        .shippingdetails {
            float: left;
            width: 100%;
            margin: 20px 0 0;
            border-top: 1px solid $manatee;
            border-bottom: 1px solid $manatee;
            box-sizing: border-box;
            .heading {
                margin: 0;
                text-align: left;
                @include GothamBold($Gotham);
                font-size: $base-font + 1px;
                color: $SW-navy-blue;
                letter-spacing: 0.5px;
                background: url(../../../images/pdp-arrow-closed.png) no-repeat
                    right;
                &.expanded {
                    background: url(../../../images/pdp-arrow-open.png)
                        no-repeat right;
                }
            }
            .shippingdrawer,
            .productinfodate p {
                @include GothamMedium($Gotham);
                font-size: $base-font + 1px;
                letter-spacing: 0.5px;
                color: $SW-gray;
            }
        }
        button {
            border: medium none;
            @include GothamBold($Gotham);
            font-size: $base-font + 2px;
            letter-spacing: 2px;
            height: 52px;
            padding: 0;
            color: $white;
            display: inline-block;
            background-color: $SW-navy-blue;
            text-transform: uppercase;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            &.all-set-button {
                width: auto;
                border-radius: 0;
            }
        }
        .shippingdetails .shippingdrawer {
            .productinfo .clickhere {
                @include GothamMedium($Gotham);
                font-size: $base-font - 1px;
                letter-spacing: 0.5px;
                color: $SW-gray;
                text-transform: uppercase;
            }
        }
        .dw-apple-pay-button {
            margin-left: 0.5rem;
            @media screen and (min-width: 768px) {
                width: 172px; // make sure apple pay button is as big as Add To Cart
            }
        }
        .availability-web {
            label {
                display: none;
            }
            .availability-msg {
                p {
                    @include WorkSansMedium($WorkSans);
                    color: $black;
                    letter-spacing: 0.5px;
                    font-size: $base-font + 1px;
                }
            }
        }
    }
    .savingmessage {
        color: $SW-light-blue;
        font-size: $base-font + 1px;
        @include GothamMedium($Gotham);
        line-height: 22px;
        text-transform: uppercase;
        width: 100%;
        letter-spacing: 0.5px;
    }
    .promotion {
        letter-spacing: 1.5px;
        .promotion-title {
            display: none;
        }
        .promotion-callout {
            color: $SW-green;
            @include GothamMedium($Gotham);
            font-size: $base-font + 1px;
            line-height: 20px;
            text-transform: uppercase;
            letter-spacing: 0.5px;
            .callout-message {
                color: $SW-green;
                font-size: $base-font + 1px;
            }
            i {
                display: none;
            }
            .promo-tooltip {
                margin-left: 0.2rem;
                text-transform: uppercase;
                color: $SW-gray;
                text-decoration: underline;
                @include GothamBold($Gotham);
                font-size: $base-font - 2px;
                letter-spacing: 0.5px;
            }
        }
    }
    .product-actions {
        @extend %clearfix;
        padding: 22px 0;
        .socialsharing {
            display: block;
            width: 100%;
        }
        .share_label {
            font-family: $Helvetica;
            color: $black;
            font-size: $base-font;
            vertical-align: middle;
        }
        a {
            border: 0;
            color: $black;
            font-family: $HelveticaBold;
            padding: 0;
            height: 52px;
            line-height: 52px;
            &.share-icon {
                display: inline-block;
                line-height: 0;
                height: auto;
                vertical-align: middle;
                padding: 0 13px;
                font-size: $base-font + 9;
                &.share-twitter {
                    padding: 0 16px;
                }
                &.share-mail {
                    padding: 0 5px;
                }
            }
        }
    }
    .product-shortdescription {
        color: $black;
        font-size: $base-font;
        @include WorkSansBold($WorkSans);
        text-transform: uppercase;
        margin-bottom: 20px;
    }
    .product-longdescription {
        color: $black;
        font-size: $base-font + 2;
        @include WorkSansMedium($WorkSans);
        margin-bottom: 20px;
        line-height: 25px;
        text-transform: none;
    }
    .heading {
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 1px;
        font-size: $base-font + 1px;
        font-family: $HelveticaBold;
    }
    .share-icon-container {
        .share-icon {
            display: inline-block;
            vertical-align: middle;
            padding: 0 10px;
            img {
                width: 26px;
            }
            &.share-facebook {
                img {
                    width: 18px;
                }
            }
            &.share-mail {
                img {
                    width: 22px;
                }
            }
        }
        .share-icon-label {
            font-size: $base-font + 1px;
            @include WorkSansBold($WorkSans);
            color: $black;
            display: inline-block;
            text-transform: uppercase;
            letter-spacing: 0.5px;
        }
    }
    .product-thumbnails,
    .product-thumbnails ul,
    .enlarge-thumb-image {
        @media screen and (min-width: 768px) {
            width: 95%;
            padding-top: 16px;
            overflow: hidden;
            height: 120px;
            &.remove-ht-ovr-hidden {
                overflow: visible;
                height: auto;
            }
            &.slick-arrow-bar {
                &::before {
                    content: "";
                    background: url("../../../images/ww/alt-lt-rt-icons.png")
                        no-repeat;
                    background-position: -5px -44px;
                    width: 19px;
                    height: 1px;
                    display: inline-block;
                    position: absolute;
                    top: 64px;
                    right: -28px;
                }
            }
            button {
                &.slick-arrow {
                    width: 12px;
                    height: 18px;
                    padding: 0;
                    border: none;
                    text-indent: -9999px;
                    position: absolute;
                    top: 0;
                    right: -24px;
                    transition: initial;
                    &.slick-prev {
                        background: url("../../../images/carat-left_Black.svg")
                            no-repeat;
                        top: 78px;
                        transform: translateX(15px);
                        left: 100%;
                        &.slick-disabled {
                            background: url("../../../images/carat-left_Grey.svg")
                                no-repeat;
                        }
                    }
                    &.slick-next {
                        background: url("../../../images/carat-right_Black.svg")
                            no-repeat;
                        top: 36px;
                        transform: translateX(15px);
                        left: 100%;
                        &.slick-disabled {
                            background: url("../../../images/carat-right_Grey.svg")
                                no-repeat;
                        }
                    }
                }
            }
        }
    }
    .main-product-set-details,
    .product-set {
        #thumbnails {
            padding-bottom: 30px;
            .thumb {
                padding-top: 0;
                width: 25%;
                padding-right: 15px;
                box-sizing: border-box;
                line-height: 0;
                float: left;
                @media screen and (max-width: 767px) {
                    padding-right: 0;
                }
                a {
                    @media screen and (max-width: 767px) {
                        width: 63.5px;
                        float: left;
                        height: 93.5px;
                        left: 0;
                    }
                }
            }
        }
        .product-price {
            font-size: $base-font + 6px;
            .price-standard,
            .price-standard-exist {
                font-size: $base-font + 6px;
                @media screen and (max-width: 767px) {
                    font-size: $base-font + 2px;
                }
            }
        }
    }
    .product-variations {
        width: 100%;
        display: inline-block;
        margin-top: 3px;
        //To display 3 size attributes in mobile.
        @media screen and (max-width: 767px) {
            ul {
                li {
                    ul.size {
                        float: left;
                    }
                }
            }
        }
        .attribute {
            border-top: 0;
            padding: 5px 0;
            border-bottom: 1px solid $manatee;
            padding-bottom: 10px;
            .attribute_label {
                margin: 11px 0 2px;
                .label,
                .selected-value,
                .size-chart-link {
                    display: inline-block;
                    margin: 0;
                    letter-spacing: 0.5px;
                    margin-right: 3px;
                }
                .label {
                    @include GothamBook($GothamBook);
                    font-size: $base-font + 1px;
                    letter-spacing: 0.5px;
                    color: $SW-navy-blue;
                }
                .label,
                .selected-value {
                    text-transform: capitalize;
                    color: $SW-navy-blue;
                }
                .selected-value,
                .final-sale-color-msg {
                    @include GothamBold($Gotham);
                    letter-spacing: 0.64px;
                    font-size: $base-font + 1px;
                    @media screen and (min-width: 320px) and (max-width: 767px) {
                        letter-spacing: 0.55px;
                    }
                }
                .size-chart-link {
                    font-size: 11px;
                    float: right;
                    span.size_icon,
                    span.size_label {
                        display: inline-block;
                        vertical-align: middle;
                    }
                    span.size_icon {
                        width: 23px;
                        padding-top: 5px;
                        img {
                            width: 100%;
                        }
                    }
                    span.size_label {
                        padding-left: 3px;
                        text-transform: uppercase;
                        @include GothamMedium($Gotham);
                        font-size: $base-font - 1px;
                        letter-spacing: 0.5px;
                        color: $SW-gray;
                    }
                }
            }
            &.braCupSize {
                .attribute_label {
                    .label,
                    .selected-value {
                        text-transform: uppercase;
                    }
                }
            }
            .value {
                margin-top: 11px;
            }
            &:first-child {
                border-top: 1px solid $manatee;
                margin-top: 10px;
            }
            &.size_border {
                margin-bottom: 4px;
                .attribute_label {
                    margin: 11px 0;
                }
                .swatches {
                    li {
                        a {
                            margin: 5px 10px 5px 0;
                        }
                    }
                }
            }
        }
    }
    // Product Set Display
    .product-set {
        .product-set-list {
            border-top: 1px solid transparent;
            .product-set-details {
                padding-left: 4%;
                margin: 0;
                width: 78%;
                float: right;
                box-sizing: border-box;
                .product-price {
                    margin-bottom: 3px;
                    display: inline-block;
                    float: left;
                }
                .product-hemmable {
                    border-bottom: none;
                }
            }
            .product-image-container {
                float: left;
                box-sizing: border-box;
                background: transparent;
                .thumb {
                    border-bottom: 5px solid transparent;
                    float: left;
                    margin-right: 10px;
                    padding-top: 20px;
                    width: 25%;
                    &.selected {
                        a {
                            img {
                                border-bottom: 5px solid $wine-berry;
                                box-sizing: border-box;
                                cursor: pointer;
                                max-width: 100%;
                                height: auto;
                            }
                        }
                    }
                }
            }
            .product-set-item {
                clear: both;
                overflow: inherit;
                display: inline-block;
                padding-top: 35px;
                padding-bottom: 40px;
                width: 100%;
                .product-variations {
                    width: 100%;
                    float: left;
                }
            }
            .product-set-image {
                width: 21.6%;
                display: inline-block;
                img {
                    width: 100%;
                }
            }
            .product-number {
                display: none;
            }
            .attribute {
                h3 {
                    width: 25%;
                }
                .label {
                    width: auto;
                    white-space: nowrap;
                }
            }
        }
        .product-add-to-cart {
            border: none;
            padding: 0 0 0 4%;
            width: 78.4%;
            float: right;
            box-sizing: border-box;
            label {
                float: left;
                font-size: 0.75rem;
                padding-top: 0.3em;
                padding-right: 0.5em;
            }
            .availability-web {
                padding: 15px 0;
                font-size: $base-font + 1px;
                @include WorkSansMedium($WorkSans);
                letter-spacing: 0.5px;
                label {
                    float: none;
                }
                .color_gray {
                    font-weight: normal;
                    color: $dim-gray;
                }
            }
            .inventory {
                width: 75px;
                float: left;
                white-space: nowrap;
                height: 50px;
                position: relative;
            }
            .sub-product-item {
                background: $black;
                color: $white;
                cursor: pointer;
                display: inline-block;
                font-size: $base-font + 2px;
                padding: 0 36px;
                text-align: center;
                transition: all 0.3s ease;
                text-transform: uppercase;
                outline: 0;
                letter-spacing: 1px;
                width: auto;
                height: 52px;
                float: left;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
            > a {
                font-family: $HelveticaBold;
                color: $black;
                font-size: 11px;
                line-height: 30px;
            }
        }
        .view-details {
            @include WorkSansBold($WorkSans);
            font-size: $base-font - 1px;
            color: $black;
            text-align: center;
            text-transform: uppercase;
            padding: 8px 0;
        }
        .product-id {
            @include WorkSansMedium($WorkSans);
            color: $silver;
            font-size: $base-font;
            text-align: center;
        }
    }
    // Product Recommendations
    .recommendation {
        width: 14.29%;
        float: right;
        .recommendations {
            clear: left;
            padding: 0 0;
            border: 1px solid $light-gray;
        }
        .recommendations-heading {
            text-align: center;
            padding: 17px 0 13px;
            margin: 0 0;
            @include GothamMedium($Gotham);
            font-size: $base-font + 1px;
            text-transform: uppercase;
            color: $SW-navy-blue;
            letter-spacing: 0.5px;
        }
        button {
            &.slick-arrow {
                width: 17px;
                height: 23px;
                padding: 0;
                border: none;
                text-indent: -9999px;
                position: relative;
                top: 31%;
                left: 0;
                bottom: 0;
                margin: 0 auto;
                z-index: 9;
                transition: initial;
                &.slick-prev {
                    background: url("../../../images/recently-viewed-arrows_left.svg")
                        no-repeat;
                    &.slick-disabled {
                        background: url("../../../images/recently-viewed-arrows-left-unactivated.svg")
                            no-repeat;
                    }
                }
                &.slick-next {
                    background: url("../../../images/recently-viewed-arrows_right.svg")
                        no-repeat;
                    &.slick-disabled {
                        background: url("../../../images/recently-viewed-arrows-right-unactivated.svg")
                            no-repeat;
                    }
                }
            }
        }
        ul {
            list-style-type: none;
            position: relative;
            width: 100%;
        }
        .grid-tile {
            float: none;
            width: 100%;
            border: 0;
        }
        .recommendation-tooltip {
            background: none repeat scroll 0 0 $white;
            border: 1px solid;
            left: 50%;
            position: absolute;
            text-align: left;
            top: 50%;
            width: 300px;
            z-index: 100;
        }
        .product-tile {
            padding: 0 0;
            margin: 0 0;
            color: $black;
            width: 100%;
            border: 0;
            .product-image {
                max-width: 80px;
                margin: auto;
                img {
                    width: auto;
                    height: auto;
                }
            }
            .product-name {
                font-size: $base-font;
                letter-spacing: 0;
                margin: 3px 0 6px;
                width: 100%;
                line-height: normal;
            }
            .product-price {
                font-size: $base-font;
                margin: 5px 0;
                color: $night-rider;
                @include WorkSansMedium($WorkSans);
                .price-standard {
                    padding-right: 6px;
                }
            }
        }
        &.for-desktop {
            .product-tile {
                padding-bottom: 9px;
            }
        }
        &.for-device {
            display: none;
        }
        @media screen and (min-width: 1025px) {
            .slick-slider {
                top: 0 !important;
            }
            .recommendations {
                clear: both;
                display: inline-block;
            }
            .product-tile {
                .product-name {
                    overflow: hidden;
                    height: auto;
                    padding: 0 5px;
                    box-sizing: border-box;
                }
            }
        }
        @media screen and (max-width: 1023px) {
            width: 100%;
            float: none;
            &.for-desktop {
                display: none;
            }
            &.for-device {
                display: block;
            }
            .recommendations {
                padding-bottom: 27px;
                margin-top: 35px;
            }
            .recommendation-tiles {
                max-width: 796px;
                margin: auto;
            }
            .recommendations-heading {
                padding: 23px 0 22px;
            }
            .grid-tile {
                width: 149px;
                float: left;
                padding: 0 25px;
            }
            .product-tile {
                .product-image {
                    max-width: 116px;
                }
                .product-name {
                    line-height: normal !important;
                    word-break: normal !important;
                    margin: 7px 0 0;
                }
            }
        }
        @media screen and (max-width: 1023px) {
            .recommendations {
                padding-bottom: 17px;
                margin-top: 22px;
            }
            .grid-tile {
                padding: 0 21px;
            }
        }
        @media screen and (max-width: 1023px) {
            .recommendations {
                margin: 0 10px;
            }
        }
        @media screen and (max-width: 767px) {
            .product-tile {
                .product-image {
                    max-width: initial;
                    img {
                        max-width: 100%;
                    }
                }
            }
        }
    }
    //ends recommendation
    .tabs {
        width: 100%;
        display: inline-block;
        margin: 20px 0 0 0;
        .tab-label {
            @include GothamBold($Gotham);
            color: $SW-navy-blue;
            font-size: $base-font + 2px;
            text-transform: uppercase;
            text-align: center;
            margin: 0;
            border: 0;
            padding: 10px 0;
            letter-spacing: 0.5px;
            border-bottom: 3px solid $white;
            &.current {
                margin: 0;
                border: 0;
                background-color: $white;
                border-bottom: 3px solid $SW-navy-blue;
            }
        }
        .tab-content {
            background-color: $SW-gainsboro;
            display: none;
            padding: 41px 0;
            &.current {
                display: block;
            }
            .tab-content-details {
                @include GothamBold($Gotham);
                font-size: $base-font + 1px;
                color: $SW-navy-blue;
                letter-spacing: 0.5px;
                line-height: 22px;
                margin: 0 auto;
                text-align: left;
                display: inline-block;
                max-width: 966px;
                width: 100%;
                background: $white;
                padding: 15px 18px;
                box-sizing: border-box;
                .mainframeid-itemno {
                    @include GothamBook($GothamBook);
                    font-size: $base-font + 1px;
                    letter-spacing: 0.5px;
                    color: $SW-navy-blue;
                }
                .tab-left {
                    width: 57%;
                    display: inline-block;
                    float: left;
                }
                .tab-right {
                    width: 39%;
                    padding: 0 0 0 37px;
                    display: inline-block;
                    box-sizing: border-box;
                }
                p {
                    @include WorkSansMedium($WorkSans);
                    font-size: $base-font + 1px;
                    letter-spacing: 0.3px;
                    font-weight: normal;
                    line-height: 20px;
                    strong {
                        @include WorkSansMedium($WorkSans);
                    }
                    @media screen and (max-width: 767px) {
                        font-size: $base-font + 1px;
                    }
                }
                ul {
                    padding: 0;
                    margin-left: 8px;
                    @include GothamBook($GothamBook);
                    font-size: $base-font + 1px;
                    letter-spacing: 0.5px;
                    li {
                        @include GothamBook($GothamBook);
                        font-size: $base-font + 1px;
                        letter-spacing: 0.5px;
                        color: $SW-gray;
                        margin: 0 0 5px 0;
                        text-indent: -8px;
                        &:before {
                            content: "-";
                            text-indent: -5px;
                        }
                        &:last-child:not(.tt-o-page-list__number) {
                            margin: 0 0 19px 0;
                        }
                    }
                }
                .tab-detail-header {
                    text-transform: capitalize;
                }
                .tab-fit,
                .tab-care {
                    padding: 0 0 15px 0;
                }
            }
        }
    }
    .product-primary-image {
        .product-video-container {
            position: absolute;
            z-index: 1;
            background-color: #fff;
            display: none;
            width: 100%;
            height: 100%;
        }
    }
}
.main-image {
    .zoom_container {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
        top: 0;
        left: 120%;
        z-index: 999;
        display: none;
        #zoom-window {
            overflow: visible !important;
        }
    }
    &:hover {
        .zoom_container {
            display: block;
        }
    }
}
.primary-image {
    max-width: 100%;
}
.product-thumbnails {
    @media screen and (max-width: 767px) {
        margin-bottom: 21px;
    }
    ul {
        @extend %clearfix;
    }
    @media screen and (min-width: 768px) {
        // thumbnail
        .thumb {
            margin-right: 12px;
            width: 73px;
            float: left;
            position: relative;
            a {
                display: inline-block;
                width: 100%;
                @media screen and (min-width: 1400px) {
                    img {
                        border: 0 solid transparent;
                    }
                }
            }
        }
    }
    // thumbnail image
    img {
        cursor: pointer;
        max-width: 100%;
        height: auto;
    }
    h2 {
        margin: 1rem 0 0.3rem;
    }
}
.rating {
    color: $black;
    font-size: 16px;
    .product-info & {
        font-size: 2em;
    }
    i {
        padding-right: 3px;
    }
}
.product-variations,
.product-options {
    .attribute {
        overflow: visible;
        padding: 0;
        position: relative;
        border-top: 1px solid $alto;
        h3,
        .label {
            font-size: $base-font;
            margin: 16px 0;
            text-transform: uppercase;
            display: inline-block;
            font-family: $HelveticaBold;
        }
        .value {
            width: 100%;
            display: inline-block;
        }
        ul {
            float: right;
            width: 100%;
        }
        .selected-value {
            border: 0 none;
            color: $black;
            text-transform: none;
            display: inline-block;
            font-family: $Helvetica;
        }
        .size-chart-link {
            a {
                border-color: $white !important;
                padding: 0;
            }
        }
    }
    .swatches li {
        float: left;
        &.selected {
            a {
                border-color: $black;
                background: $black;
                color: $white;
            }
        }
        @media screen and (min-width: 1026px) {
            &:hover {
                a {
                    border-color: $black;
                    background: $black;
                    color: $white;
                }
            }
        }
        &.unselectable {
            a {
                border-color: $FB-light-mercury-gray;
                opacity: 0.8;
                position: relative;
                color: $alto;
                &::after {
                    content: "";
                    position: absolute;
                    height: 2px;
                    background: $alto;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%) rotate(-18deg);
                    width: 92px;
                }
            }
            &:hover {
                a {
                    border-color: $FB-light-mercury-gray;
                    background-color: $dim-gray;
                    opacity: 0.3;
                }
            }
        }
        &.unselectable {
            &.selected {
                a {
                    background-color: $FB-light-mercury-gray;
                    opacity: 0.3;
                }
                &:hover {
                    a {
                        border-color: $FB-light-mercury-gray;
                        background-color: $dim-gray;
                        opacity: 0.3;
                    }
                }
            }
        }
        a {
            @include GothamMedium($Gotham);
            font-size: $base-font + 1px;
            letter-spacing: 0.5px;
            box-sizing: border-box;
            color: $SW-navy-blue;
            border: 1px solid $FB-light-mercury-gray;
            display: block;
            line-height: 32px;
            height: 33px;
            border-radius: 0;
            min-width: 90px;
            margin: 5px 10px 5px 0;
            padding: 1px;
            text-align: center;
            white-space: nowrap;
            @media screen and (max-width: 767px) {
                height: 33px;
                min-width: 90px;
            }
        }
        &.selected {
            a {
                background-color: $SW-navy-blue !important;
                color: #fff !important;
            }
        }
        &.selected,
        &:hover {
            a {
                border: 2.5px solid $SW-navy-blue !important;
                color: #fff !important;
                background: $SW-navy-blue;
            }
        }
    }
    .color li {
        a {
            width: 29px;
            min-width: 29px;
            height: 29px;
            border: 0;
            border-radius: 50%;
            margin-right: 8px;
            line-height: 29px;
            padding: 2px;
            box-sizing: border-box;
            &.color.color-swatch{
				background-clip: content-box !important;
                border: 1px solid $gainsboro;
                padding: 0.5px;
			}
            img {
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                border-radius: 50%;
                border: 1px solid $gainsboro;
            }
        }
        &.selected {
            a {
                border: 1px solid $shady-lady;
            }
        }
    }
    select {
        background-color: $white;
        border-radius: 0;
        height: 42px;
        width: 100%;
        margin-left: 0;
        @media screen and (min-width: 768px) {
            height: 32px;
        }
    }
}
// Tabs using just CSS, no javascript required
.tabs {
    position: relative;
    text-align: center;
}
.tab {
    @media screen and (min-width: 768px) {
        display: inline-block;
        float: none;
    }
    .tab-switch {
        display: none;
        &:checked {
            + .tab-label {
                @media screen and (min-width: 768px) {
                    background: $white;
                    border-bottom-color: $black;
                    color: $black;
                    font-weight: 900;
                    top: -0.0625rem;
                    transition: all 0.35s;
                    z-index: 1;
                }
            }
            + label + .tab-content {
                opacity: 1;
                transition: all 0.35s;
                z-index: 2;
            }
        }
    }
    .tab-label {
        border: 0;
        border-radius: 0;
        border-bottom: 3px solid $white;
        color: $nobel;
        display: block;
        font-weight: 400;
        line-height: 2.5em;
        margin-bottom: 0;
        position: relative;
        text-transform: none;
        top: 0;
        transition: all 0.25s;
        min-width: 100%;
        background: transparent;
        outline-offset: -4px;
        @media screen and (min-width: 768px) {
            cursor: pointer;
            padding: 0 1.25rem;
            &:hover {
                border-bottom-color: $manatee;
            }
        }
    }
    .tab-content {
        padding: 1em 0;
        text-align: left;
        z-index: 1;
        @media screen and (min-width: 768px) {
            left: 0;
            opacity: 0;
            position: absolute;
            top: 2.75em;
            transition: all 0.35s;
            width: 100%;
        }
    }
}
.pt_product-details {
    @media screen and (max-width: 1023px) {
        @media screen and (min-width: 960px) {
            .product-col-2 {
                .product-add-to-cart {
                    .all-set-button {
                        padding: 0 10px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1023px) {
        #product-nav-container {
            div {
                float: right;
            }
        }
        .pdp-main {
            margin: 0;
            .product-set {
                &.product-col-1 {
                    width: 50%;
                    padding-right: 10px;
                }
                &.product-col-2 {
                    width: 100%;
                    padding: 0;
                    &.main-product-set-details {
                        width: 50%;
                        padding-left: 10px;
                    }
                    .product-review {
                        margin-bottom: 12px;
                    }
                    .product-shortdescription {
                        margin-bottom: 15px;
                    }
                    .product-set-list {
                        .product-set-image {
                            width: 20.6%;
                        }
                        .product-set-details {
                            width: 78.4%;
                        }
                    }
                }
            }
            .product-bundle {
                padding: 0;
                .product-col-2 {
                    &.product-detail,
                    &.product-detail-set {
                        width: 100%;
                        padding: 0;
                        &:nth-child(2n) {
                            border-bottom: 1px solid $manatee;
                        }
                        .product-name {
                            font-size: $base-font + 12px;
                            margin: 0 0 27px 0;
                        }
                        .product-thumbnails {
                            display: none;
                        }
                        .product-set-image {
                            width: 52.6%;
                        }
                        .product-set-details {
                            width: 100%;
                        }
                        .product-set-item {
                            padding: 20px 0 10px 0;
                        }
                        .product-add-to-cart {
                            width: 100%;
                            margin: 0;
                        }
                        .product-actions {
                            padding: 0;
                            width: 100%;
                            text-align: left;
                            .wishlist {
                                width: 100%;
                                padding: 20px 0;
                            }
                        }
                    }
                }
            }
            .product-col-2 {
                &.giftcard-detail,
                &.e-giftcard-detail {
                    width: 100%;
                    .product-add-to-cart {
                        padding: 0 5px 20px;
                        @media screen and (max-width: 767px) {
                            padding: 0 0 20px;
                        }
                        .wishlist {
                            width: 100%;
                            padding: 20px 0 0 0;
                            margin-bottom: 20px;
                        }
                    }
                }
            }
            .tabs {
                margin: 61px 0 0 0;
                .tab-content {
                    padding: 41px 18px 41px 18px;
                }
                .tab-content-details {
                    width: 100%;
                }
            }
        }
        &#wrapper {
            width: 100%;
            padding-top: 0;
        }
        .primary-content {
            width: 100%;
            box-sizing: border-box;
            padding: 43px 0 0 0;
        }
    }
    @media screen and (max-width: 767px) {
        .promotion {
            float: none;
            display: inline-block;
            width: 100%;
            padding: 10px 0;
            .promotion-callout {
                line-height: normal;
            }
        }
        #main {
            padding: 0;
        }
        .main-product-set-details {
            #thumbnails {
                padding-bottom: 25px;
            }
        }
        .pdp-main {
            .product-review {
                display: block;
            }
            .inventory {
                .quantityinput {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    height: 52px;
                }
            }
            .product-set {
                &.product-col-1 {
                    width: 100%;
                    padding: 0;
                    &.product-bundle {
                        .product-primary-image {
                            display: none;
                        }
                    }
                }
                &.product-col-2 {
                    width: 100%;
                    padding: 0 20px;
                    &.product-bundle {
                        .product-set-image {
                            width: 48.4%;
                        }
                    }
                    &.main-product-set-details {
                        width: 100%;
                        padding: 0;
                        .product-set-list {
                            .product-set-item {
                                .product-variations {
                                    display: none;
                                }
                            }
                        }
                    }
                    .top-add-all.product-add-to-cart {
                        #add-all-to-cart {
                            display: none;
                        }
                    }
                    .view-details,
                    .product-id {
                        display: none;
                    }
                    .product-set-list {
                        .product-set-image,
                        .product-set-details {
                            width: 100%;
                        }
                    }
                    .product-add-to-cart {
                        width: 100%;
                    }
                }
            }
            .product-col-1 {
                &.giftcard-detail,
                &.e-giftcard-detail {
                    width: 100%;
                }
            }
            .product-col-2 {
                &.giftcard-detail,
                &.e-giftcard-detail {
                    width: 100%;
                    padding: 0;
                    .fields-address {
                        .form-row {
                            width: 100%;
                            .input-textarea {
                                width: 100%;
                            }
                        }
                    }
                }
            }
            .product-actions {
                a {
                    &.share-icon {
                        padding: 0 10px;
                    }
                }
            }
            .product-name {
                line-height: normal;
                box-sizing: border-box;
                .item-name {
                    font-size: 24px;
                    line-height: normal;
                }
            }
            .product-shortdescription {
                font-size: 12px;
                line-height: normal;
                font-size: 3.75vw;
            }
            .product-longdescription {
                font-size: 16px;
                line-height: normal;
                font-size: 3.9vw;
            }
            .share-icon-container .share-icon-label {
                font-size: 12px;
                line-height: normal;
            }
            .top-add-all.product-add-to-cart .shop-the-look {
                font-size: 24px;
                line-height: normal;
                font-size: 6.5vw;
                padding: 10px 0;
            }
            .product-variations {
                .attribute {
                    .attribute_label {
                        .label,
                        .selected-value {
                            line-height: normal;
                        }
                        .size-chart-link {
                            font-size: $base-font;
                            line-height: normal;
                        }
                    }
                }
            }
            .product-set {
                &.product-col-1 {
                    width: 100%;
                    padding: 0;
                }
                &.product-col-2 {
                    width: 100%;
                    padding: 0 20px;
                    .product-add-to-cart {
                        .all-set-button {
                            width: 100%;
                            margin: 15px 0;
                        }
                    }
                    .product-set-main-button-parent,
                    .product-set-main-button-error,
                    .product-set-main-button-error,
                    .variation-selection-error-msg,
                    .product-set-product-button-error {
                        width: 100%;
                        padding-left: 0;
                        margin: 0;
                        font-size: 12px;
                        font-size: 3.8vw;
                    }
                    span.product-variations-toggle {
                        font-size: $base-font + 2;
                        font-family: $HelveticaBold;
                        line-height: normal;
                        font-size: 3.8vw;
                    }
                    &.main-product-set-details {
                        width: 100%;
                        padding: 0;
                        .product-price {
                            line-height: normal;
                            font-size: 14px;
                        }
                    }
                    .top-add-all.product-add-to-cart {
                        .all-set-button,
                        .product-set-main-button-parent {
                            display: none !important;
                        }
                    }
                    .availability-web {
                        font-size: $base-font + 1px;
                        @include WorkSansMedium($WorkSans);
                        letter-spacing: 0.5px;
                        line-height: normal;
                        .availability-msg {
                            p {
                                font-size: $base-font + 1px;
                                line-height: normal;
                            }
                        }
                    }
                    .view-details,
                    .product-id {
                        display: none;
                    }
                    .product-set-list {
                        .product-set-item {
                            padding-top: 15px;
                            padding-bottom: 25px;
                            .product-set-image,
                            .product-set-details {
                                width: 100%;
                                padding: 12px 0;
                                .product-price,
                                .price-standard,
                                .price-standard-exist {
                                    font-size: 14px;
                                    line-height: normal;
                                    font-size: 4.4vw;
                                }
                            }
                        }
                    }
                    .product-add-to-cart {
                        width: 100%;
                        padding: 0;
                    }
                }
            }
            .product-col-1 {
                &.giftcard-detail,
                &.e-giftcard-detail {
                    width: 100%;
                }
            }
            .product-col-2 {
                &.giftcard-detail,
                &.e-giftcard-detail {
                    width: 100%;
                    padding: 0;
                    .inventory {
                        width: 70px;
                    }
                    .fields-address {
                        .form-row {
                            .input-textarea {
                                width: 100%;
                            }
                        }
                    }
                }
            }
            .product-actions {
                a {
                    &.share-icon {
                        padding: 0 10px;
                    }
                }
            }
            .product-bundle {
                width: 100%;
                .product-review {
                    display: none;
                }
                .product-name {
                    display: none;
                }
                .promotion {
                    margin: 0;
                }
                &.product-col-1 {
                    width: 100%;
                    &.product-set {
                        width: 100%;
                        .product-thumbnails {
                            ul {
                                li {
                                    text-align: center;
                                    .thumbnail-link {
                                        .productthumbnail {
                                            border-bottom: none;
                                        }
                                    }
                                }
                                &.slick-dots {
                                    float: right;
                                    li {
                                        text-align: right;
                                        float: left;
                                        margin: 0 20px 0 0;
                                        button {
                                            font-size: 0;
                                            line-height: 0;
                                            display: block;
                                            width: 10px;
                                            height: 11px;
                                            padding: 5px;
                                            cursor: pointer;
                                            color: $very-light-gray;
                                            border: 0;
                                            outline: none;
                                            background: $very-light-gray;
                                            border-radius: 50%;
                                        }
                                        &.slick-active {
                                            button {
                                                background: $black;
                                                color: $black;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .product-col-2 {
                    &.product-detail {
                        padding: 0;
                        .product-price {
                            padding: 30px 0 0 0;
                            &.sps-ps-total-price {
                                padding: 10px 0 0 0;
                            }
                        }
                        .product-actions {
                            text-align: center;
                        }
                        .product-set-item {
                            padding: 15px 0 0 0;
                            .product-set-image {
                                width: 48.4%;
                            }
                            .product-set-details {
                                padding: 12px 0 0 0;
                            }
                            .product-variations-toggle {
                                display: none;
                            }
                            .product-variations {
                                display: block;
                                .attribute {
                                    padding: 0 0 35px 0;
                                    &:nth-child(2n) {
                                        padding: 0 0 40px 0;
                                    }
                                }
                            }
                        }
                        .product-actions {
                            .wishlist {
                                a {
                                    height: auto;
                                }
                            }
                        }
                        .product-add-to-cart {
                            margin: 0;
                            button {
                                width: 73%;
                                margin: 0;
                                border-top-left-radius: 0;
                                border-bottom-left-radius: 0;
                            }
                        }
                    }
                }
                .product-add-to-cart {
                    button {
                        width: 85%;
                    }
                }
                .product-actions {
                    a {
                        font-size: $base-font + 12px;
                    }
                }
            }
            .tabs {
                .tab {
                    width: 50%;
                    text-align: left;
                    float: left;
                    margin: 0;
                }
                .tab-content {
                    &.current {
                        display: inline-block;
                        width: 100%;
                        box-sizing: border-box;
                    }
                    .tab-content-details {
                        .tab-left,
                        .tab-right {
                            width: 100%;
                            padding: 0;
                        }
                    }
                }
            }
        }
        .pt_product-details {
            .pdp-main {
                .product-set {
                    &.product-col-1 {
                        &.product-bundle {
                            .product-primary-image {
                                .thumb {
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 480px) {
        .main-product-set-details {
            #thumbnails {
                padding-bottom: 25px;
            }
        }
        .product-set {
            .product-add-to-cart {
                .sub-product-item {
                    width: 220px;
                    width: calc(100% - 75px);
                }
            }
        }
        .pdp-main {
            .product-bundle {
                .bundle {
                    .product-add-to-cart {
                        button {
                            width: 73%;
                        }
                    }
                }
            }
        }
    }
}
#QuickViewDialog {
    .pdpForm {
        fieldset {
            width: 100%;
        }
    }
    .pdp-main {
        @include WorkSansMedium($WorkSans);
        margin: 0;
        .product-name {
            font-size: $base-font + 8px;
            line-height: 29px;
            font-style: normal;
            margin: 0;
        }
        .product-thumbnails,
        .enlarge-thumb-image {
            @media screen and (min-width: 768px) {
                &.slick-arrow-bar {
                    &::before {
                        top: 53px;
                    }
                }
                button {
                    &.slick-arrow {
                        &.slick-prev {
                            top: 65px;
                        }
                        &.slick-next {
                            top: 33px;
                        }
                    }
                }
            }
        }
        .product-price {
            font-size: $base-font + 4px;
            float: left;
            width: 100%;
            margin: 7px 0 10px;
            .price-standard {
                color: $SW-gray;
            }
            .price-standard-exist {
                color: $SW-navy-blue;
            }
        }
        .savingmessage {
            @include WorkSansBold($WorkSans);
            font-size: $base-font + 2px;
            letter-spacing: normal;
        }
        .product-actions {
            display: none;
        }
        .product-set {
            &.product-col-1 {
                padding: 46px 30px 0;
                width: 163px;
                left: 100%;
                right: auto;
                margin-left: -163px;
                box-sizing: border-box;
                background: $concrete;
                max-height: 100%;
                overflow: overlay;
                position: absolute;
                top: 0;
                height: 100%;
                vertical-align: top;
                .heading {
                    font-size: $base-font;
                    @include WorkSansMedium($WorkSans);
                }
                .product-thumbnails {
                    .thumb {
                        width: 100%;
                        a {
                            border: 0;
                            &:hover {
                                border: 0;
                            }
                            img {
                                border-bottom: 4px solid transparent;
                            }
                        }
                        &.selected {
                            a {
                                img {
                                    border-color: $black;
                                }
                            }
                        }
                    }
                }
            }
            .view-details {
                width: 79%;
            }
            &.product-col-2 {
                width: 83%;
                float: none;
                box-sizing: border-box;
                padding: 0 20px;
            }
            .product-set-list {
                .product-image-container,
                .product-set-image {
                    width: 40%;
                    .view-details,
                    .product-id {
                        display: none;
                    }
                }
                .product-set-details {
                    padding: 0 0 0 4%;
                    width: 100%;
                    display: block;
                    float: none;
                    .qv-productset-scrollbar {
                        padding: 13px 0 0;
                        display: inline-block;
                    }
                    .product-name {
                        font-size: $base-font + 12px;
                        line-height: 28px;
                    }
                    .product-price {
                        @include WorkSansMedium($WorkSans);
                    }
                    .product-variations {
                        .attribute {
                            .attribute_label {
                                .label {
                                    @include WorkSansRegular($WorkSans);
                                }
                                .selected-value {
                                    @include WorkSansBold($WorkSans);
                                }
                                .size-chart-link {
                                    @include WorkSansBold($WorkSans);
                                    text-transform: uppercase;
                                }
                            }
                        }
                        .swatches {
                            li {
                                a {
                                    @include WorkSansMedium($WorkSans);
                                    border-radius: 2px;
                                }
                            }
                            &.color {
                                li {
                                    a {
                                        border: none;
                                    }
                                    &.selected {
                                        a {
                                            border-radius: 50%;
                                            border: 1px solid $black;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .product-set-item {
                    display: none;
                    border-bottom: none;
                    padding-bottom: 0;
                    max-height: 550px;
                    &.selected {
                        display: inline-block;
                        padding-top: 0;
                    }
                }
            }
            .product-add-to-cart {
                padding: 0 40px;
                width: 100%;
                border: none;
                .availability-web {
                    display: block;
                    @include WorkSansRegular($WorkSans);
                }
                .wishlist {
                    width: 100%;
                    padding: 20px 0 0 0;
                    text-align: center;
                    display: inline-block;
                    @include WorkSansBold($WorkSans);
                    font-size: $base-font - 1px;
                    letter-spacing: 0.5px;
                    a {
                        .wishlist-logo {
                            display: inline-block;
                            line-height: 18px;
                            @include WorkSansBold($WorkSans);
                            background: url("../../../images/wishlist.svg")
                                no-repeat;
                            width: auto;
                            height: 18px;
                            padding-left: 30px;
                            &.added-to-wishlist {
                                background: url("../../../images/wishlist-solid.svg")
                                    no-repeat;
                            }
                        }
                    }
                }
            }
        }
    }
    .product-col-1 {
        .product-primary-image {
            height: 393px;
        }
        .b_product_badge {
            position: absolute;
            z-index: 9;
            top: 0;
            left: 0;
        }
    }
    .product-col-2 {
        .product-name {
            .item-name {
                font-size: $base-font + 12px;
            }
        }
        .e-giftcard-detail,
        .giftcard-detail {
            .product-add-to-cart {
                .add-to-cart {
                    @media screen and (max-width: 767px) {
                        width: 100%;
                    }
                }
                .wishlist {
                    margin-bottom: 20px;
                }
            }
        }
        .attribute {
            .attribute_label {
                .label {
                    text-transform: capitalize;
                }
                span.selected-value {
                    text-transform: capitalize;
                    font-size: $base-font + 1px !important;
                }
                .final-sale-color-msg {
                    font-size: $base-font + 1px !important;
                }
            }
        }
        .promotion {
            font-size: $base-font + 2px;
            width: 100%;
            .promotion-title {
                font-weight: normal;
            }
            .promotion-callout {
                font-size: $base-font;
            }
        }
        .inventory {
            select {
                @include WorkSansBold($WorkSans);
                font-size: $base-font + 3px;
            }
        }
    }
    .pdp-main {
        &.specialproductset {
            .product-actions {
                .socialsharing {
                    display: none;
                }
            }
            .promotion {
                display: inline-block;
                margin: 10px 0 0 0;
            }
            .product-primary-image {
                height: 100%;
            }
            .product-price {
                padding: 0 0 15px 0;
                margin: 0;
            }
            .savingmessage {
                padding: 0 0 15px 0;
            }
            .product-bundle-main {
                width: auto;
                float: none;
                .product-col-2 {
                    width: 100%;
                    padding: 0;
                    border: 0;
                    .product-name {
                        word-break: break-word;
                        margin: 0 0 14px 0;
                    }
                    .product-add-to-cart {
                        button {
                            width: 73%;
                        }
                    }
                    .product-set-list {
                        padding: 0;
                        box-sizing: border-box;
                        float: left;
                        .product-set-item {
                            display: block;
                            max-height: none;
                            border-bottom: 6px solid $light-gray;
                            box-sizing: border-box;
                            padding-right: 10px;
                            &.selected {
                                border-top: 1px solid $light-gray;
                            }
                        }
                        .product-set-details {
                            width: 100%;
                            padding: 0;
                        }
                    }
                }
                form {
                    width: 100%;
                    .product-add-to-cart {
                        width: 100%;
                        padding: 10px 0 20px 0;
                        margin: 0;
                        border-top: 1px solid $light-gray;
                    }
                }
                .product-actions {
                    display: block;
                    border: none;
                    .product-add-to-cart {
                        width: 100%;
                        padding: 0 0 30px 0;
                        margin: 0;
                        .wishlist {
                            padding: 0;
                        }
                    }
                }
                .view-details {
                    width: 93%;
                    padding: 0 0 20px 0;
                }
            }
        }
    }
}
.product-variations {
    .attribute:last-child {
        border-bottom: none;
    }
}
.ui-dialog {
    &.product-set-quickview {
        &.quick-view {
            .ui-dialog-content {
                padding: 0;
            }
        }
        #QuickViewDialog {
            .pdp-main {
                .product-set {
                    .product-add-to-cart {
                        padding: 20px 0 0 0;
                    }
                    &.product-col-1 {
                        height: 108%;
                        .product-thumbnails {
                            .thumb {
                                padding: 0 0;
                                margin: 0 0;
                                text-align: center;
                            }
                        }
                    }
                    #thumbnails {
                        width: 100%;
                    }
                }
            }
        }
        .qv-productset-scrollbar {
            display: inline-block;
            width: 96% !important;
        }
    }
    &.quick-view {
        overflow: visible;
        .ui-dialog-content {
            position: relative;
            overflow: visible;
            .pdp-main {
                .product-col-1 {
                    .enlarge-video-sec {
                        .enlarge-video {
                            &.video-link {
                                float: left;
                            }
                        }
                    }
                    .promotionalbanner {
                        display: none;
                    }
                }
                .top-wrap,
                .info {
                    padding: 0;
                }
                .product-variations {
                    .attribute {
                        border-top: 1px solid $alto;
                        border-bottom: none;
                        &.variant-dropdown {
                            border-top: none;
                        }
                    }
                }
                .product-monogramming,
                .product-hemmable {
                    border-bottom: none;
                }
                .product-col-2 {
                    padding: 0 5% 0 0;
                    input[type="checkbox"] {
                        margin: 0 0 0 5px;
                    }
                    .product-special-messages {
                        padding: 20px 0 0 0;
                    }
                    .tab-content {
                        ul {
                            padding: 0 0 0 15px;
                        }
                    }
                    &.giftcard-detail {
                        .gift-card {
                            border-top: none;
                        }
                        .product-add-to-cart {
                            .add-to-cart {
                                width: 74%;
                            }
                            .wishlist {
                                margin-bottom: 20px;
                            }
                        }
                    }
                    &.e-giftcard-detail {
                        .gift-card {
                            border-top: none;
                        }
                        .product-add-to-cart {
                            .add-to-cart {
                                width: 100%;
                            }
                            .wishlist {
                                margin-bottom: 20px;
                            }
                        }
                    }
                    .product-add-to-cart {
                        padding: 20px 0 30px;
                        border-top: 1px solid $alto;
                        border-bottom: none;
                        .inventory {
                            width: 80px;
                        }
                        button {
                            padding: 0 27px;
                            width: 280px;
                            height: 50px;
                        }
                        .wishlist {
                            width: 100%;
                            padding: 20px 0 2px 0;
                            margin: 0 auto;
                            text-align: center;
                            a {
                                display: inline-block;
                                span {
                                    float: none;
                                    vertical-align: middle;
                                }
                            }
                        }
                        .fields-address {
                            .form-row {
                                &:nth-child(3) {
                                    width: 100%;
                                }
                                .input-textarea {
                                    width: 100%;
                                }
                            }
                        }
                        .availability-web {
                            padding: 2px 0 12px;
                        }
                    }
                    .product-variation-content {
                        max-height: 400px;
                        overflow: auto;
                        width: 100%;
                        float: left;
                        padding-right: 13px;
                    }
                }
            }
        }
    }
}
.pt_product-details {
    #main {
        .pdp-main {
            .product-col-2 {
                &.e-giftcard-detail {
                    .product-add-to-cart {
                        .add-to-cart {
                            @media screen and (max-width: 480px) {
                                width: 100%;
                            }
                        }
                    }
                }
            }
            .product-add-to-cart {
                .inventory {
                    .custom-select {
                        .selected-option {
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 0;
                            padding: 0 21px;
                            border-width: 2px;
                            @include GothamMedium($Gotham);
                            font-size: $base-font + 3px;
                            letter-spacing: 1px;
                            color: $SW-navy-blue;
                            border: 2px solid $SW-navy-blue;
                        }
                        .selection-list {
                            border-width: 2px;
                        }
                    }
                }
            }
            .product-monogramming {
                select,
                input,
                textarea {
                    font-family: $WorkSans;
                    font-size: 12px;
                }
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .pt_product-details {
        .pdp-main {
            &.specialproductset {
                .product-set {
                    &.product-col-2 {
                        &.main-product-set-details {
                            .product-set-list {
                                .product-set-item {
                                    .product-variations {
                                        display: block;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1023px) {
    .enlarge-dialog {
        &.ui-dialog {
            top: 0 !important;
            position: fixed !important;
            .pinch-zoom-container {
                a {
                    left: 0;
                    right: 0;
                    margin: auto;
                    text-align: center;
                    .productthumbnail {
                        max-height: 100%;
                        display: inline-block;
                    }
                }
            }
        }
    }
}
#QuickViewDialog .view-details a {
    @include GothamBold($Gotham);
    font-size: $base-font + 4px;
    color: $SW-navy-blue;
    letter-spacing: 1px;
}
#QuickViewDialog .product-add-to-cart {
    .custom-select .selected-option {
        @include GothamMedium($Gotham);
        font-size: $base-font + 3px;
        color: $SW-navy-blue;
        letter-spacing: 1px;
        border: 1px solid $SW-navy-blue;
    }
}
.TTratingBox {
    height: auto;
}
.TT3ShowMore span.TT3ShowMoreText {
    letter-spacing: 0.5px;
    margin-left: 15px;
}
/*------- SPS Product Specific Changes ----------*/
.pt_product-details #main {
    .specialproductset {
        &.sw-specialproductset {
            .product-add-to-cart {
                padding-top: 30px;
                border-top: 1px solid #e1e1e1;
                @media screen and (max-width: 767px) {
                    border-top: 1px solid #958faa;
                }
                button {
                    width: 290px;
                    text-align: center;
                    height: 52px;
                    @media screen and (max-width: 767px) {
                        font-size: 14px;
                        font-weight: 900;
                        letter-spacing: 2px;
                        padding: 0 30px;
                    }
                }
                .content-asset {
                    #shippinginfoBtn {
                        width: auto;
                        @media screen and (max-width: 767px) {
                            padding: 0;
                        }
                    }
                }
                button[disabled="disabled"] {
                    font-size: 14px;
                    @media screen and (max-width: 1023px) {
                        font-size: 12px;
                    }
                    background-color: #666666 !important;
                    color: #fff;
                }
                .shippingdetails {
                    border-top: 1px solid $manatee;
                    border-bottom: 1px solid $manatee;
                    @media screen and (max-width: 767px) {
                        border-top: 1px solid #958faa;
                    }
                }
                .custom-select select {
                    height: 52px;
                    font-weight: bold;
                }
            }
            &.pdp-main {
                .turntofit {
                    font-size: 12px;
                    font-weight: 600;
                    letter-spacing: 0.46px;
                    line-height: 18px;
                    margin: 0;
                    @media screen and (max-width: 767px) {
                        font-size: 11px;
                    }
                }
                .product-name.is-product-brand {
                    margin: 0;
                    @media screen and (max-width: 767px) {
                        font-size: 20px;
                        line-height: 20px;
                        letter-spacing: 0;
                        margin-bottom: 5px;
                    }
                }
                .product-brand {
                    @include WorkSansSemiBold($WorkSans);
                    @media screen and (max-width: 767px) {
                        font-size: 10px;
                        font-weight: 400;
                    }
                }
                .product-name.is-product-brand {
                    margin-bottom: 0;
                    line-height: inherit;
                }
                .product-brand {
                    @include WorkSansSemiBold($WorkSans);
                    letter-spacing: 0.43px;
                }
                .product-price {
                    @include GothamMedium($Gotham);
                    font-size: $base-font + 8px;
                    letter-spacing: 0.5px;
                    color: $SW-navy-blue;
                    @media screen and (max-width: 767px) {
                        .sps-ps-total-price {
                            padding: 10px 0 0;
                        }
                    }
                    span {
                        margin: 10px 0 0 0;
                        &.price-standard-exist {
                            font-size: 20px;
                        }
                        @media screen and (max-width: 767px) {
                            margin: 0;
                            line-height: 42px;
                        }
                    }
                    .price-standard {
                        @include GothamMedium($Gotham);
                        font-size: $base-font + 4px;
                        letter-spacing: 0.5px;
                        color: $SW-gray;
                        padding-right: 9px !important;
                    }
                }
                .product-set-list {
                    border-top: 0;
                    @media screen and (max-width: 767px) {
                        border-top: 0;
                    }
                    margin-top: 0px;
                    .availability-web b {
                        font-size: 12px;
                        font-weight: 600;
                        letter-spacing: 0.46px;
                        color: #ba1616;
                    }
                    .product-set-item {
                        border-bottom: 0;
                        padding: 0;
                        .product-variations {
                            margin-bottom: 18px;
                            .availability-web {
                                padding: 0;
                                .availability-msg {
                                    .backorder-msg {
                                        color: #ba1616;
                                        font-weight: 600;
                                        font-size: 12px;
                                        line-height: normal;
                                        font-style: normal;
                                        font-stretch: normal;
                                        letter-spacing: 0.46px;
                                        @media screen and (max-width: 767px) {
                                            font-size: 11px;
                                            letter-spacing: 0.42px;
                                        }
                                    }
                                }
                            }
                            ul {
                                li {
                                    &.attribute {
                                        padding: 0 0 4px 0;
                                        &.color {
                                            padding: 10px 0 12px 0;
                                            margin-bottom: 18px;
                                            border-top: 1px solid #e1e1e1;
                                            border-bottom: 1px solid #e1e1e1;
                                            @media screen and (max-width: 767px) {
                                                border-top: 1px solid #958faa;
                                                border-bottom: 1px solid #958faa;
                                            }
                                            #current_color {
                                                display: none;
                                            }
                                            &.hide {
                                                display: none;
                                            }
                                            li {
                                                a {
                                                    width: 32px;
                                                    height: 32px;
                                                    line-height: 32px;
                                                    min-width: 32px;
                                                    border: 1px solid $mortar-grey;
                                                    @media screen and (max-width: 767px) {
                                                        width: 22px;
                                                        height: 22px;
                                                        min-width: 22px;
                                                        line-height: 22px;
                                                    }
                                                    img {
                                                        border: 1px solid
                                                            #979797;
                                                    }
                                                }
                                            }
                                            #current_color {
                                                display: none;
                                            }
                                            .value {
                                                margin-top: 0;
                                            }
                                        }
                                        &.size {
                                            .attribute_label {
                                                margin: 0 0 14px;
                                            }
                                        }
                                        .attribute_label {
                                            .attribute_label_left {
                                                width: 60%;
                                            }
                                            h3 {
                                                &.label {
                                                    text-transform: uppercase;
                                                    span {
                                                        text-transform: capitalize;
                                                    }
                                                }
                                                @media screen and (max-width: 767px) {
                                                    &.label {
                                                        font-size: 13px !important;
                                                        letter-spacing: 0.43px !important;
                                                        line-height: normal;
                                                        span {
                                                            text-transform: capitalize;
                                                        }
                                                    }
                                                }
                                            }
                                            .label,
                                            .selected-value {
                                                @media screen and (min-width: 768px) and (max-width: 1023px) {
                                                    max-width: 210px;
                                                    span {
                                                        white-space: normal;
                                                    }
                                                }
                                                @media screen and (max-width: 767px) {
                                                    max-width: 210px;
                                                    span {
                                                        white-space: normal;
                                                    }
                                                }
                                            }
                                            .selected-value {
                                                @include GothamBook($Gotham);
                                                font-size: $base-font + 1px;
                                                letter-spacing: 0.5px;
                                                color: $SW-navy-blue;
                                            }
                                            .size-chart-link {
                                                font-size: 12px;
                                                letter-spacing: 0.46px;
                                                font-weight: 500;
                                                span.size_icon {
                                                    padding-top: 2px;
                                                }
                                            }
                                            &:last-child {
                                                padding: 0;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 768px) {
    .product-bundle .product-image.main-image {
        min-height: 400px;
    }
}
.pt_product-details #main {
    .specialproductset.sw-specialproductset.pdp-main {
        .product-set-list .product-set-item {
            .product-variations ul li.attribute {
                .attribute_label h3.label {
                    span {
                        @include GothamBold($Gotham);
                        font-size: $base-font + 1px;
                        letter-spacing: 0.5px;
                        color: $SW-navy-blue;
                    }
                }
            }
        }
    }
}
#wrapper
    #main
    .pdp-main
    .pdp-horizontal
    .recommendations-h
    .recommendations-heading {
    color: $SW-navy-blue;
    opacity: 1;
}
/*SC-8518*/
#QuickViewDialog
    .prod-detail-page
    .pdp-max-width
    .product-variations
    ul.swatches.color
    li
    a
    img {
    vertical-align: inherit;
}
/*SC-7581*/
@media only screen and (width: 768px) {
    .TTratingLinks {
        font-size: 9px;
    }
}

 
.legacy-sitegen .tt-o-button--link,.legacy-sitegen button.tt-o-button--link {
    background-color: transparent;
    border: none;
    padding: 0;
    color: $SW-navy-blue;
    font-family: gbook,Helvetica Neue,Helvetica,Arial,Lucida Grande,sans-serif;
    font-weight: 400;
    font-size: .75rem;
    line-height: 1.3;
    text-transform: none;
    letter-spacing: normal;
    text-decoration: underline;
}

.legacy-sitegen .tt-o-button--toolbar,.legacy-sitegen button.tt-o-button--toolbar {
    border: none;
    padding: 0.25rem 0;
    border-radius: 0;
    font-family: gbook,Helvetica Neue,Helvetica,Arial,Lucida Grande,sans-serif;
    font-size: .75rem;
    font-weight: 400;
    text-transform: none;
    letter-spacing: 0;
    line-height: 1;
    background-color: transparent;
    color: $SW-navy-blue;
    fill: $SW-navy-blue;
}

.legacy-sitegen .tt-o-button--icon,
.legacy-sitegen button.tt-o-button--icon,
.legacy-sitegen .tt-o-pagination .tt-o-button--icon,
.legacy-sitegen .tt-o-pagination button.tt-o-button--icon {
    border: none;
    padding: 0.1875rem;
    background-color: transparent !important;
    fill: $SW-navy-blue;
}

.legacy-sitegen .pt_product-details #main .specialproductset .product-add-to-cart .quantity.custom-select {
    height: 52px;
}

.enlarge-hero-container .thumb .thumbnail-link {
    outline-offset: -3px;
}