.ui-dialog {
    &.cart-overlay {
        .legacy-sitegen .addtocartoverlay-products {
            .mini-cart-product {
                .right-content {
                    .finalSale-sps {
                        font-weight: normal;
                        font-size: 12px;
                    }
                    .mini-cart-name {
                        font-size: $base-font + 8px;
                        @include GothamMedium($Gotham);
                        letter-spacing: 1px;
                        color: $sw-brand-blue;
                        text-transform: uppercase;
                        a {
                            color: $sw-brand-blue;
                        }
                    }
                    .label,
                    .mini-cart-subtotals {
                        @include GothamBold($Gotham);
                        font-size: $base-font + 1px;
                        letter-spacing: 2px;
                        color: $sw-brand-blue;
                    }
                    .value,
                    .on-order,
                    .product-price {
                        @include GothamMedium($Gotham);
                        font-size: $base-font;
                        letter-spacing: 1px;
                        color: $sw-brand-blue;
                    }
                    .mini-cart-brand {
                        @include Gotham($Gotham);
                    }
                    .price-standard {
                        @include Gotham($Gotham);
                        margin: 0 5px 0 0;
                        text-decoration: line-through;
                        @include text-properties(1px, $SW-gray, $base-font);
                    }
                    .price-sales.price-standard-exist {
                        @include GothamBold($Gotham);
                        @include text-properties(1px, $SW-navy-blue, $base-font);
                    }
                }
            }
        }
    }
}
