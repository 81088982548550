// Order History - Order Details Styling
.pt_order,
.confirmation {
    .actions {
        padding: 0.5em 1%;
        a {
            margin-right: 2rem;
        }
    }
    .make-label-absolute label.input-focus {
        transform: none;
        span {
            font-size: 14px;
            transition: none;
        }
    }
}
.catalog-item-number {
    font-family: $WorkSans;
    font-size: $base-font + 1px;
    letter-spacing: 0.5px;
    color: $dim-gray;
}
.pt_order {
    .primary-content {
        width: 100%;
        float: left;
    }
    #secondary {
        .secondary-navigation {
            @media screen and (min-width: 768px) {
                margin-bottom: 37px;
            }
        }
    }
    .order-payment-summary {
        .actions {
            float: left;
        }
    }
    .internation-shipment-method {
        font-size: $base-font + 2px;
        font-family: $WorkSans;
        background: $light-black-two;
        color: $white;
        text-align: center;
        line-height: 18px;
        padding: 6px 0 8px;
        width: 100%;
        display: inline-block;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        @media screen and (max-width: 767px) {
            text-align: left;
            padding: 6px 9px 8px;
            .shipping-method {
                margin-right: 15px;
            }
        }
    }
    .order-details-section {
        .qa-section {
            max-width: 1020px;
            margin: 0 auto;
            display: block;
            .question-title {
                text-transform: inherit;
                padding: 25px 40px;
            }
            .qa-content {
                padding: 25px 40px;
                @include WorkSansBold($WorkSans);
                font-size: $base-font;
                .question {
                    letter-spacing: 0.5px;
                }
                .answer {
                    font-size: $base-font + 2px;
                    padding: 18px 0 0 0;
                }
            }
        }
    }
    .orderdetails {
        margin: 25px auto 60px;
        .heading {
            @include WorkSansSemiBold($WorkSans);
            width: 100%;
            margin-bottom: 10px;
            text-transform: capitalize;
            display: inline-block;
        }
        .actions {
            padding: 0;
            margin-top: 35px;
            a {
                padding: 16px 36px;
                text-transform: uppercase;
                letter-spacing: 2px;
                font-size: $base-font + 2px;
                @include WorkSansSemiBold($WorkSans);
                border-color: $black;
                color: $black;
                border-radius: 3px;
            }
        }
        .optioninfo {
            .option-info {
                font-family: $Helvetica;
                font-size: $base-font;
                color: $mangotango;
                span {
                    text-transform: capitalize;
                    letter-spacing: 0;
                }
            }
        }
        .order-information {
            padding: 5px 0 0 0;
            .order-print {
                h1 {
                    font-size: $base-font + 10px;
                    @include WorkSansMedium($WorkSans);
                    margin: 0 0 10px 0;
                    letter-spacing: normal;
                }
            }
            .order-status {
                width: auto;
                display: inline-block;
                margin: 13px 0 0 3px;
                .order-status {
                    display: none;
                }
                .mail-box {
                    background: url("../../../images/mail-black.svg") no-repeat;
                    float: left;
                }
                .receipt-message {
                    @include WorkSansMedium($WorkSans);
                    font-size: $base-font + 1px;
                    margin: 0 0 0 8px;
                    float: left;
                    line-height: 14px;
                }
            }
        }
        .order-detail-section {
            padding: 16px;
            .section {
                width: 33%;
                .heading {
                    margin: 0 0 10px 0;
                    text-transform: capitalize;
                }
                .value {
                    margin: 0 0 10px 0;
                    display: inline-block;
                    width: 100%;
                }
            }
            &.order-detail-response {
                .order-status {
                    width: 25%;
                }
                span {
                    &.img {
                        float: right;
                        margin-left: 8px;
                    }
                }
                .order-payment-instruments {
                    width: 32%;
                    .inner-block {
                        max-width: 200px;
                    }
                }
                .order-coupon-reward {
                    width: 20%;
                }
                .order-billing {
                    width: auto;
                }
            }
            .order-payment-instruments {
                label {
                    line-height: 18px;
                }
                &.section {
                    width: 31.6%;
                }
            }
        }
        .order-shipments {
            .order-shipment-table {
                &.e-gift-card {
                    .order-shipment-section {
                        .method {
                            width: 86%;
                            float: left;
                            @media screen and (max-width: 1023px) {
                                width: 84%;
                            }
                            @media screen and (max-width: 767px) {
                                width: 100%;
                            }
                            .shipment-method {
                                max-width: 700px;
                                @media screen and (max-width: 767px) {
                                    max-width: 100%;
                                    margin-left: 0;
                                }
                                .value {
                                    padding-bottom: 12px;
                                    padding-left: 5px;
                                }
                            }
                        }
                    }
                    @media screen and (min-width: 768px) and (max-width: 1023px) {
                        .line-items {
                            .line-item {
                                .col-1 {
                                    .item-details {
                                        .line-item-quantity {
                                            display: none;
                                            &.gift-card {
                                                display: block;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                @media screen and (min-width: 768px) and (max-width: 1023px) {
                    &.shipping-exception {
                        .order-shipment-section {
                            .order-shipment-address {
                                width: 80%;
                            }
                            .order-shipping-status {
                                width: 20%;
                            }
                        }
                    }
                }
                &.gift-card {
                    .order-shipment-details {
                        .method {
                            width: 20%;
                            float: left;
                            max-width: 160px;
                            @media screen and (max-width: 767px) {
                                width: 100%;
                                max-width: 100%;
                            }
                        }
                        @media screen and (max-width: 1023px) {
                            .order-shipment-address {
                                width: 80%;
                            }
                            .order-shipping-status {
                                width: 20%;
                            }
                        }
                        .order-shipment-section {
                            width: 80%;
                            float: left;
                            @media screen and (max-width: 767px) {
                                width: 100%;
                            }
                        }
                    }
                    @media screen and (min-width: 768px) and (max-width: 1023px) {
                        .line-items {
                            .line-item {
                                .col-1 {
                                    .item-details {
                                        .line-item-quantity {
                                            display: none;
                                            &.gift-card {
                                                display: block;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .order-shipment-details {
                        .method {
                            font-size: $base-font + 1px;
                            letter-spacing: 1px;
                            @media screen and (max-width: 767px) {
                                font-size: $base-font - 1px;
                            }
                            .shipment-method {
                                .value {
                                    @media screen and (min-width: 768px) {
                                        padding-left: 5px;
                                    }
                                }
                            }
                        }
                    }
                }
                &.shipping-exception {
                    .order-shipment-details {
                        .value {
                            @include WorkSansMedium($WorkSans);
                            font-size: $base-font;
                        }
                    }
                }
            }
            .shipmentnumber {
                padding: 0 0 14px 2px;
                border-bottom: 1px solid $alto;
                h2 {
                    padding: 0;
                    border: 0;
                    width: auto;
                    float: left;
                    font-size: $base-font + 12px;
                    @include WorkSansMedium($WorkSans);
                    letter-spacing: normal;
                }
                .trackingnumber {
                    font-size: $base-font;
                    @include WorkSansSemiBold($WorkSans);
                    text-transform: uppercase;
                    line-height: 30px;
                    letter-spacing: 1px;
                    .value {
                        font-family: $HelveticaMedium;
                        color: $black;
                    }
                }
            }
            .order-shipment-section {
                font-size: $base-font + 1px;
                text-transform: capitalize;
                @include WorkSansMedium($WorkSans);
                .heading {
                    @include WorkSansSemiBold($WorkSans);
                }
                .method,
                .order-shipping-status {
                    width: 15%;
                    float: left;
                    .shipment-method {
                        max-width: 100px;
                    }
                }
                .order-shipping-status {
                    width: 14%;
                    .heading {
                        margin-left: 0;
                    }
                }
                .order-shipment-address {
                    width: 86%;
                    float: left;
                    .shipment-address {
                        max-width: 730px;
                    }
                }
            }
            .line-item {
                width: 100%;
                display: inline-block;
                border-top: 0px;
                padding: 20px;
                box-sizing: border-box;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                .col-1 {
                    max-width: 500px;
                    width: 50%;
                    margin: 0;
                    float: left;
                    .line-item-price {
                        display: none;
                    }
                    .item-image {
                        width: 100px;
                        text-align: center;
                        @media screen and (max-width: 767px) {
                            width: 37%;
                        }
                    }
                    .item-details {
                        float: left;
                        max-width: 66%;
                        padding-left: 38px;
                        box-sizing: border-box;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        font-size: $base-font - 1px;
                        @media screen and (max-width: 1024px) {
                            width: 63%;
                            max-width: 63%;
                        }
                        .giftmessage {
                            word-break: break-word;
                        }
                    }
                    .quick-order-badge {
                        display: none;
                        @media screen and (max-width: 767px) {
                            display: block;
                            width: 100% !important;
                            margin: 0;
                        }
                    }
                }
                .col-2 {
                    max-width: 196px;
                    width: 21.6%;
                    margin: 54px 0 0 0;
                    float: left;
                    .line-item-quantity {
                        font-size: $base-font - 1px;
                        @include WorkSansMedium($WorkSans);
                        text-transform: uppercase;
                        span {
                            width: auto;
                            display: inline-block;
                            &.label {
                                @include WorkSansSemiBold($WorkSans);
                            }
                        }
                    }
                }
                .col-3 {
                    max-width: 180px;
                    width: 16.7%;
                    margin: 54px 0 0 0;
                    float: left;
                    .label {
                        display: none;
                    }
                    .line-item-price {
                        font-size: $base-font + 2px;
                        @include WorkSansMedium($WorkSans);
                        display: inline-block;
                        &.discount-price {
                            color: $RedRibbon;
                        }
                    }
                }
                .col-4 {
                    text-align: right;
                    width: 30%;
                    font-size: $base-font - 1px;
                    text-transform: uppercase;
                    @include WorkSansSemiBold($WorkSans);
                    letter-spacing: 1px;
                    float: left;
                    &.quick-order-item {
                        margin: 0;
                        .details-review {
                            margin-top: 15px;
                            display: inline-block;
                        }
                    }
                    .buy-again {
                        margin-top: 9px;
                    }
                    .quick-order-badge {
                        &.quick-order-badge-confirmation {
                            width: 95px !important;
                            text-align: right !important;
                            float: right !important;
                        }
                        .quick-order-badge-link {
                            padding-right: 18px;
                            margin-bottom: 5px;
                            .heading {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
        .mini-address-name {
            margin: 0 0 5px 0;
        }
        .mini-address-location {
            address {
                font-style: normal;
                line-height: 25px;
            }
        }
    }
    //order History page
    .order-history {
        margin: 30px auto 20px;
        @media screen and (max-width: 767px) {
            margin-top: 0;
        }
        .international-orders {
            .check-order {
                display: block;
                .form-row {
                    display: inline-block;
                    width: 27%;
                    padding: 0 47px 0 0;
                    label {
                        padding: 0 0 16px 0;
                        text-transform: uppercase;
                        span {
                            @include WorkSansBold($WorkSans);
                            color: $black;
                            font-size: 12px;
                            &.error {
                                color: #af3434;
                            }
                        }
                    }
                    .field-wrapper {
                        border-bottom: 4px solid #000;
                        input {
                            border: 1px solid #ebebef;
                        }
                    }
                    @media screen and (max-width: 768px) {
                        width: 100%;
                        padding: 0;
                    }
                }
            }
            .error-form {
                border: none;
                background-color: transparent;
            }
            button {
                background: $white;
                border: 2px solid $SW-navy-blue;
                color: $SW-navy-blue;
                letter-spacing: 2px;
                font-size: $base-font + 4px;
                @media screen and (max-width: 768px) {
                    float: right;
                }
            }
        }
        .subheading {
            font-size: $base-font + 1px;
            @include GothamBook($GothamBook);
            letter-spacing: 0;
            color: $SW-gray;
        }
        .top-content {
            .no_orders {
                font-size: $base-font + 1px;
                @include GothamBook($GothamBook);
                letter-spacing: 0;
                color: $SW-gray;
            }
        }
        .ordertrack-form-content-bottom {
            padding-top: 30px;
        }
        h1 {
            @include GothamBold($Gotham);
            font-size: $base-font + 10px;
            letter-spacing: 2px;
            color: $SW-navy-blue;
            text-transform: uppercase;
            &.account-subpage-heading {
                font-size: $base-font + 10px;
            }
        }
        .order-history-table {
            .order-history-header {
                @include WorkSansSemiBold($WorkSans);
            }
            .order-list-wrapper {
                @include WorkSansMedium($WorkSans);
                .order-list {
                    padding: 30px 20px 38px;
                }
                .col-last {
                    padding-top: 2px;
                }
                .button-text {
                    @include WorkSansSemiBold($WorkSans);
                    color: inherit;
                }
            }
        }
    }
}
.confirmation-left-section:not(.ot) {
    max-width: 840px;
    float: left;
    margin-top: 20px;
    width: 75%;
    h1 {
        font-size: $base-font + 8px;
        @include GothamBold($Gotham);
        margin: 0;
        letter-spacing: 1px;
        color: $s4a-navy-blue;
        text-transform: uppercase;
    }
    .specialmessaging {
        clear: both;
        color: black;
        margin: 0;
    }
}
.confirmation-message {
    border-bottom: 1px solid $alto;
    padding: 25px 0 20px 0;
}
.order-confirmation-details {
    .order-print {
        width: 100%;
        display: inline-block;
        padding: 30px 0;
        .order-number {
            float: left;
            @include GothamMedium($Gotham);
            text-transform: none;
        }
        .print-page {
            border: none;
            float: right;
            padding: 10px 35px;
            font-size: $base-font - 1px;
            @include GothamBold($Gotham);
            background-color: $white;
            color: $s4a-navy-blue;
            letter-spacing: 0.5px;
            text-transform: none;
        }
    }
}
.orderdetails {
    .notshipped-message {
        @include WorkSansMedium($WorkSans);
        font-size: $base-font + 2px;
        color: $mangotango;
        border-top: 1px solid $alto;
        width: 100%;
        display: inline-block;
        padding: 19px 0 0 0;
        margin: 10px 0 0 0;
    }
    .login-create-account {
        h1 {
            margin: 0 0 20px 0;
            @include text-properties(1px, $SW-navy-blue, $base-font + 8px);
            @include GothamMedium($Gotham);
            text-transform: none;
        }
        .login-box-content {
            padding: 30px;
            box-sizing: border-box;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            background: $sw-heading-bg;
        }
        .login-box-left-content {
            width: 55%;
            display: inline-block;
            vertical-align: top;
            .inner-block {
                max-width: 385px;
                @include text-properties(0.5px, $SW-navy-blue, $base-font);
                @include GothamMedium($Gotham);
                h2 {
                    text-transform: inherit;
                    margin: 0 0 20px 0;
                    @include text-properties(
                        0px,
                        $SW-navy-blue,
                        $base-font + 1px
                    );
                    @include GothamBold($GothamBold);
                    border-top: none;
                    padding-top: 0;
                }
                h3 {
                    text-transform: inherit;
                    @include WorkSansSemiBold($WorkSans);
                    font-size: $base-font + 1px;
                    margin-top: 0;
                }
            }
        }
        .login-box-right-content {
            width: 44%;
            display: inline-block;
            .inner-block {
                max-width: 295px;
                &.make-label-absolute {
                    .password-status {
                        span {
                            @include text-properties(
                                0.5px,
                                $SW-navy-blue,
                                $base-font - 1px
                            );
                            @include GothamBold($GothamBold);
                        }
                    }
                    .form-row {
                        position: relative;
                    }
                    label {
                        font-weight: normal;
                        font-size: $base-font;
                        padding: 0;
                        position: absolute;
                        -webkit-transform: translate(5px, 20px);
                        -ms-transform: translate(5px, 20px);
                        transform: translate(5px, 20px);
                        left: 12px;
                        margin: 0;
                        top: -3px;
                        width: auto;
                        color: $dim-gray;
                        z-index: 1;
                        -webkit-transition: -webkit-transform 0.3s ease,
                            font-size 0.3s, color 0.3s;
                        -ms-transition: -webkit-transform 0.3s ease,
                            font-size 0.3s, color 0.3s;
                        transition: transform 0.3s ease, font-size 0.3s,
                            color 0.3s;
                        &.input-focus {
                            -webkit-transform: translate(5px, 11px);
                            -ms-transform: translate(5px, 11px);
                            transform: translate(5px, 11px);
                        }
                        span {
                            @include WorkSansSemiBold($WorkSans);
                            text-transform: capitalize;
                            &.required-indicator {
                                display: none;
                            }
                        }
                    }
                    .field-wrapper {
                        box-sizing: border-box;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        margin-bottom: 20px;
                        width: 100%;
                        input[type="text"],
                        input[type="number"],
                        input[type="password"],
                        input[type="date"] textarea {
                            padding: 20px 15px 14px 15px;
                            height: auto;
                            border-radius: 0;
                            @include text-properties(
                                1px,
                                $SW-navy-blue,
                                $base-font
                            );
                            @include GothamMedium($Gotham);
                            border: 1px solid $SW-navy-blue;
                        }
                        .pwd-show {
                            @include WorkSansSemiBold($WorkSans);
                        }
                    }
                }
            }
        }
    }
}
.plcc-user {
    p {
        margin: 0 0 15px 0;
        @include text-properties(0.5px, $SW-navy-blue, $base-font);
        @include GothamMedium($Gotham);
    }
    ul {
        margin: 0;
        @media screen and (max-width: 767px) {
            padding: 0 0 0 16px;
        }
        li {
            list-style: inherit;
            @include text-properties(0.5px, $SW-navy-blue, $base-font);
            @include GothamMedium($Gotham);
        }
    }
}
.order-detail-section {
    width: 100%;
    display: inline-block;
    padding: 35px 45px;
    box-sizing: border-box;
    background: $white;
    box-shadow: -2px 6px 30px 8px $white-smoke;
    margin: 35px 0;
    .section {
        width: 25%;
        float: left;
        .inner-block {
            max-width: 155px;
            @include text-properties(0px, $SW-navy-blue, $base-font + 1px);
            @include GothamBook($GothamBook);
            word-wrap: break-word;
            .inner-block-section {
                margin: 25px 0 0 0;
                &.method {
                    margin-top: 15px;
                }
            }
            .label {
                &.heading {
                    @include text-properties(
                        0px,
                        $SW-navy-blue,
                        $base-font + 1px
                    );
                    @include GothamBold($GothamBold);
                }
            }
        }
    }
}
.order-shipments {
    .order-shipment-details {
        h2 {
            @include text-properties(0.5px, $SW-navy-blue, $base-font + 12px);
            @include GothamBook($GothamBook);
            margin: 0;
            text-transform: capitalize;
            padding: 0 0 15px 0;
            border-bottom: 1px solid $alto;
            border-top: none;
        }
        .heading {
            margin: 15px 0 10px 5px;
        }
        .address {
            @include text-properties(0.5px, $SW-navy-blue, $base-font);
            @include GothamMedium($Gotham);
            width: 100%;
            padding-bottom: 15px;
            margin-left: 4px;
            .default {
                border-right: 1px solid $black;
            }
            div {
                width: auto;
                display: inline-block;
                text-transform: capitalize;
                padding-right: 5px;
            }
        }
    }
}
.order-payment-summary {
    padding: 42px 11.2% 30px 35px;
    box-sizing: border-box;
    width: 100%;
    display: inline-block;
    background: $white;
    box-shadow: -2px 6px 30px 8px $white-smoke;
    .order-detail-summary {
        width: 50%;
        float: right;
        max-width: 285px;
        .order-detail {
            width: 100%;
            display: inline-block;
            padding: 0 0 12px 0;
            .label {
                width: 60%;
                float: left;
                text-transform: capitalize;
                @include text-properties(
                    0.5px,
                    $SW-navy-blue,
                    $base-font + 1px
                );
                @include GothamMedium($Gotham);
            }
            .value {
                width: 40%;
                float: right;
                text-align: right;
                @include text-properties(
                    0.5px,
                    $SW-navy-blue,
                    $base-font + 1px
                );
                @include GothamMedium($Gotham);
            }
            &.order-total {
                border-top: 1px solid $alto;
                padding: 17px 0 10px 0;
                .label {
                    @include text-properties(
                        0.5px,
                        $SW-navy-blue,
                        $base-font + 6px
                    );
                    @include GothamBold($GothamBold);
                }
                .value {
                    @include text-properties(
                        0.5px,
                        $SW-navy-blue,
                        $base-font + 6px
                    );
                    @include GothamBold($GothamBold);
                }
            }
            &.discount {
                color: $radical-red;
            }
            &.order-saving {
                .label {
                    @include text-properties(
                        0.5px,
                        $s4a-green,
                        $base-font + 1px
                    );
                    @include GothamBold($GothamBold);
                }
                .value {
                    @include text-properties(
                        0.5px,
                        $s4a-green,
                        $base-font + 6px
                    );
                    @include GothamBold($GothamBold);
                }
            }
        }
    }
    .actions {
        width: 50%;
        padding: 0;
        display: inline-block;
        margin: 20px 0;
        a {
            margin: 0;
            padding: 16px 40px;
            border: 1px solid;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-size: $base-font + 3px;
            @include WorkSansMedium($WorkSans);
        }
    }
}
.order-shipment-table {
    .line-items {
        width: 100%;
        display: inline-block;
        .line-item {
            width: 100%;
            display: inline-block;
            border-top: 1px solid $alto;
            padding: 20px;
            box-sizing: border-box;
        }
        .personalized-message {
            font-size: $base-font - 1px;
            font-family: $HelveticaBold;
            letter-spacing: 1px;
            text-transform: uppercase;
            margin: 0 0 15px 0;
        }
        .giftcard {
            margin: 0 0 15px 0;
            .label {
                font-size: $base-font;
                font-family: $HelveticaBold;
                margin: 0 3px 0 0px;
                letter-spacing: 1px;
            }
            .value {
                font-size: $base-font;
                font-family: $Helvetica;
                text-transform: capitalize;
            }
        }
        .giftmessage {
            font-size: $base-font;
            margin: 0 0 15px 0;
            word-break: break-all;
        }
        .sps-line-item {
            .on-order,
            .preorder-msg,
            .backorder-msg {
                @include GothamBold($Gotham);
                @include text-properties(
                    1px,
                    $mangotango,
                    $base-font
                );
            }
        }
        .column {
            width: 33.33%;
            float: left;
            box-sizing: border-box;
            margin: 0 0;
            &.col-1 {
                max-width: 555px;
                width: 70%;
                .item-image {
                    float: left;
                    width: 100px;
                    text-align: center;
                }
                .item-details {
                    float: left;
                    max-width: 66%;
                    padding-left: 35px;
                    box-sizing: border-box;
                    font-size: $base-font - 1px;
                    .name {
                        @include text-properties(
                            1px,
                            $SW-navy-blue,
                            $base-font
                        );
                        @include GothamMedium($Gotham);
                        margin: 1px 0 6px;
                        text-transform: uppercase;
                    }
                    .sku {
                        @include text-properties(
                            0.5px,
                            $s4a-grey,
                            $base-font - 2px
                        );
                        @include GothamMedium($Gotham);
                        margin-bottom: 13px;
                    }
                    .attribute {
                        .label,
                        .swsps-top-bottom-label {
                            margin-right: 3px;
                            text-transform: capitalize;
                            @include text-properties(
                                1px,
                                $SW-navy-blue,
                                $base-font - 1px
                            );
                            @include GothamBold($Gotham);
                        }
                        .value,
                        .price-sales,
                        .swsps-top-bottom-val {
                            @include text-properties(
                                1px,
                                $SW-navy-blue,
                                $base-font
                            );
                            @include Gotham($Gotham);
                        }
                    }
                    .variant-availability-group {
                        .swsps-top-bottom-label {
                            margin-right: 3px;
                            @include text-properties(
                                1px,
                                $SW-navy-blue,
                                $base-font - 1px
                            );
                            @include GothamBold($Gotham);
                        }
                        .swsps-top-bottom-val {
                            @include text-properties(
                                1px,
                                $SW-navy-blue,
                                $base-font
                            );
                            @include Gotham($Gotham);
                        }
                    }
                    .price-promotion {
                        .price-sales {
                            color: $radical-red;
                        }
                        .price-standard {
                            margin: 0 5px 0 0;
                            text-decoration: line-through;
                        }
                    }
                    .product-option {
                        margin-bottom: 9px;
                        letter-spacing: 1px;
                        text-transform: uppercase;
                        font-family: $HelveticaBold;
                    }
                    .optioninfo {
                        @include WorkSansMedium($WorkSans);
                        .hemmable {
                            margin-top: 15px;
                            margin-bottom: 10px;
                        }
                        span {
                            letter-spacing: 1px;
                            &.monogram,
                            &.hemming {
                                text-decoration: underline;
                                margin: 10px 0;
                                display: inline-block;
                                cursor: pointer;
                                text-transform: uppercase;
                            }
                        }
                        .optionheading {
                            text-transform: uppercase;
                        }
                        .optionvalue {
                            font-family: $HelveticaMedium;
                            line-height: 20px;
                        }
                    }
                }
                .line-item-price {
                    display: none;
                }
            }
            &.col-2 {
                max-width: 170px;
                width: 20%;
                margin: 59px 0 0 0;
            }
            &.col-3 {
                max-width: 95px;
                width: 10%;
                .line-item-price {
                    &.value {
                        @include text-properties(
                            0.5px,
                            $SW-navy-blue,
                            $base-font + 2px
                        );
                        @include GothamMedium($Gotham);
                        &.discount-price {
                            color: $guardsman-Red;
                        }
                    }
                }
            }
        }
    }
}
.pt_order-confirmation {
    .heading {
        @include WorkSansSemiBold($WorkSans);
        width: 100%;
        display: inline-block;
        text-transform: uppercase;
    }
    .receipt-message {
        @include text-properties(0.5px, $SW-navy-blue, $base-font);
        @include GothamMedium($Gotham);
    }
    .line-item-price,
    .discount-price {
        font-size: $base-font + 2px;
    }
    address {
        @include text-properties(0.5px, $SW-navy-blue, $base-font + 1px);
        @include GothamBook($GothamBook);
    }
    .profile-summary {
        .profile-label {
            @include text-properties(0.5px, $SW-navy-blue, $base-font + 2px);
            @include GothamMedium($Gotham);
            padding: 0 5px 0 0;
        }
        .profile-value {
            @include text-properties(0.5px, $SW-navy-blue, $base-font + 1px);
            @include GothamMedium($Gotham);
        }
        .edit-profile {
            color: $black;
            font-family: $WorkSans;
            font-size: $base-font + 1px;
        }
    }
    .login-box-right-content {
        button {
            width: 100%;
            background-color: $SW-navy-blue;
            @include text-properties(2px, $white, $base-font + 2px);
            @include GothamBold($GothamBold);
            border: 2px solid $SW-navy-blue;
        }
    }
    .order-shipment-table {
        &.order-confirmation-product-brylane {
            .line-item .col-1 {
                .item-image img {
                    object-fit: cover;
                    min-height: 144px;
                    @media screen and (max-width: 360px) {
                        min-height: 196px;
                    }
                    @media screen and (min-width: 361px) and (max-width: 767px) {
                        min-height: 204px;
                    }
                }
            }
        }
        .line-items {
            .column {
                &.col-1 {
                    .item-details {
                        .name {
                            margin-bottom: 8px;
                        }
                    }
                }
            }
        }
    }
    .order-shipments {
        .heading {
            @include WorkSansSemiBold($WorkSans);
        }
        .line-items {
            .label {
                @include text-properties(1px, $SW-navy-blue, $base-font);
                @include GothamMedium($Gotham);
            }
            .qty-value {
                @include text-properties(1px, $SW-navy-blue, $base-font);
                @include GothamMedium($Gotham);
            }
            .mini-address-name {
                @include text-properties(
                    0.5px,
                    $SW-navy-blue,
                    $base-font + 1px
                );
                @include GothamMedium($Gotham);
            }
        }
    }
    .order-payment-summary {
        .order-detail-summary {
            .discount {
                .label {
                    @include text-properties(
                        0.5px,
                        $s4a-green,
                        $base-font + 1px
                    );
                    @include GothamMedium($Gotham);
                }
                .value {
                    @include text-properties(
                        0.5px,
                        $s4a-green,
                        $base-font + 1px
                    );
                    @include GothamMedium($Gotham);
                }
            }
            .order-detail {
                .label {
                    .shippingoverlay-link {
                        background: url(../../../images/tips.svg) no-repeat;
                        width: 16px;
                        height: 16px;
                        background-size: cover;
                        vertical-align: bottom;
                    }
                }
            }
        }
    }
    .additional-offers {
        h2 {
            font-size: $base-font + 12px;
            @include WorkSansMedium($WorkSans);
            font-weight: normal;
        }
    }
    .option-info {
        font-size: $base-font + 1px;
        @include WorkSansMedium($WorkSans);
    }
    .order-detail-section {
        margin-top: 9px;
        .payment-type {
            display: none;
        }
        .login-box-content {
            margin-bottom: 9px;
        }
    }
}
@media screen and (max-width: 1023px) {
    .pt_order {
        #secondary {
            display: block;
        }
        .order-details-section {
            margin: 0 20px;
        }
        .orderdetails {
            margin: 30px auto;
            .order-detail-section {
                padding: 35px 25px 40px 25px;
                &.order-detail-response {
                    .order-status {
                        width: 24%;
                    }
                    .order-payment-instruments {
                        width: 30%;
                        .inner-block {
                            max-width: 190px;
                        }
                    }
                    .order-billing {
                        width: auto;
                    }
                    .order-coupon-reward {
                        width: 23%;
                    }
                    span {
                        &.img {
                            margin-left: 0;
                        }
                    }
                }
            }
            .order-shipments {
                .order-shipment-section {
                    .order-shipping-status {
                        width: 16%;
                    }
                    .order-shipment-address {
                        width: 84%;
                    }
                }
                .line-item {
                    .col-1 {
                        width: 60%;
                        .callout-message {
                            &.hide-tablet {
                                display: none;
                            }
                        }
                        .hide-tablet {
                            .label {
                                margin-right: 3px;
                                letter-spacing: 1px;
                                text-transform: uppercase;
                                @include WorkSansSemiBold($WorkSans);
                                font-size: $base-font - 1px;
                                display: inline-block;
                            }
                            .value {
                                @include WorkSansMedium($WorkSans);
                                font-size: $base-font - 1px;
                            }
                        }
                    }
                    .col-2 {
                        display: none;
                    }
                    .col-3 {
                        width: 20%;
                    }
                    .col-4 {
                        width: 20%;
                    }
                }
            }
        }
    }
    .pt_order-confirmation {
        .order-detail-section {
            span {
                &.img {
                    &.cc-number-symbol {
                        display: block;
                        margin-bottom: 15px;
                    }
                }
            }
            .cc-number {
                span {
                    &.img {
                        display: none;
                    }
                }
            }
        }
        .confirmation-left-section {
            margin-top: 0;
            width: 100%;
        }
        .confirmation-message {
            padding-top: 5px;
        }
        .order-date {
            span {
                word-wrap: break-word;
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .pt_order {
        .order-history {
            .order-history-table {
                .order-list-wrapper {
                    .order-list {
                        padding: 15px 13.5px;
                        .order-number {
                            @include WorkSansMedium($WorkSans);
                            .only-for-mobile {
                                @include WorkSansMedium($WorkSans);
                            }
                        }
                        .only-for-mobile {
                            @include WorkSansBold($WorkSans);
                        }
                    }
                    .col-last {
                        padding-top: 15px;
                    }
                }
            }
        }
    }
    .pt_order-confirmation {
        .orderdetails {
            .login-create-account {
                .login-box-content {
                    padding: 30px 10px;
                }
            }
        }
        .order-status {
            margin: 0 0 15px 0;
        }
        .profile-summary {
            .edit-profile {
                margin: 5px 0 0 0;
            }
        }
        .order-confirmation-details {
            .order-print {
                .print-page {
                    padding: 7px 0;
                    float: none;
                }
            }
        }
        .order-detail-section {
            span {
                &.img {
                    &.cc-number-symbol {
                        display: none;
                        margin-bottom: 0;
                    }
                }
            }
            .cc-number {
                span {
                    &.img {
                        display: inline-block;
                    }
                }
            }
            &.expanded-order {
                .order-status {
                    margin: 0 0 10px 0;
                }
            }
        }
        .receipt-message {
            font-size: $base-font - 1px;
        }
        .plcc-user {
            p {
                font-size: $base-font;
            }
        }
        .confirmation-left-section {
            padding: 0 10px;
            h1 {
                font-size: $base-font + 4px;
            }
            .create-heading {
                font-size: $base-font + 12px;
                @include WorkSansMedium($WorkSans);
            }
        }
        .order-detail-section {
            .view-more-section {
                .view-more-link {
                    font-size: $base-font + 3px;
                    @include WorkSansBold($WorkSans);
                }
            }
        }
        .order-shipment-table {
            .line-item-price {
                font-size: $base-font - 1px;
                @include WorkSansMedium($WorkSans);
                .subtotal-label {
                    @include WorkSansSemiBold($WorkSans);
                    display: inline-block;
                }
            }
        }
        .login-box-right-content {
            button {
                padding: 12.5px 48px;
            }
        }
        .order-shipment-table {
            .line-items {
                .column {
                    &.col-1 {
                        .line-item-price {
                            display: block;
                        }
                    }
                }
            }
        }
        .orderdetails {
            .order-shipments {
                .order-shipment-address {
                    margin: 0;
                }
            }
        }
        .additional-offers {
            h2 {
                font-size: $base-font + 3px;
            }
        }
    }
    .pt_order {
        .order-details-section {
            margin: 0 10px;
            .qa-section {
                width: 100%;
                display: inline-block;
                .question-title {
                    padding: 25px 20px;
                    @include text-properties(
                        2px,
                        $SW-navy-blue,
                        $base-font + 6px
                    );
                    background-color: $sw-heading-bg;
                    @include GothamBold($GothamBold);
                    display: block;
                }
                .qa-content {
                    padding: 20px;
                    background: transparent;
                    .question {
                        font-size: $base-font + 2px;
                        @include text-properties(
                            1px,
                            $SW-navy-blue,
                            $base-font + 1px
                        );
                        @include GothamBold($GothamBold);
                    }
                    .answer {
                        @include text-properties(
                            1px,
                            $SW-navy-blue,
                            $base-font + 1px
                        );
                        @include GothamBook($GothamBook);
                    }
                }
            }
        }
        .orderdetails {
            margin: 15px auto 30px;
            .actions {
                width: 100%;
                a {
                    width: 100%;
                    display: inline-block;
                    box-sizing: border-box;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    text-align: center;
                    color: $black;
                    border: 1px solid $black;
                    padding: 18px 30px;
                }
            }
            .optioninfo {
                .option-info {
                    font-family: $Helvetica;
                    font-size: $base-font;
                    color: $mangotango;
                    span {
                        text-transform: capitalize;
                        letter-spacing: 0;
                    }
                }
            }
            .order-information {
                .order-status {
                    .mail-box {
                        display: none;
                    }
                    .receipt-message {
                        font-size: $base-font;
                        margin: 10px 0 0 0;
                    }
                }
            }
            .order-payment-summary {
                padding: 25px 20px;
                .order-detail-summary {
                    width: 100%;
                    float: left;
                    max-width: 100%;
                }
                .actions {
                    display: none;
                }
            }
            .order-detail-section {
                padding: 25px 20px;
                margin: 20px 0;
                &.order-detail-response {
                    .section {
                        width: 100%;
                    }
                }
                .expanded-section {
                    .method {
                        width: 100%;
                        float: left;
                        .shipment-method {
                            max-width: 100%;
                            margin-top: 30px;
                            .heading {
                                margin: 0 0 10px 0;
                                font-size: $base-font;
                                font-family: $HelveticaMedium;
                                letter-spacing: 1px;
                            }
                        }
                    }
                }
                .view-more-section {
                    width: 100%;
                    float: left;
                    text-align: center;
                    font-size: $base-font;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    font-family: $HelveticaBold;
                    margin: 20px 0 0 0;
                }
                .order-status {
                    &.section {
                        .inner-block {
                            margin-top: 0;
                        }
                    }
                    .order-date {
                        width: 50%;
                        float: left;
                    }
                    .total {
                        width: 50%;
                        float: right;
                        margin: 0;
                        padding-left: 15px;
                        box-sizing: border-box;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                    }
                }
                .section {
                    width: 100%;
                    .inner-block {
                        max-width: 100%;
                        margin-top: 30px;
                    }
                    .heading {
                        margin: 0 0 10px 0;
                        font-size: $base-font;
                        font-family: $HelveticaMedium;
                        letter-spacing: 1px;
                    }
                    span {
                        margin: 0 0 7px 0;
                        font-size: $base-font - 1px;
                        font-family: $Helvetica;
                        letter-spacing: 1px;
                    }
                }
                .order-payment-instruments {
                    &.section {
                        width: 100%;
                    }
                }
            }
            .order-shipments {
                .shipmentnumber {
                    border: 0;
                    padding: 0;
                    h2 {
                        font-size: $base-font + 4px;
                        width: 100%;
                        margin: 10px 0 0 0;
                        letter-spacing: normal;
                    }
                    .trackingnumber {
                        width: 100%;
                        font-size: $base-font - 1px;
                        line-height: normal;
                        letter-spacing: 1px;
                        .label,
                        .value {
                            width: auto;
                            display: inline-block;
                        }
                        .value {
                            font-family: $HelveticaMedium;
                            color: $wine-berry;
                        }
                    }
                }
                .order-shipment-details {
                    padding-bottom: 20px;
                    border-bottom: 1px solid $alto;
                    margin-bottom: 10px;
                    width: 100%;
                    float: left;
                }
                .order-shipment-section {
                    font-size: $base-font - 1px;
                    letter-spacing: 1px;
                    margin: 0;
                    .heading {
                        text-transform: uppercase;
                        width: auto;
                        display: inline-block;
                        margin: 15px 0 0 0;
                    }
                    .value {
                        font-family: $HelveticaMedium;
                        font-size: $base-font - 1px;
                        width: auto;
                        display: inline-block;
                    }
                    .address {
                        font-size: $base-font - 1px;
                        font-family: $HelveticaMedium;
                        width: auto;
                        display: inline-block;
                        padding: 0;
                        line-height: 20px;
                    }
                    .method,
                    .order-shipping-status {
                        width: 100%;
                        .shipment-method {
                            max-width: 100%;
                        }
                        .heading {
                            &:after {
                                content: ":";
                            }
                        }
                    }
                    .order-shipment-address {
                        width: 100%;
                        .shipment-address {
                            max-width: 100%;
                            margin: 0;
                            .heading {
                                &:after {
                                    content: ":";
                                }
                            }
                        }
                    }
                }
                .line-item {
                    padding: 10px 0 30px;
                    border-top: 0;
                    border-bottom: 1px solid $alto;
                    .callout-message {
                        &.hide-mobile {
                            display: block !important;
                        }
                    }
                    &:last-child {
                        border-top: 0;
                    }
                    .col-1 {
                        max-width: 100%;
                        width: 100%;
                        .line-item-price {
                            display: none;
                        }
                        .line-item-quantity {
                            margin-bottom: 9px;
                        }
                        .quick-order-badge-link .heading {
                            display: block;
                        }
                    }
                    .col-3 {
                        max-width: 100%;
                        width: 63%;
                        float: right;
                        margin: 60px 0 0 0;
                        padding-left: 35px;
                        @include prefix(box-sizing, border-box, $prefixArray);
                        .heading {
                            width: auto;
                            display: inline-block;
                            text-transform: uppercase;
                            letter-spacing: 1px;
                            margin: 0;
                            font-size: $base-font - 1px;
                            @include GothamMedium($Gotham);
                        }
                        .line-item-price {
                            font-size: $base-font - 1px;
                            width: auto;
                            display: inline-block;
                            .product-option-price {
                                display: inline-block;
                            }
                            &.discount-price {
                                color: $light-red;
                            }
                        }
                    }
                    .col-4 {
                        max-width: 100%;
                        text-align: left;
                        width: 37%;
                        display: inline-block;
                        margin: 25px 0 0 0;
                        font-size: $base-font - 1px;
                        letter-spacing: 1px;
                        .buy-again {
                            margin-top: 24px;
                        }
                    }
                }
            }
            .order-payment-summary {
                .actions {
                    float: left;
                }
            }
            .mini-address-name {
                margin: 0 0 5px 0;
                display: inline-block;
                width: 100%;
            }
            .mini-address-location {
                address {
                    font-style: normal;
                    line-height: 25px;
                }
            }
        }
    }
}
//Order Confirmation Recommendation
.pt_order-confirmation {
    .confirmation-right-content {
        max-width: 280px;
        float: right;
        margin-top: 20px;
        width: 23.1%;
    }
    .confirmation-recommendations {
        margin-top: 1rem;
        .you-may-like {
            background: transparent;
            border: 1px solid $light-gray;
            @media screen and (max-width: 1023px) {
                background: transparent;
                border: 0;
            }
        }
        h2.trending {
            font-size: $base-font + 6px;
            @include WorkSansMedium($WorkSans);
            text-align: center;
            padding: 17px 0 13px;
            margin: 0 0;
            color: $black;
            font-style: normal;
            font-weight: normal;
            text-transform: none;
            @media screen and (max-width: 1023px) {
                font-size: $base-font + 16px;
                padding-bottom: 20px;
                margin-bottom: 20px;
                text-transform: capitalize;
                text-align: center;
                padding-top: 0;
            }
            @media screen and (max-width: 767px) {
                font-size: $base-font + 12px;
            }
        }
        .product-tile {
            color: $black;
            border: 0;
            .product-name {
                font-size: $base-font;
                margin: 3px 0 6px;
                width: 100%;
                line-height: normal;
            }
            .product-price {
                font-size: $base-font;
                margin: 5px 0;
                color: $night-rider;
                @include WorkSansMedium($WorkSans);
                .price-standard {
                    padding-right: 6px;
                }
            }
        }
        @media screen and (max-width: 1024px) {
            .recommendations-heading {
                font-size: $base-font + 18px;
            }
            .product-tile {
                .product-name {
                    font-size: $base-font + 3px !important;
                }
            }
        }
    }
}
.check-order {
    display: inline-block;
    vertical-align: top;
    @media screen and (max-width: 767px) {
        width: 100%;
    }
    &.inner-block {
        .check-order {
            .login-order-track {
                h2 {
                    font-size: $base-font + 12px;
                    @include WorkSansBold($WorkSans);
                    border-bottom: 2px solid #000;
                    padding: 0 0 15px 0;
                }
            }
        }
        .login-order-track {
            background-color: #d8d7df;
            box-sizing: border-box;
            padding: 32px 70px 32px 65px;
            @media screen and (max-width: 1023px) {
                padding: 32px;
            }
            p {
                font-size: $base-font + 1px;
                @include GothamBook($GothamBook);
                color: $SW-gray !important;
                line-height: 21px;
            }
            .ordererror {
                border: none;
                background-color: $wild-sand;
                padding: 0 0 10px 0;
                color: $RedRibbon;
            }
            .dialog-required {
                display: none;
            }
            .form-row-button {
                text-align: right;
                &.orderLookupNarvar {
                    .button {
                        letter-spacing: 2px;
                        @include GothamBold($GothamBold);
                        font-size: $base-font + 4px;
                        border-color: $SW-navy-blue;
                        &.lookUpButton {
                            border-color: $SW-navy-blue;
                            background-color: $SW-navy-blue;
                        }
                        &.buttonOulined {
                            border-color: $SW-navy-blue;
                            color: $SW-navy-blue;
                        }
                    }
                }
                button {
                    padding: 14.5px 63px;
                    width: auto;
                }
            }
            .form-row {
                margin: 0 0 20px 0;
                width: 100%;
                float: left;
            }
            .orderstatus-section {
                .form-row {
                    label {
                        transform: none;
                        &.input-focus {
                            transform: none;
                            letter-spacing: 0.5px;
                            .label-text {
                                font-size: 14px;
                                transition: none;
                            }
                        }
                    }
                }
            }
        }
    }
}
.order-payment-summary {
    .tax-disclaimer {
        @include text-properties(0px, $s4a-grey, $base-font - 2px);
        @include GothamBook($GothamBook);
    }
}
.pt_order-confirmation {
    .qa-section {
        width: 100%;
        display: inline-block;
        .question-title {
            padding: 25px 20px;
            @include text-properties(2px, $SW-navy-blue, $base-font + 6px);
            background-color: $sw-heading-bg;
            @include GothamBold($GothamBold);
            display: block;
        }
        .qa-content {
            padding: 20px;
            background: transparent;
            .question {
                font-size: $base-font + 2px;
                @include text-properties(1px, $SW-navy-blue, $base-font + 1px);
                @include GothamBold($GothamBold);
            }
            .answer {
                @include text-properties(1px, $SW-navy-blue, $base-font + 1px);
                @include GothamBook($GothamBook);
            }
        }
    }
}

.order-shipment-table
    .line-item.sps-line-item
    .column.col-1
    .item-details
    .price-promotion
    .price-sales {
    color: $promo-red;
}
