#main {
    .product-tile {
        .product-name {
            @include GothamMedium($Gotham); //SC-3515
            font-size:$base-font;
            box-sizing:border-box;
        }
    }
}
.home-content-container.div-trending-section
    .you-may-like
    .product-name-image-container
    .product-image.product-brylane
    img {
    height: 317px;
    @media screen and (min-width: 1025px) and (max-width: 1200px) {
        height: 26vw;
    }
    @media screen and (width: 1024px) {
        height: 23vw;
    }
}
#wrapper #main .pdp-main .recommendation .recommendations-heading,
#wrapper
    #main
    .pdp-main
    .pdp-horizontal
    .recommendations-h
    .recommendations-heading
    #wrapper
    .pt_order-confirmation
    .confirmation-right-content
    .confirmation-recommendations
    h2.trending,
#wrapper .you-may-like .recommendation-heading,
#wrapper #main .pdp-main .complete-the-look h4 {
    color: $SW-navy-blue;
    span,
    h2 {
        color: $SW-navy-blue;
    }
}
