/* FB-site breadcrumbs css start */
.breadcrumb {
    @include GothamBook($GothamBook);
    font-size: $base-font;
    letter-spacing: 0.5px;
    color: $SW-gray;
    ol li {
        + li::before {
            content: ">";
            color: $FB-dark-gray;
        }
    }
    .breadcrumb-element {
        color: $FB-dark-gray;
        text-transform: capitalize;
        font-weight: normal;
        &.current-element {
            @include GothamMedium($Gotham);
            font-size: $base-font;
            letter-spacing: 0.5px;
            color: $SW-navy-blue;
        }
    }
    .breadcrumb-slash {
        &:after {
            color: $FB-dark-gray;
            content: ">";
            padding: 0 1px 0 2px;
            pointer-events: none;
            cursor: default;
        }
    }
    @media screen and (min-width: 1024px) {
        border-radius: 0px;
        background-color: transparent;
        padding: 5px 7px;
    }
    @media screen and (max-width: 767px) {
        /* breadcrumb mobile media query start */
        .breadcrumb-element {
            &.current-element {
                margin: 6px 4px;
            }
        }
    } /* breadcrumb mobile media query end */
}
/* FB-site breadcrumbs css end */
.pt_product-details .breadcrumb {
    border-bottom: none;
    border-top: 4px solid #b3b0c4;
    @media screen and (max-width: 767px) {
        border-bottom: 1px solid $alto;
        border-top: none;
        padding: 10px;
        margin: 0 -10px;
    }
}
